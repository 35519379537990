import { MemoryRouter } from "react-router";

/** CONSERVE UNE VARIABLE EN MEMOIRE LOCAL POUR QUELLE SOIT DISPONIBLE DANS TTE L'aPPLI
    @param {any} laChose la variable ou constante a mettre en memoire
    @param {string} NomDeLaChose le nom de recuperation de la variable ou de la constante
 */
export function writeThisInLocalstore(laChose, NomDeLaChose) {
    localStorage.setItem(NomDeLaChose, JSON.stringify(laChose));
}

/** RECUPERER UNE VARIABLE DANS LE LOCAL STORAGE */
export function getThisInLocalstore(NomDeLaChose) {
    return (JSON.parse(localStorage.getItem(NomDeLaChose)));
}
/** VIDER UNE MEMORE OU LE LOCAL STORAGE EN ENTIER */
export function purgeStrorage(NomDeLaChose) {
    if (NomDeLaChose===undefined) {
        localStorage.clear();
    } else {
        localStorage.removeItem(NomDeLaChose);
    }
    return true;
}

/** RECUPERER UN PARAMETRE SPECIFIQUE DANS l'URL */
export function getUrlParameter(sParam) {
    var sPageURL = decodeURIComponent(window.location.search.substring(1)),
    sURLVariables = sPageURL.split('&'), sParameterName, i;
    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');
        if (sParameterName[0] === sParam) 
        {
            return sParameterName[1] === undefined ? true : sParameterName[1];
        }
    }
    return false;
}

/**RETOURNE LE DERNIER ELMENT DE LA BARRE D'ADRESSE [le nom du fichier en cours d'execusion]
 * 
 * @returns 
 */
export function getCurrentPath(){
    let pathname = window.location.pathname;
    return pathname.split("/").pop();
}

/**RETOURNE LA RACINE DE L'URL
 * 
 * @returns 
 */
export function getUrlOrigin() {
    return (window.location.protocol+"//"+window.location.hostname);
}

/**FORMATTEUR DE LIBELLE
 *
 * @returns
 */
export function formatLargeLabel(maxCaract,label){
        if (label.length > maxCaract) {
            return label.toString().substr(0, maxCaract)+"..."
        }
        return label;
}

export function chronosDate(){
    const maintenant = new Date();
    const annee = maintenant.getFullYear();
    const mois = maintenant.getMonth() + 1;
    const jour = maintenant.getDate();
    const heure = maintenant.getHours();
    const minute = maintenant.getMinutes();
    const seconde = maintenant.getSeconds();

    return `${annee}${mois}${jour}${heure}${minute}${seconde}`;
}

export const dateLiteralEnLang =(lang,datex)=>{
    const date = new Date();
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const dateFrancais = date.toLocaleDateString(lang, options);
    return dateFrancais
}

export const  converteDateToLetterFranch=(date)=>{
    if (date!==undefined) {
        const [day, month, year] = date.split('/');
        const months = [
            'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
            'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
        ];
        const daysOfWeek = [
            'Dimanche', 'Lundi', 'Mardi', 'Mercredi',
            'Jeudi', 'Vendredi', 'Samedi'
        ];
        const dataLitFrancais= `${daysOfWeek[new Date(year, month - 1, day).getDay()]} ${day} ${months[month - 1]} ${year}`;
        return dataLitFrancais
    }
    return null;
}

export const packageJSON = require("../../package.json");

export const fileName = ()=>{
    console.log(__filename);
    console.log(__dirname);
    console.log(MemoryRouter);
}