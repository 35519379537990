import React from 'react';
import { useSelector } from "react-redux";

/**
 * LOADER
 * @param {state} loader state disponible dans le store rapprochements
 * @param {text} loader.text  texte à afficher
 * @param {boolean} loader.open affichage ou non du loader
 * 
 */
function CustomLoader() {
  const { loader } = useSelector(state => state.documents);
  return (
    loader.open ?
      <div id="print_screen">
        <div className="loader">
          <div className="loader-content text-center">
            {
              loader.spinner === "img" ?
              <div className='tracking-in-expand'>
                <img src="boa_bmce_groupLOGO.png" width="140" className="navbar-logo" alt="logo" />
              </div> 
              :
              <div className="spinner-border loader-lg text-primary align-self-center"></div> 
            }
            <br />
            <span className="text-center text-primary align-self-center font-weight-bolder">{loader.text}</span>
          </div>
        </div>
      </div>
    :
      null
  )
}

export default CustomLoader