import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Danger, Info, Success } from "../../globalComponents/CustomToast";
import { getCurrentPath, getThisInLocalstore } from "../../globalComponents/Main";
import { PAGINATION } from "../../globalComponents/Pagination";
import { BASEURL } from "../../services/serveur.js";

/** MATELOT [CONVERGENCE] SYNAPSE GROUPE
* !!CENTRALISATION DES STATES MATELOT
*@bessely
*@Author  : YAO BESSELY SUNDAY JUNIOR : +2250709116844 besselymail@gmail.com
*         :Codez en pensant que celui qui maintiendra votre code est un psychopathe qui connaît votre adresse.
*/

export const STATUS = Object.freeze({
        SUCCESS : 'success',
        ERROR   : 'error',
        LOADING : 'loading',
});

const APINAMESPACE = Object.freeze({
        MATELOT     : "/matelot",
        CONGIG      : "/session",
});
const MODALMATELOTDEFAULTSTATE       = { open: false, mode: "", title: "", button: "", inputstate: "", btnclass: "btn btn-primary my-2 text-18 col-4" }; const MODALMATELOTLOCKUP             = { open: false, mode: "", size: "xx", title: "Long période d'inactivité détectée", button: true, buttonName: "Toujours là !!!", btnclass: "btn btn-block btn-danger shadow" };
const CURRENTMATELOTDEFAULTSATE      = [{ LG_UTIID: "", STR_UTIMAIL: "", STR_UTIPHONE: "", STR_UTIPIC: "", STR_UTIFIRSTNAME: "", STR_UTILASTNAME: "", STR_UTILOGIN: "", AGENCE: [{}] }];
const MODALPROFILMATELOTDEFAULTSTATE = { open: false, mode: "creation", size: "profil", title: "Choisissez un profil", button: false, };
const MODALCOMPTEMATELOTDEFAULTSTATE = { open: false, mode: "modification", size: "compte", title: "Mon Compte", button: false, };
const initialState = {
        MatelotList    : [],
        loginMatelot   : [],
        currentMatelot : CURRENTMATELOTDEFAULTSATE,
        currentProfile : "",
        pagination     : PAGINATION,
        formErreur     : [],
        modalMatelot   : MODALMATELOTDEFAULTSTATE,
        modalProfile   : MODALPROFILMATELOTDEFAULTSTATE,
        modalCompte    : MODALCOMPTEMATELOTDEFAULTSTATE,
        modalLockFrame : MODALMATELOTLOCKUP,
        fileImg        : [],
        searchParam    : { choix: "", matelot: "", type: "" },
        status         : { matelot: STATUS.LOADING },
};

export const MatelotSlice = createSlice({
        name: "matelots",
        initialState,
        reducers: {
                setMatelotList    (state, action) { state.MatelotList    = action.payload; },
                setLoginMatelot   (state, action) { state.loginMatelot   = action.payload; },
                setCurrentMatelot (state, action) { state.currentMatelot = action.payload; },
                setCurrentProfile (state, action) { state.currentProfile = action.payload; },
                setPagination     (state, action) { state.pagination     = action.payload; },
                setformErreur     (state, action) { state.formErreur     = action.payload; },
                setFileImg        (state, action) { state.fileImg        = action.payload; },
                setModalMatelot   (state, action) { state.modalMatelot   = action.payload; },
                setModalProfile   (state, action) { state.modalProfile   = action.payload; },
                setModalCompte    (state, action) { state.modalCompte    = action.payload; },
                setStatus         (state, action) { state.status         = action.payload; },
                setMenu           (state, action) { state.menu           = action.payload; },
                setSubMenuState   (state, action) { state.subMenuState   = action.payload; },
                setmodalLockFrame (state, action) { state.modalLockFrame = action.payload; },
        },
        extraReducers: (builder) => {
                builder
                        .addCase(loadDataMatelotList.pending, (state, action) => {
                                state.status.matelot = STATUS.LOADING;
                        })
                        .addCase(loadDataMatelotList.fulfilled, (state, action) => {
                                if (getCurrentPath()==="Document") {
                                        if (action.payload.recordsTotal > 0) {
                                                let userListOpt = [];
                                                action.payload.data.map((item) => {
                                                        return userListOpt.push({
                                                                label    : item.name_matelot+ " " +item.surname_matelot,
                                                                value    : item.id_matelot,
                                                                mail     : item.email_matelot,
                                                                allInfos : item,
                                                                typeList : "MATELOT",
                                                        });
                                                });
                                                state.MatelotList = userListOpt;
                                        }
                                }else{
                                        if (action.payload.recordsTotal > 0) {
                                                state.MatelotList = action.payload.data; // !<- PUBLICATION DE LA LISTE DES: MATELOTS DANS LE STORE
                                                state.pagination =
                                                {
                                                        listParPage     : PAGINATION.listParPage,
                                                        currentPage     : state.pagination.changePageClick ? state.pagination.currentPage : 0,  // !si rechargement ou chargement simple des data currentPage=0 sinon currentPage reste par defaut la page designée l'hors du click sur la pâgination
                                                        changePageClick : false,
                                                        listLenght      : action.payload.recordsFiltered,
                                                        nbrPage         : Math.ceil(action.payload.recordsFiltered / PAGINATION.listParPage)
                                                };
                                        } else {
                                                state.MatelotList = [];
                                                Info.fire({ title: "Aucun enregistrement trouvé !!!" });
                                        }
                                        state.status = { ...state.status, matelot: STATUS.SUCCESS };
                                }
                        })
                        .addCase(loadDataMatelotList.rejected, (state, action) => {
                                state.status.matelot = STATUS.ERROR;
                                Danger.fire({ title: "Désolé ! La liste des matelots n'a pas pu être chargée" });
                        })
                        .addCase(getDataMatelot.fulfilled, (state, action) => {
                                if (action.payload.reponse === "success") {
                                        state.currentMatelot        = action.payload.data; // !<- PUBLICATION DU CHARGEMENT DE L'MATELOT EN COURS DANS LE STORE
                                } else {
                                        Danger.fire({ title: "Ce matelot est introuvable !" });
                                }
                        })
                        .addCase(searchMatelot.pending, (state, action) => {
                                state.status.matelot = STATUS.LOADING;
                        })
                        .addCase(searchMatelot.fulfilled, (state, action) => {
                                if (action.payload.reponse === "success") {
                                        if (action.payload.data.length > 0) {
                                                if (action.payload.recordsTotal > 0) {
                                                        state.MatelotList = action.payload.data; // !<- PUBLICATION DE LA LISTE DES: MATELOTS DANS LE STORE
                                                        state.pagination =
                                                        {
                                                                listParPage     : PAGINATION.listParPage,
                                                                currentPage     : state.pagination.changePageClick ? state.pagination.currentPage : 0,  // !si rechargement ou chargement simple des data currentPage=0 sinon currentPage reste par defaut la page designée l'hors du click sur la pâgination
                                                                changePageClick : false,
                                                                listLenght      : action.payload.recordsFiltered,
                                                                nbrPage         : Math.ceil(action.payload.recordsFiltered / PAGINATION.listParPage)
                                                        };
                                                } else {
                                                        state.MatelotList = [];
                                                        if (action.meta.arg.search !== undefined) { //pendant la recherche : vu que c'est le memem endpoint 
                                                                Info.fire({ title: "Aucun matelot trouvé !!!" });
                                                        } else {
                                                                Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                                                        }
                                                }
                                                state.status = { ...state.status, matelot: STATUS.SUCCESS };
                                        } else { // !<- pas de resultat  
                                                Info.fire({ title: "Désolé ! Aucun résultat trouvé !" });
                                                state.status.matelot = STATUS.ERROR;
                                                state.MatelotList = [];
                                        }
                                } else {
                                        state.MatelotList = [];
                                        state.status = { ...state.status, matelot: STATUS.ERROR };
                                        Info.fire({ title: "Désolé ! Aucun résultat trouvé !" });
                                }
                        })
                        .addCase(searchMatelot.rejected, (state, action) => {
                                state.status.matelot = STATUS.ERROR;
                                Danger.fire({ title: "Désolé ! La liste des matelots n'a pas pu être chargée" });
                        });
        },
});

/**CHARGEMENT DES OU MATELOTS */
export const loadDataMatelotList = createAsyncThunk('loadDataMatelotList/fetchAll', async (data) => {
        try {
                var bodyFormData = new FormData();
                bodyFormData.append("STATE_MATELOT", "%");
                bodyFormData.append("search[value]", data.search ?? "%");
                bodyFormData.append("START", data.start ?? 0);
                bodyFormData.append("LENGTH", data.listParPage ?? 100);
                var res = await fetch(`${BASEURL}${APINAMESPACE.MATELOT}/list`, {
                        method : 'POST',
                        body   : bodyFormData
                });
                const response = await res.json();
                return response;
        } catch (error) {
                Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                return error.message;
        }
});

/**RECHERCHE MANUELLE DANS LA LISTE DES MATELOTS SELON LES PARAMETRES DE RECHERCHE
 * @évènement keyDown sur la zone de recherche
 */
export const searchMatelot = createAsyncThunk('matelotSearch/fetchAll', async (searchParam) => {
        try {
                var bodyFormData = new FormData();
                bodyFormData.append("STATE_MATELOT", "%");
                bodyFormData.append("search[value]", (searchParam.search ?? ""));
                bodyFormData.append("START", searchParam.start ?? 0);
                bodyFormData.append("LENGTH", searchParam.listParPage ?? 100);
                var res = await fetch(`${BASEURL}${APINAMESPACE.MATELOT}/list`, {
                        method : 'POST',
                        body   : bodyFormData
                });
                const response = await res.json();
                if (response.reponse==="error") {
                        return Danger.fire({ title: response.message });
                }
                return response;
        } catch (error) {
                Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                return error.message;
        }
});

/** CHARGEMENT D'UN MATELOT SPECIFIÉ */
export const getDataMatelot = createAsyncThunk('matelot/fetchOne', async (idMatelot) => {
        try {
                var bodyFormData = new FormData();
                bodyFormData.append("ID_MATELOT", idMatelot ?? getThisInLocalstore("loginUtilisateur").id_user);
                var res = await fetch(`${BASEURL}${APINAMESPACE.MATELOT}/GetOne`, {
                        method : 'POST',
                        body   : bodyFormData
                });
                const response = await res.json();
                if (response.reponse==="error") {
                        return Danger.fire({ title: response.message });
                }
                return response;
        } catch (error) {
                Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                return error.message;
        }
});

/** SOUMISSION DU FORMULAIRE DE CREATION D1 MATELOT
 * @param {array} data données du formulaire
 * @function 
 */
export const createMatelot = createAsyncThunk('createMatelot', async (data,{ dispatch, getState }) => {
        try {
                var bodyFormData = new FormData();
                bodyFormData.append("NAME_MATELOT"            , data.name_matelot            ??"");
                bodyFormData.append("SURNAME_MATELOT"         , data.surname_matelot         ??"");
                bodyFormData.append("EMAIL_MATELOT"           , data.email_matelot           ??"");
                bodyFormData.append("CONTACT1_MATELOT"        , data.contact1_matelot        ??"");
                bodyFormData.append("CONTACT2_MATELOT"        , data.contact2_matelot        ??"");
                bodyFormData.append("BITHDATE_MATELOT"        , data.bithdate_matelot        ??"");
                bodyFormData.append("LOCATION_BITHDAY"        , data.location_bithday        ??"");
                bodyFormData.append("NATIONALITY_MATELOT"     , data.nationality_matelot     ??"");
                bodyFormData.append("ADDRESS_MATELOT"         , data.address_matelot         ??"");
                bodyFormData.append("BOX_NUM_MATELOT"         , data.box_num_matelot         ??"");
                bodyFormData.append("EXPIR_DATE_BOX_MATELOT"  , data.expir_date_box_matelot  ??"");
                bodyFormData.append("RANK_MATELOT"            , data.rank_matelot            ??"");
                bodyFormData.append("PASSP_NUM_MATELOT"       , data.passp_num_matelot       ??"");
                bodyFormData.append("EXPIR_DATE_PASSP_MATELOT", data.expir_date_passp_matelot??"");
                bodyFormData.append("TOKEN", getThisInLocalstore("loginUtilisateur").token);
                // if (getState().currentMatelot.fileImg) {
                //         bodyFormData.append("file", getState().matelots.fileImg); //<- les fichiers
                // }
                var res = await fetch(`${BASEURL}${APINAMESPACE.MATELOT}/new`, {
                        method : 'POST',
                        body   : bodyFormData
                });
                const response = await res.json();
                if (response.reponse === "success") {
                        dispatch(loadDataMatelotList({ start: 0, listParPage: PAGINATION.listParPage }));   //<- rechargement des data
                        dispatch(setModalMatelot(MODALMATELOTDEFAULTSTATE)); //<- fermeture de la modale de modification
                        Success.fire({ title: response.message  });
                } else {
                        if (response.reponse==="error") {
                                return Danger.fire({ title: response.message });
                        }
                        Danger.fire({ title: "Echec de la création veuillez réessayer." });
                }
                return response;
        }
        catch (error) {
                Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                return error.message;
        }
});

/** MODIFICATION D'MATELOT 
 * @param {array} data données du formulaire
 * */
export const updateMatelot = createAsyncThunk('updateMatelot', async (data, { dispatch, getState }) => {
        try {
                var bodyFormData = new FormData();
                bodyFormData.append("ID_MATELOT"              , data.id_matelot                  );
                bodyFormData.append("NAME_MATELOT"            , data.name_matelot            ??"");
                bodyFormData.append("SURNAME_MATELOT"         , data.surname_matelot         ??"");
                bodyFormData.append("EMAIL_MATELOT"           , data.email_matelot           ??"");
                bodyFormData.append("CONTACT1_MATELOT"        , data.contact1_matelot        ??"");
                bodyFormData.append("CONTACT2_MATELOT"        , data.contact2_matelot        ??"");
                bodyFormData.append("BITHDATE_MATELOT"        , data.bithdate_matelot        ??"");
                bodyFormData.append("LOCATION_BITHDAY"        , data.location_bithday        ??"");
                bodyFormData.append("NATIONALITY_MATELOT"     , data.nationality_matelot     ??"");
                bodyFormData.append("ADDRESS_MATELOT"         , data.address_matelot         ??"");
                bodyFormData.append("BOX_NUM_MATELOT"         , data.box_num_matelot         ??"");
                bodyFormData.append("EXPIR_DATE_BOX_MATELOT"  , data.expir_date_box_matelot  ??"");
                bodyFormData.append("RANK_MATELOT"            , data.rank_matelot            ??"");
                bodyFormData.append("PASSP_NUM_MATELOT"       , data.passp_num_matelot       ??"");
                bodyFormData.append("EXPIR_DATE_PASSP_MATELOT", data.expir_date_passp_matelot??"");
                bodyFormData.append("TOKEN", getThisInLocalstore("loginUtilisateur").token);
                // if (getState().currentMatelot.fileImg) {
                //         bodyFormData.append("file", getState().matelots.fileImg); //<- les fichiers
                // }}
                var res = await fetch(`${BASEURL}${APINAMESPACE.MATELOT}/update`, {
                        method : 'POST',
                        body   : bodyFormData
                });
                const response = await res.json();
                if (response.reponse === "success") {
                        dispatch(loadDataMatelotList({ start: 0, listParPage: PAGINATION.listParPage }));   //<- rechargement des data
                        dispatch(setModalMatelot(MODALMATELOTDEFAULTSTATE)); //<- fermeture de la modale de modification
                        Success.fire({ title:response.message });
                } else {
                        if (response.reponse === "error") {
                                return Danger.fire({ title: response.message});
                        }
                        Danger.fire({ title: "Echec de la modification veuillez réessayer." });
                }
                return response;
        } catch (error) {
                Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                return error.message;
        }
});


/** SUPPRESSION D'UN MATELOT */ 
export const deleteMatelot = createAsyncThunk('deleteMatelot', async (ID_MATELOT, { dispatch, getState }) => {
        try {
                var bodyFormData = new FormData();
                bodyFormData.append("ID_MATELOT", ID_MATELOT);
                bodyFormData.append("STATE_MATELOT", "DELETED");
                bodyFormData.append("TOKEN", getThisInLocalstore("loginUtilisateur").token);
                var res = await fetch(`${BASEURL}${APINAMESPACE.MATELOT}/updateState`, {
                                method : 'POST',
                                body   : bodyFormData
                });
                const response = await res.json();
                if (response.reponse === "success") {
                        dispatch(loadDataMatelotList({ start: 0, listParPage: PAGINATION.listParPage }));   //<- rechargement des data
                        Success.fire({ title: "Suppression effectuée !" });
                } else {
                        if (response.reponse==="error") {
                                return Danger.fire({ title: response.message });
                        }
                        Danger.fire({ title: "Echec de la suppression veuillez réessayer." });
                }
                return response;
        } catch (error) {
                Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                return error.message;
        }
});


export const { setModalCompte, setmodalLockFrame, setMatelotList, setCurrentMatelot, setCurrentProfile, setLoginMatelot, setMenu, setMenuSate, setSubMenuState, setPagination, setformErreur, setFileImg, setModalMatelot, setModalProfile, status, menu } = MatelotSlice.actions;
export default MatelotSlice.reducer;


