import React from 'react';
import ErreurObject from '../../../globalComponents/ErreurObject';

function FormUtilisateur({ handleSubmit,captureSaisie, formErreur, currentUtilisateur, className, btnTitle, formType }) {
  if (formType==="compte") {
    return (
      <div className={"border border-primary rounded p-3 register-modal shadow " + className}>
        {/* {JSON.stringify(currentUtilisateur)} */}
        <form>
          <div className="form-row mb-2">
            <div className="form-group mb-0 col-md-6">
              <label htmlFor="name_user">Nom </label> <small className="text-danger">*</small>
              <input onChange={captureSaisie} type="text" className="form-control mb-1" id="name_user" value={currentUtilisateur.name_user ?? ""} placeholder="Nom de l'utilisateur" />
              <ErreurObject>{formErreur.name_user}</ErreurObject>

            </div>
            <div className="form-group mb-0 col-md-6">
              <label htmlFor="surname_user">Prénoms</label> <small className="text-danger">*</small>
              <input onChange={captureSaisie} type="text" className="form-control mb-1" id="surname_user" value={currentUtilisateur.surname_user ?? ""} placeholder="Prénoms de l'utilisateur" />
              <ErreurObject>{formErreur.surname_user}</ErreurObject>
            </div>

          </div>
          <div className="form-row mb-2">
          </div>
          <div className="form-row mb-2">
            <div className="form-group mb-0 col">
              <label htmlFor="STR_VILLE">Email</label> <small className="text-danger">*</small>
              <input onChange={captureSaisie} type="text" className="form-control mb-1" id="email_user" value={currentUtilisateur.email_user ?? ""} placeholder="exemplemail@mail.com" />
              <ErreurObject>{formErreur.email_user}</ErreurObject>
            </div>
          </div>
          {
            btnTitle !== undefined && btnTitle.length > 0 ? <button onClick={(e) => { handleSubmit(e) }} className='btn btn-lg btn-outline-success float-right col-4'>{btnTitle}</button> : null
          }
        </form>

      </div>
    )
  }else{
    return (
      <div className={"border border-primary rounded p-3 register-modal shadow " + className}>
        <form>
          <div className="form-row mb-2">
            <div className="form-group mb-0 col-md-6">
              <label htmlFor="name_user">Nom </label> <small className="text-danger">*</small>
              <input onChange={captureSaisie} type="text" className="form-control mb-1" id="name_user" value={currentUtilisateur.name_user ?? ""} placeholder="Nom de l'utilisateur" />
              <ErreurObject>{formErreur.name_user}</ErreurObject>

            </div>
            <div className="form-group mb-0 col-md-6">
              <label htmlFor="surname_user">Prénoms</label> <small className="text-danger">*</small>
              <input onChange={captureSaisie} type="text" className="form-control mb-1" id="surname_user" value={currentUtilisateur.surname_user ?? ""} placeholder="Prénoms de l'utilisateur" />
              <ErreurObject>{formErreur.surname_user}</ErreurObject>
            </div>

          </div>
          <div className="form-row mb-2">
            <div className="form-group mb-0 col">
              <label htmlFor="STR_VILLE">Email</label> <small className="text-danger">*</small>
              <input onChange={captureSaisie} type="text" className="form-control mb-1" id="email_user" value={currentUtilisateur.email_user ?? ""} placeholder="exemplemail@mail.com" />
              <ErreurObject>{formErreur.email_user}</ErreurObject>
            </div>
          </div>
        </form>

      </div>
    )
  }

}

export default FormUtilisateur