import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Danger, Info, Success } from "../../globalComponents/CustomToast";
import { getCurrentPath, getThisInLocalstore } from "../../globalComponents/Main";
import { PAGINATION } from "../../globalComponents/Pagination";
import { BASEURL } from "../../services/serveur.js";
import { loadDataUtilisateurList } from "../Utilisateurs/Utilisateur";

/** PROFIL [CONVERGENCE]
* !!CENTRALISATION DES STATES PROFIL
*@bessely
*@Author  : YAO BESSELY SUNDAY JUNIOR : +2250709116844 besselymail@gmail.com
*         :Codez en pensant que celui qui maintiendra votre code est un psychopathe qui connaît votre adresse.
*/

// toast.configure();
export const STATUS = Object.freeze({
        SUCCESS : 'success',
        ERROR   : 'error',
        LOADING : 'loading',
});

const APINAMESPACE = Object.freeze({
        PROFIL      : "/profil",
        SWITCH      : "/ConfigurationManager",
        UTILISATEUR : "/user",
});
const MODALPROFILDEFAULTSTATE = { open: false, mode: "creation", size: "xl", title: "Nouveau Profil", button: true, buttonName: "Enregistrer", inputstate: "", btnclass: "btn btn-primary" };
const CURRENTPROFILDEFAULTSATE = { LG_PROID: "", STR_PRONAME: "", STR_PRODESCRIPTION: "", STR_PROTYPE: "", SOCIETE: [{}] };
const initialState = {
        profilList      : [],
        currentProfil   : CURRENTPROFILDEFAULTSATE,
        pagination      : PAGINATION,
        formErreur      : [],
        modalProfil     : MODALPROFILDEFAULTSTATE,
        fileImg         : [],
        searchParam     : { choix: "", profil: "", type: "" },
        status          : { profil: STATUS.LOADING },
        LG_PROID        : "",
        tabChecked      : [],
        checkProfil     : [],
        modalAssoProfil : MODALPROFILDEFAULTSTATE,
};

export const ProfilSlice = createSlice({
        name: "profils",
        initialState,
        reducers: {
                setProfilList      (state, action) { state.profilList     = action.payload; },
                setCurrentProfil   (state, action) { state.currentProfil  = action.payload; },
                setPagination      (state, action) { state.pagination     = action.payload; },
                setformErreur      (state, action) { state.formErreur     = action.payload; },
                setFileImg         (state, action) { state.fileImg        = action.payload; },
                setModalProfil     (state, action) { state.modalProfil    = action.payload; },
                setStatus          (state, action) { state.status         = action.payload; },
                setLgproid         (state, action) { state.LG_PROID       = action.payload; },
                setTabChecked      (state, action) { state.tabChecked     = action.payload; },
                setCheckProfil     (state, action) { state.checkProfil    = action.payload; },
                setModalAssoProfil (state, action) { state.modalAssoProfil = action.payload; },
        },
        extraReducers: (builder) => {
                builder
                        .addCase(loadDataProfilList.pending, (state, action) => {
                                state.status.profil = STATUS.LOADING;
                        })
                        .addCase(loadDataProfilList.fulfilled, (state, action) => {
                                if (action.payload?.reponse ==="success") {
                                        state.profilList = action.payload.data; // !<- PUBLICATION DE LA LISTE DES: PROFILS DANS LE STORE
                                        state.pagination =
                                        {
                                                listParPage     : PAGINATION.listParPage,
                                                currentPage     : state.pagination.changePageClick ? state.pagination.currentPage : 0,  // !si rechargement ou chargement simple des data currentPage=0 sinon currentPage reste par defaut la page designée l'hors du click sur la pâgination
                                                changePageClick : false,
                                                listLenght      : action.payload?.data?.length,
                                                nbrPage         : 6 / PAGINATION.listParPage
                                        };
                                } else {
                                        state.profilList = [];
                                        if (action.meta.arg.search !== undefined) {
                                                Info.fire({ title: "Aucun profil trouvée" });
                                        } else {
                                                Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                                        }
                                }
                                state.status = { ...state.status, profil: STATUS.SUCCESS };
                        })
                        .addCase(loadDataProfilList.rejected, (state, action) => {
                                state.status.profil = STATUS.ERROR;
                                Danger.fire({ title: "Désolé ! La liste des profils n'a pas pu être chargée" });
                        })
                        .addCase(getDataProfil.fulfilled, (state, action) => {
                                if (action.payload?.LG_PROID !== undefined) {
                                        // !<- PUBLICATION DU CHARGEMENT DE L'PROFIL EN COURS DANS LE STORE  
                                        let currenProfil = {
                                                label    : action.payload.STR_SOCDESCRIPTION,
                                                value    : action.payload.LG_SOCID,
                                                typeList : "SOCIETE",
                                        };
                                        state.currentProfil         = action.payload;
                                        state.currentProfil.SOCIETE = currenProfil;
                                } else {
                                        Danger.fire({ title: "Ce Profil semble être introuvable !" });
                                }
                                if (action.payload.code_statut !== undefined && parseInt(action.payload.code_statut) >= 2) {
                                        Danger.fire({ title: action.payload.desc_statut });
                                        return;
                                }
                        })
                        .addCase(searchProfil.pending, (state, action) => {
                                state.status.profil = STATUS.LOADING;
                        })
                        .addCase(searchProfil.fulfilled, (state, action) => {
                                if (action.payload.code_statut === "" || action.payload.code_statut === "1") {
                                        if (action.payload.data.length > 0) {
                                                var searchParam = action.meta.arg;
                                                var result = action.payload.data.filter(item => { // !!! je fais un filtre sur les resultats selon les options selectionnées
                                                        return(
                                                                (searchParam.choix !== "" && searchParam.type === "" && searchParam.profil === "") ?  action.payload.data : null 
                                                        )
                                                });
                                                if (result.length > 0) {
                                                        state.status = { ...state.status, profil: STATUS.SUCCESS };
                                                        state.profilList = result; // !<- PUBLICATION DE LA LISTE DES: PROFILS DANS LE STORE
                                                } else {
                                                        state.status = { ...state.status, profil: STATUS.ERROR };
                                                        state.profilList = []; // !<- pas de resultat 
                                                        Info.fire({ title: "Désolé ! Aucun résultat trouvé !" });
                                                }
                                                state.pagination = {
                                                        listParPage     : PAGINATION.listParPage,
                                                        currentPage     : state.pagination.changePageClick ? state.pagination.currentPage : 0,  // !si rechargement ou chargement simple des data currentPage=0 sinon currentPage reste par defaut la page designée l'hors du click sur la pâgination
                                                        changePageClick : false,
                                                        listLenght      : action.payload.recordsTotal,
                                                        nbrPage         : Math.ceil(result ?? 0 / PAGINATION.listParPage)
                                                };
                                        } else { // !<- pas de resultat  
                                                Info.fire({ title: "Désolé ! Aucun résultat trouvé  !" });
                                                state.status.profil = STATUS.ERROR;
                                                state.profilList = [];
                                        }
                                } else {
                                        state.profilList = [];
                                        state.status = { ...state.status, profil: STATUS.ERROR };
                                        Info.fire({ title: "Désolé ! Aucun résultat trouvé !" });
                                }
                        })
                        .addCase(searchProfil.rejected, (state, action) => {
                                state.status.profil = STATUS.ERROR;
                                Danger.fire({ title: "Désolé ! La liste des profils n'a pas pu être chargée" });
                        });
        },
});

/**CHARGEMENT DES PROFILS */
export const loadDataProfilList = createAsyncThunk('profilList/fetchAll', async (data, { dispatch, getState }) => {
        try {
                var bodyFormData = new FormData();
                bodyFormData.append("LG_SOCID", "");
                bodyFormData.append("search[value]", data.search ?? "");
                bodyFormData.append("start", data.start ?? 0);
                bodyFormData.append("length", data.length ?? 10);
                var res = await fetch(`${BASEURL}${APINAMESPACE.PROFIL}/list`, {
                        method : 'POST',
                        body   : bodyFormData
                });
                const response = await res.json();
                return response;
        } catch (error) {
                Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                return error.message;
        }
});

/** 
 * CHARGEMENT D'UN PROFIL DONNÉE 
 * */
export const getDataProfil = createAsyncThunk('profil/fetchOne', async (idProfil) => {
        try {
                var bodyFormData = new FormData();
                bodyFormData.append("ID_PROFIL", idProfil);
                var res = await fetch(`${BASEURL}${APINAMESPACE.PROFIL}/GetOne`, {
                        method : 'POST',
                        body   : bodyFormData
                });
                const response = await res.json();
                return response;
        } catch (error) {
                Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                return error.message;
        }
});

/**RECHERCHE MANUELLE DANS LA LISTE DES PROFILS SELON LES PARAMETRES DE RECHERCHE
 * @évènement onClick
 */
export const searchProfil = createAsyncThunk('profilSearch/fetchAll', async (searchParam) => {
        try {
                var bodyFormData = new FormData();
                bodyFormData.append("LG_SOCID", "");
                bodyFormData.append("search[value]", searchParam.search ?? "");
                bodyFormData.append("start", searchParam.start ?? 0);
                bodyFormData.append("length", searchParam.length ?? 250);
                var res = await fetch(`${BASEURL}${APINAMESPACE.PROFIL}/listProfile`, {
                        method : 'POST',
                        body   : bodyFormData
                });
                const response = await res.json();
                return response;
        } catch (error) {
                Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion " });
                return error.message;
        }
});

/** SOUMISSION DU FORMULAIRE DE CREATION D1 PROFIL
 * 
 * @param {array} data données du formulaire
 * @function 
 */
export const createProfil = createAsyncThunk("createProfil", async (data,{ dispatch, getState }) => {
                try {
                        var bodyFormData = new FormData();
                        bodyFormData.append("STR_PRONAME", data.STR_PRONAME);
                        bodyFormData.append("STR_PRODESCRIPTION", data.STR_PRODESCRIPTION);
                        bodyFormData.append("STR_PROTYPE", data.STR_PROTYPE);
                        bodyFormData.append("LG_SOCID", getThisInLocalstore("loginUtilisateur").lg_SOCIEGE_ID);
                        bodyFormData.append("LG_UTITOKEN", getThisInLocalstore("loginUtilisateur").token);
                        var res = await fetch(`${BASEURL}${APINAMESPACE.PROFIL}/createProfile`, {
                                method : 'POST',
                                body   : bodyFormData
                        });
                        const response = await res.json();
                        if (response.code_statut === "1") {
                                dispatch(createSwitchProfilPrivilege(response.data[0].LG_PROID));
                                Success.fire({ title: response.desc_statut });
                        }else{
                                Danger.fire({ title: response.desc_statut });
                        }
                        return response;
                }catch (error) {
                        Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                        return error.message;
                }
});

/** MODIFICATION D'PROFIL 
 * @param {array} data données du formulaire
 * */
export function updateProfil(data) {
        return async function updateAgenceThunk(dispatch, getState) {
                try {
                        var bodyFormData = new FormData();
                        bodyFormData.append("STR_PRONAME", data.STR_PRONAME);
                        bodyFormData.append("LG_PROID", data.LG_PROID);
                        bodyFormData.append("STR_PRODESCRIPTION", data.STR_PRODESCRIPTION);
                        bodyFormData.append("STR_PROTYPE", data.STR_PROTYPE);
                        bodyFormData.append("LG_SOCID", data.SOCIETE.value);
                        bodyFormData.append("LG_UTITOKEN", getThisInLocalstore("loginUtilisateur").token);
                        var res = await fetch(`${BASEURL}${APINAMESPACE.PROFIL}/updateProfile`, {
                                method : 'POST',
                                body   : bodyFormData
                        });
                        const response = await res.json();
                        if (response.code_statut === "1") {
                                dispatch(loadDataProfilList({ start: 0, listParPage: PAGINATION.listParPage }));   //<- rechargement des data                                      
                                dispatch(setModalProfil(MODALPROFILDEFAULTSTATE)); //<- fermeture de la modale de modification
                                Success.fire({ title: "Correctement mis à jour !" });
                                return;
                        }
                        if (response.code_statut === "0") {
                                Danger.fire({ title: response.desc_statut });
                                return;
                        }
                        Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion.11" });
                        return response;
                } catch (error) {
                        Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion." });
                        return error.message;
                }
        };
}

/** SUPPRESSION D'UN PROFIL */
export function deleteProfil(LG_PROID, STR_UTITOKEN) {
        return async function deleteProfilThunk(dispatch, getState) {
                try {
                        var bodyFormData = new FormData();
                        bodyFormData.append("LG_PROID", LG_PROID);
                        bodyFormData.append("STR_UTITOKEN", getThisInLocalstore("loginUtilisateur").token);
                        var res = await fetch(`${BASEURL}${APINAMESPACE.PROFIL}/deleteProfile`, {
                                method: 'POST',
                                body: bodyFormData
                        });
                        const response = await res.json();
                        console.log(response);
                        if (response.code_statut === "1") {
                                dispatch(loadDataProfilList({ start: 0, listParPage: PAGINATION.listParPage }));   //<- rechargement des data                                      
                                Success.fire({ title: "Suppression effectuée !" });
                        } else {
                                Danger.fire({ title: "Echec de la suppression veuillez réessayer." });
                        }
                        return response;
                } catch (error) {
                        Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                        return error.message;
                }
        };
}

/**
 * CHARGEMENT DES SOCIETE DANS CREATION DE PROFIL 
 * 
**/
export const listSocieteOptions = createAsyncThunk('profilList/fetchAll', async (data) => {
        try {
                var bodyFormData = new FormData();
                bodyFormData.append("STR_UTITOKEN", getThisInLocalstore("loginUtilisateur").token);
                bodyFormData.append("LG_SOCID", "");
                bodyFormData.append("search[value]", data.search ?? "");
                bodyFormData.append("start", data.start ?? 0);
                bodyFormData.append("length", data.listParPage ?? 100000);
                var res = await fetch(`${BASEURL}${APINAMESPACE.SOCIETE}/listSociete`, {
                        method : 'POST',
                        body   : bodyFormData
                });
                const response = await res.json();
                return response;
        } catch (error) {
                Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                return error.message;
        }
});

/** SOUMISSION DU FORMULAIRE DE CREATION D1 PROFILPRIVILEGE
 * 
 * @param {string} LG_PROID id du profil
 * @function 
 **/
export const createSwitchProfilPrivilege = createAsyncThunk("createSwitchProfilPrivilege", async (LG_PROID,{dispatch, getState}) => {
                let tabChecked= localStorage.getItem("tabChecked") ? localStorage.getItem("tabChecked") : "[]";
                try {
                        var bodyFormData = new FormData();
                        bodyFormData.append("LG_PROID", LG_PROID);
                        bodyFormData.append("LG_PRIID", tabChecked); //<- tableau des id des privilèges selectionnés | deja stringifié au sortir du localstorage
                        bodyFormData.append("STR_UTITOKEN", getThisInLocalstore("loginUtilisateur").token);
                        var res = await fetch(`${BASEURL}${APINAMESPACE.SWITCH}/switchPrivilege`, {
                                        method : 'POST',
                                        body   : bodyFormData
                        });
                        const response = await res.json();
                        if (response.code_statut === "1") {
                                dispatch(loadDataProfilList({ start: 0, listParPage: PAGINATION.listParPage }));   //<- rechargement des data
                                dispatch(setModalProfil(MODALPROFILDEFAULTSTATE)); //<- fermeture de la modale de modification
                                Success.fire({ title: response.desc_statut });
                        }
                        if (response.code_statut === "0") {
                                Danger.fire({ title: response.desc_statut });
                        }
                        return response;
                }catch (error) {
                        Danger.fire({ title: "Une erreur inconnue est survenue durant l'assosiation des provilèges au profil." });
                        return error.message;
                }
});

/** ASSOCIASION DU PROFIL A UN USER
 * 
 * @param {string} LG_PROID id du profil
 * @function 
 **/
export const bindProfilToUser = createAsyncThunk("bindProfilUser", async (LG_UTIID, { dispatch, getState }) => {
        let tabChecked = localStorage.getItem("tabChecked") ? localStorage.getItem("tabChecked") : "[]";
        try {
                // console.log(getState().profils.checkProfil);
                // console.log(({tabChecked}));
                // console.log(typeof(tabChecked));
                // return;
                var bodyFormData = new FormData();
                bodyFormData.append("PROFIL", tabChecked);
                bodyFormData.append("ID_USER", (LG_UTIID));
                bodyFormData.append("STR_UTITOKEN", getThisInLocalstore("loginUtilisateur").token);
                var res = await fetch(`${BASEURL}${APINAMESPACE.PROFIL}/attachuser`, {
                        method : 'POST',
                        body   : bodyFormData
                });
                const response = await res.json();
                if (response.reponse === "success") {
                        dispatch(loadDataUtilisateurList({ start: 0, listParPage: PAGINATION.listParPage }));
                        dispatch(setModalProfil(MODALPROFILDEFAULTSTATE));
                        Success.fire({ title: response.message });
                }
                if (response.reponse === "error") {
                        Danger.fire({ title: response.message });
                }
                return response;
        } catch (error) {
                Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                return error.message;
        }
});

/**Rattacher les profil au compte */
export const bindProfilToCompte = createAsyncThunk('bindProfilToCompte', async (data, { dispatch, getState }) => {
        let tabChecked = localStorage.getItem("tabChecked") ? localStorage.getItem("tabChecked") : "[]";
        // console.log(getState().profils.checkProfil);
        // console.log(getState().corsocietes.currentInputCompte);
        // return
        try {
                var bodyFormData = new FormData();
                bodyFormData.append("LG_SCCID", getState().corsocietes.currentInputCompte.LG_SCCID);
                bodyFormData.append("LG_PROID", tabChecked);
                bodyFormData.append("STR_UTITOKEN", getThisInLocalstore("loginUtilisateur").token);
                var res = await fetch(`${BASEURL}${APINAMESPACE.PROFIL}/createProfilAcc`, {
                        method: 'POST',
                        body: bodyFormData
                });
                const response = await res.json();
                if (response.code_statut === "1") {
                        dispatch(setModalProfil(MODALPROFILDEFAULTSTATE));
                        Success.fire({ title: response.desc_statut });
                }
                if (response.code_statut === "0") {
                        Danger.fire({ title: response.desc_statut });
                }
                return response;
        } catch (error) {
                Danger.fire({ title: "Erreur l'hors de l'enregistrement. Veuillez réessayer un peu plus tard." });
                return error.message;
        }
});

/**chargement des profils d'1 compte utilisateur */
export const getBindProfilUser = createAsyncThunk('getBindProfilUser/fetchAll', async (LG_UTIID, { dispatch, getState }) => {
        try {
                var bodyFormData = new FormData();
                bodyFormData.append("ID_USER", LG_UTIID);
                bodyFormData.append("STR_UTITOKEN", getThisInLocalstore("loginUtilisateur").token);
                var res = await fetch(`${BASEURL}${APINAMESPACE.UTILISATEUR}/GetOne`, {
                        method : 'POST',
                        body   : bodyFormData
                });
                const response = await res.json();
                if (response.reponse === "success") {
                        let checkboxList = document.getElementsByClassName("profil"); // liste des checkbox (profil) sur le DOM
                        let resumeCheckedProfil = [];
                        for (let x = 0; x < response.data.profil.length; x++) {
                                for (let index = 0; index < checkboxList.length; index++) {
                                        if (parseInt(checkboxList[index].getAttribute("data-id")) === parseInt(response.data.profil[x].id_profil)) {
                                                checkboxList[index].checked = true;
                                                resumeCheckedProfil.push(response.data.profil[x].id_profil);
                                        }
                                }
                        }
                        localStorage.removeItem('tabChecked');
                        localStorage.setItem('tabChecked', JSON.stringify(resumeCheckedProfil));
                        dispatch(setCheckProfil(resumeCheckedProfil)); // mise à jour de la liste des profils rattachés au compte dans le state des profils selectionner pour le compte en cours au cas ou ya une modif
                } else {
                        if (getState().profil.modalAssoProfil.open) {
                                dispatch(setCheckProfil([]));
                                Info.fire({ title: "Pas encore de profils rattachés à cet utilisateur" });
                        }
                }
                return response;
        } catch (error) {
                Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                return error.message;
        }
});

/**chargement des profils d'1 compte correspondant */
export const getBindProfil = createAsyncThunk('getBindProfil/fetchAll', async (LG_SCCID, { dispatch, getState }) => {
        if (LG_SCCID !== undefined) {
                try {
                        var bodyFormData = new FormData();
                        bodyFormData.append("LG_PACID", LG_SCCID);
                        var res = await fetch(`${BASEURL}${APINAMESPACE.PROFIL}/getProfAcc`, {
                                method : 'POST',
                                body   : bodyFormData
                        });
                        const response = await res.json();
                        if (response.code_statut === "1") {
                                let checkboxList = document.getElementsByClassName("profil"); // liste des checkbox (profil) sur le DOM
                                let resumeCheckedProfil = [];
                                for (let x = 0; x < response.Data.length; x++) {
                                        for (let index = 0; index < checkboxList.length; index++) {
                                                if (JSON.parse(checkboxList[index].getAttribute('data-profil')).LG_PROID === response.Data[x].LG_PROID) {
                                                        checkboxList[index].checked = true;
                                                        resumeCheckedProfil.push(response.Data[x].LG_PROID);
                                                }
                                        }
                                }
                                localStorage.removeItem('tabChecked');
                                localStorage.setItem('tabChecked', JSON.stringify(resumeCheckedProfil));
                                dispatch(setCheckProfil(resumeCheckedProfil)); // mise à jour de la liste des profils rattachés au compte dans le state des profils selectionner pour le compte en cours au cas ou ya une modif
                        } else {
                                if (getState().corsocietes.modalAssoCompt.open) {
                                        dispatch(setCheckProfil([]));
                                        Info.fire({ title: "Pas encore de profils rattachés à ce compte" });
                                }
                        }
                        return response;
                } catch (error) {
                        Danger.fire({ title: "Erreur l'hors du chargement des profils rattachés à ce compte." });
                        return error.message;
                }
        }
});

/**
 * RECUPERATION DE TT LES PROFILS PRIVILEGE COCHES DEPUIS LE DOM
 **/
export const collectProfilChecked = () => {
        localStorage.removeItem('tabChecked');
        let priv = getCurrentPath() === "Utilisateur" || getCurrentPath() === "Societe" ? document.getElementsByClassName('profil') : document.getElementsByClassName('privileges_');
        let element = [];
        for (let index = 0; index < priv.length; index++) {
                if (priv[index].checked) {
                        element.push(priv[index].getAttribute('data-id'));
                }
        }
        console.log(element);
        localStorage.setItem('tabChecked', JSON.stringify(element));
        return element;
};

/**
 * DECOCHER TOUS LES PROFILS PRIVILEGE EN UNE FOIS DIRECTEMENT DEPUIS LE DOM
 * @param string classCssElmt la classe des elmts a décodé 
 **/
export const uncheckAllProfil = (classCssElmt) => {
        let classs=classCssElmt;
        let priv = document.getElementsByClassName(classs);
        for (let index = 0; index < priv.length; index++) {
                priv[index].checked=false;
        }
        // localStorage.removeItem('tabChecked');
};

export const { setProfilList, setCurrentProfil, setPagination, setformErreur, setModalAssoProfil, setCheckProfil, setFileImg, setModalProfil, setLgproid, status, setTabChecked } = ProfilSlice.actions;
export default ProfilSlice.reducer;


