import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Danger, Success } from "../../globalComponents/CustomToast";
import { getCurrentPath, getThisInLocalstore } from "../../globalComponents/Main";
import { PAGINATION } from "../../globalComponents/Pagination";
import { BASEURL } from "../../services/serveur.js";

/** Armateur [CONVERGENCE] SYNAPSE GROUPE
* !!CENTRALISATION DES STATES Armateur
*@bessely
*@Author  : YAO BESSELY SUNDAY JUNIOR : +2250709116844 besselymail@gmail.com
*         :Codez en pensant que celui qui maintiendra votre code est un psychopathe qui connaît votre adresse.
*/

export const STATUS = Object.freeze({
        SUCCESS : 'success',
        ERROR   : 'error',
        LOADING : 'loading',
});

const APINAMESPACE = Object.freeze({
        ARMATEUR : "/armateur",
        NAVIRE    : "/navire",
});
const MODALARMATEURDEFAULTSTATE         = { open: false, mode: "", title: "", button: "", inputstate: "", btnclass: "btn btn-primary my-2 text-18 col-4" };
const MODALARMATEURLOCKUP               = { open: false, mode: "", size: "xx", title: "Long période d'inactivité détectée", button: true, buttonName: "Toujours là !!!", btnclass: "btn btn-block btn-danger shadow" };
export const CURRENTARMATEURDEFAULTSATE = [{ NAME_ARM: "", DESCRIPTION_ARM: "", STATE_ARM:"" }];
const MODALPROFILARMATEURDEFAULTSTATE   = { open: false, mode: "creation", size: "profil", title: "Choisissez un profil", button: false, };
const MODALCOMPTEARMATEURDEFAULTSTATE   = { open: false, mode: "modification", size: "compte", title: "Mon Compte", button: false, };
const initialState = {
        ArmateurList      : [],
        ArmateurListModal : [],
        loginArmateur     : [],
        currentArmateur   : CURRENTARMATEURDEFAULTSATE,
        currentNavire     : CURRENTARMATEURDEFAULTSATE,
        pagination        : PAGINATION,
        formErreur        : [],
        modalArmateur     : MODALARMATEURDEFAULTSTATE,
        modalNavire       : MODALPROFILARMATEURDEFAULTSTATE,
        modalCompte       : MODALCOMPTEARMATEURDEFAULTSTATE,
        modalLockFrame    : MODALARMATEURLOCKUP,
        fileImg           : [],
        searchParam       : { choix: "", Armateur: "", type: "" },
        status            : { Armateur: STATUS.LOADING },
};

export const ArmateurSlice = createSlice({
        name: "armateurs",
        initialState,
        reducers: {
                setArmateurList      (state, action) { state.ArmateurList      = action.payload; },
                setLoginArmateur     (state, action) { state.loginArmateur     = action.payload; },
                setCurrentArmateur   (state, action) { state.currentArmateur   = action.payload; },
                setCurrentNavire     (state, action) { state.currentNavire     = action.payload; },
                setPagination        (state, action) { state.pagination        = action.payload; },
                setformErreur        (state, action) { state.formErreur        = action.payload; },
                setFileImg           (state, action) { state.fileImg           = action.payload; },
                setModalArmateur     (state, action) { state.modalArmateur     = action.payload; },
                setModalNavire       (state, action) { state.modalNavire       = action.payload; },
                setModalCompte       (state, action) { state.modalCompte       = action.payload; },
                setStatus            (state, action) { state.status            = action.payload; },
                setMenu              (state, action) { state.menu              = action.payload; },
                setSubMenuState      (state, action) { state.subMenuState      = action.payload; },
                setmodalLockFrame    (state, action) { state.modalLockFrame    = action.payload; },
        },
        extraReducers: (builder) => {
                builder
                        .addCase(loadDataArmateurList.pending, (state, action) => {
                                state.status.Armateur = STATUS.LOADING;
                        })
                        .addCase(loadDataArmateurList.fulfilled, (state, action) => {
                                if (getCurrentPath()==="Document") {
                                        if (action.payload.reponse ==="success") {
                                                let userListOpt = [];
                                                action.payload.data.map((item) => {
                                                        return userListOpt.push({
                                                                label    : item.name_arm,
                                                                value    : item.id_arm,
                                                                navire   : item.navire,
                                                                allInfos : item,
                                                                typeList : "Armateur",
                                                        });
                                                });
                                                state.ArmateurList     = userListOpt;
                                                state.ArmateurListModal= userListOpt
                                        }
                                }else{
                                        if (action.payload.reponse==="success") {
                                                state.ArmateurList = action.payload.data; // !<- PUBLICATION DE LA LISTE DES: ArmateurS DANS LE STORE
                                        }
                                        state.status = { ...state.status, Armateur: STATUS.SUCCESS };
                                }
                        })
                        .addCase(loadDataArmateurList.rejected, (state, action) => {
                                state.status.Armateur = STATUS.ERROR;
                                Danger.fire({ title: "Désolé ! La liste des Armateurs n'a pas pu être chargée" });
                        })
                        .addCase(getDataArmateur.fulfilled, (state, action) => {
                                if (action.payload.reponse === "success") {
                                        state.currentArmateur        = action.payload.data; // !<- PUBLICATION DU CHARGEMENT DE L'Armateur EN COURS DANS LE STORE
                                } else {
                                        Danger.fire({ title: "Ce Armateur est introuvable !" });
                                }
                        })
                        .addCase(getDataNavire.fulfilled, (state, action) => {
                                if (action.payload.reponse === "success") {
                                        state.currentNavire        = action.payload.data; // !<- PUBLICATION DU CHARGEMENT DE L'Armateur EN COURS DANS LE STORE
                                } else {
                                        Danger.fire({ title: "Ce Navire est introuvable !" });
                                }
                        })
        },
});

/**CHARGEMENT DES OU ArmateurS */
export const loadDataArmateurList = createAsyncThunk('loadDataArmateurList/fetchAll', async (search) => {
        try {
                var bodyFormData = new FormData();
                bodyFormData.append("search[value]", (search ?? "%"));
                var res = await fetch(`${BASEURL}${APINAMESPACE.ARMATEUR}/list`, {
                        method : 'POST',
                        body   : bodyFormData
                });
                const response = await res.json();
                return response;
        } catch (error) {
                Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                return error.message;
        }
});

/** CHARGEMENT D'UN Armateur SPECIFIÉ */
export const getDataArmateur = createAsyncThunk('Armateur/fetchOne', async (idArmateur) => {
        try {
                var bodyFormData = new FormData();
                bodyFormData.append("ID_ARM", idArmateur);
                var res = await fetch(`${BASEURL}${APINAMESPACE.ARMATEUR}/GetOne`, {
                        method : 'POST',
                        body   : bodyFormData
                });
                const response = await res.json();
                if (response.reponse==="error") {
                        return Danger.fire({ title: response.message });
                }
                return response;
        } catch (error) {
                Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                return error.message;
        }
});

/** CHARGEMENT D'UN Armateur SPECIFIÉ */
export const getDataNavire = createAsyncThunk('Navire/fetchOne', async (immat_nav) => {
        try {
                var bodyFormData = new FormData();
                bodyFormData.append("IMMAT_NAV", immat_nav);
                var res = await fetch(`${BASEURL}${APINAMESPACE.NAVIRE}/GetOne`, {
                        method : 'POST',
                        body   : bodyFormData
                });
                const response = await res.json();
                if (response.reponse==="error") {
                        return Danger.fire({ title: response.message });
                }
                return response;
        } catch (error) {
                Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                return error.message;
        }
});
/** SOUMISSION DU FORMULAIRE DE CREATION D1 Armateur
 * @param {array} data données du formulaire
 * @function 
 */
export const createArmateur = createAsyncThunk('createArmateur', async (data,{ dispatch, getState }) => {
        try {
                var bodyFormData = new FormData();
                bodyFormData.append("NAME_ARM"        , data.name_arm        ??"");
                bodyFormData.append("DESCRIPTION_ARM" , data.description_arm ??"");
                bodyFormData.append("TOKEN", getThisInLocalstore("loginUtilisateur").token);
                // if (getState().currentArmateur.fileImg) {
                //         bodyFormData.append("file", getState().Armateurs.fileImg); //<- les fichiers
                // }
                var res = await fetch(`${BASEURL}${APINAMESPACE.ARMATEUR}/new`, {
                        method : 'POST',
                        body   : bodyFormData
                });
                const response = await res.json();
                if (response.reponse === "success") {
                        dispatch(loadDataArmateurList("%"));   //<- rechargement des data
                        dispatch(setModalArmateur(MODALARMATEURDEFAULTSTATE)); //<- fermeture de la modale de modification
                        Success.fire({ title: response.message  });
                } else {
                        if (response.reponse==="error") {
                                return Danger.fire({ title: response.message });
                        }
                        Danger.fire({ title: "Echec de la création veuillez réessayer." });
                }
                return response;
        }
        catch (error) {
                Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                return error.message;
        }
});

/** SOUMISSION DU FORMULAIRE DE CREATION D1 Armateur
 * @param {array} data données du formulaire
 * @function 
 */
export const createNavire = createAsyncThunk('createArmateur', async (data,{ dispatch, getState }) => {
        try {
                var bodyFormData = new FormData();
                bodyFormData.append("NAME_NAV"        , data.name_nav            );
                bodyFormData.append("DESCRIPTION_NAV" , data.description_nav ??"");
                bodyFormData.append("ID_ARM" , getState().armateurs.currentArmateur.id_arm);
                bodyFormData.append("TOKEN", getThisInLocalstore("loginUtilisateur").token);
                // if (getState().currentArmateur.fileImg) {
                //         bodyFormData.append("file", getState().Armateurs.fileImg); //<- les fichiers
                // }
                var res = await fetch(`${BASEURL}${APINAMESPACE.NAVIRE}/new`, {
                        method : 'POST',
                        body   : bodyFormData
                });
                const response = await res.json();
                if (response.reponse === "success") {
                        //rattachement du navire à l'armateur
                        dispatch(loadDataArmateurList("%"));   //<- rechargement des data
                        dispatch(setModalNavire(MODALARMATEURDEFAULTSTATE)); //<- fermeture de la modale de modification
                        dispatch(setCurrentNavire(CURRENTARMATEURDEFAULTSATE));
                        Success.fire({ title: response.message  });
                } else {
                        if (response.reponse==="error") {
                                return Danger.fire({ title: response.message });
                        }
                        Danger.fire({ title: "Echec de la création veuillez réessayer." });
                }
                return response;
        }
        catch (error) {
                Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                return error.message;
        }
});

/** MODIFICATION D'Armateur 
 * @param {array} data données du formulaire
 * */
export const updateArmateur = createAsyncThunk('updateArmateur', async (data, { dispatch, getState }) => {
        try {
                var bodyFormData = new FormData();
                bodyFormData.append("NAME_ARM"        , data.name_arm            );
                bodyFormData.append("ID_ARM"          , data.id_arm              );
                bodyFormData.append("DESCRIPTION_ARM" , data.description_arm ??"");
                bodyFormData.append("TOKEN", getThisInLocalstore("loginUtilisateur").token);
                // if (getState().currentArmateur.fileImg) {
                //         bodyFormData.append("file", getState().Armateurs.fileImg); //<- les fichiers
                // }}
                var res = await fetch(`${BASEURL}${APINAMESPACE.ARMATEUR}/update`, {
                        method : 'POST',
                        body   : bodyFormData
                });
                const response = await res.json();
                if (response.reponse === "success") {
                        dispatch(loadDataArmateurList("%"));   //<- rechargement des data
                        dispatch(setModalArmateur(MODALARMATEURDEFAULTSTATE)); //<- fermeture de la modale de modification
                        Success.fire({ title:response.message });
                } else {
                        if (response.reponse === "error") {
                                return Danger.fire({ title: response.message});
                        }
                        Danger.fire({ title: "Echec de la modification veuillez réessayer." });
                }
                return response;
        } catch (error) {
                Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                return error.message;
        }
});

/** MODIFICATION D'Armateur 
 * @param {array} data données du formulaire
 * */
export const updateNavire = createAsyncThunk('updateArmateur', async (data, { dispatch, getState }) => {
        try {
                var bodyFormData = new FormData();
                bodyFormData.append("NAME_NAV"        , data.name_nav            );
                bodyFormData.append("IMMAT_NAV"       , data.immat_nav           );
                bodyFormData.append("DESCRIPTION_NAV" , data.description_nav ??"");
                bodyFormData.append("TOKEN", getThisInLocalstore("loginUtilisateur").token);
                // if (getState().currentArmateur.fileImg) {
                //         bodyFormData.append("file", getState().Armateurs.fileImg); //<- les fichiers
                // }}
                var res = await fetch(`${BASEURL}${APINAMESPACE.NAVIRE}/update`, {
                        method : 'POST',
                        body   : bodyFormData
                });
                const response = await res.json();
                if (response.reponse === "success") {
                        dispatch(loadDataArmateurList("%"));   //<- rechargement des data
                        dispatch(setModalNavire(MODALARMATEURDEFAULTSTATE)); //<- fermeture de la modale de modification
                        Success.fire({ title:response.message });
                } else {
                        if (response.reponse === "error") {
                                return Danger.fire({ title: response.message});
                        }
                        Danger.fire({ title: "Echec de la modification veuillez réessayer." });
                }
                return response;
        } catch (error) {
                Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                return error.message;
        }
});


/** SUPPRESSION D'UN Armateur */ 
export const deleteArmateur = createAsyncThunk('deleteArmateur', async (ID_Armateur, { dispatch, getState }) => {
        try {
                var bodyFormData = new FormData();
                bodyFormData.append("ID_ARM", ID_Armateur);
                bodyFormData.append("STATE_ARM", "DELETED");
                bodyFormData.append("TOKEN", getThisInLocalstore("loginUtilisateur").token);
                var res = await fetch(`${BASEURL}${APINAMESPACE.ARMATEUR}/updateState`, {
                                method : 'POST',
                                body   : bodyFormData
                });
                const response = await res.json();
                if (response.reponse === "success") {
                        dispatch(loadDataArmateurList("%"));
                        Success.fire({ title: "Suppression effectuée !" });
                } else {
                        if (response.reponse==="error") {
                                return Danger.fire({ title: response.message });
                        }
                        Danger.fire({ title: "Echec de la suppression veuillez réessayer." });
                }
                return response;
        } catch (error) {
                Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                return error.message;
        }
});

/** SUPPRESSION D'UN Armateur */ 
export const deleteNavire = createAsyncThunk('deleteArmateur', async (immat_nav, { dispatch, getState }) => {
        try {
                var bodyFormData = new FormData();
                bodyFormData.append("IMMAT_NAV", immat_nav);
                bodyFormData.append("STATE_NAV", "DELETED");
                bodyFormData.append("TOKEN", getThisInLocalstore("loginUtilisateur").token);
                var res = await fetch(`${BASEURL}${APINAMESPACE.NAVIRE}/updateState`, {
                                method : 'POST',
                                body   : bodyFormData
                });
                const response = await res.json();
                if (response.reponse === "success") {
                        dispatch(loadDataArmateurList("%"));
                        Success.fire({ title: "Suppression effectuée !" });
                } else {
                        if (response.reponse==="error") {
                                return Danger.fire({ title: response.message });
                        }
                        Danger.fire({ title: "Echec de la suppression veuillez réessayer." });
                }
                return response;
        } catch (error) {
                Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                return error.message;
        }
});


export const { setModalCompte, setmodalLockFrame,setCurrentNavire, setArmateurList, setCurrentArmateur, setLoginArmateur, setMenu, setMenuSate, setSubMenuState, setPagination, setformErreur, setFileImg, setModalArmateur, setModalNavire, status, menu } = ArmateurSlice.actions;
export default ArmateurSlice.reducer;


