import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Danger, Info, Success } from "../../globalComponents/CustomToast";
import { getCurrentPath, getThisInLocalstore, purgeStrorage, writeThisInLocalstore } from "../../globalComponents/Main";
import { PAGINATION } from "../../globalComponents/Pagination";
import { BASEROOT, BASEURL } from "../../services/serveur.js";

/** UTILISATEUR [CONVERGENCE] SYNAPSE GROUPE
* !!CENTRALISATION DES STATES UTILISATEUR
*@bessely
*@Author  : YAO BESSELY SUNDAY JUNIOR : +2250709116844 besselymail@gmail.com
*         :Codez en pensant que celui qui maintiendra votre code est un psychopathe qui connaît votre adresse.
*/

export const STATUS = Object.freeze({
        SUCCESS : 'success',
        ERROR   : 'error',
        LOADING : 'loading',
});

const APINAMESPACE = Object.freeze({
        UTILISATEUR : "/user",
        CONGIG      : "/session",
});
const MODALUTILISATEURDEFAULTSTATE       = { open: false, mode: "", title: "", button: "", inputstate: "", btnclass: "btn btn-primary my-2 text-18 col-4" };
const MODALUTILISATEURLOCKUP             = { open: false, mode: "", size: "xx", title: "Long période d'inactivité détectée", button: true, buttonName: "Toujours là !!!", btnclass: "btn btn-block btn-danger shadow" };
const CURRENTUTILISATEURDEFAULTSATE      = [{ LG_UTIID: "", STR_UTIMAIL: "", STR_UTIPHONE: "", STR_UTIPIC: "", STR_UTIFIRSTNAME: "", STR_UTILASTNAME: "", STR_UTILOGIN: "", AGENCE: [{}] }];
const MODALPROFILUTILISATEURDEFAULTSTATE = { open: false, mode: "creation", size: "profil", title: "Choisissez un profil", button: false, };
const MODALCOMPTEUTILISATEURDEFAULTSTATE = { open: false, mode: "modification", size: "compte", title: "Mon Compte", button: false, };
const initialState = {
        UtilisateurList    : [],
        loginUtilisateur   : [],
        currentUtilisateur : CURRENTUTILISATEURDEFAULTSATE,
        currentProfile     : "",
        pagination         : PAGINATION,
        formErreur         : [],
        modalUtilisateur   : MODALUTILISATEURDEFAULTSTATE,
        modalProfile       : MODALPROFILUTILISATEURDEFAULTSTATE,
        modalCompte        : MODALCOMPTEUTILISATEURDEFAULTSTATE,
        modalLockFrame     : MODALUTILISATEURLOCKUP,
        fileImg            : [],
        searchParam        : { choix: "", utilisateur: "", type: "" },
        status             : { utilisateur: STATUS.LOADING },
        menu               : [],
        subMenuState       : {},
};

export const UtilisateurSlice = createSlice({
        name: "utilisateurs",
        initialState,
        reducers: {
                setUtilisateurList    (state, action) { state.UtilisateurList    = action.payload; },
                setLoginUtilisateur   (state, action) { state.loginUtilisateur   = action.payload; },
                setCurrentUtilisateur (state, action) { state.currentUtilisateur = action.payload; },
                setCurrentProfile     (state, action) { state.currentProfile     = action.payload; },
                setPagination         (state, action) { state.pagination         = action.payload; },
                setformErreur         (state, action) { state.formErreur         = action.payload; },
                setFileImg            (state, action) { state.fileImg            = action.payload; },
                setModalUtilisateur   (state, action) { state.modalUtilisateur   = action.payload; },
                setModalProfile       (state, action) { state.modalProfile       = action.payload; },
                setModalCompte        (state, action) { state.modalCompte        = action.payload; },
                setStatus             (state, action) { state.status             = action.payload; },
                setMenu               (state, action) { state.menu               = action.payload; },
                setSubMenuState       (state, action) { state.subMenuState       = action.payload; },
                setmodalLockFrame     (state, action) { state.modalLockFrame     = action.payload; },
        },
        extraReducers: (builder) => {
                builder
                        .addCase(loadDataUtilisateurList.pending, (state, action) => {
                                state.status.utilisateur = STATUS.LOADING;
                        })
                        .addCase(loadDataUtilisateurList.fulfilled, (state, action) => {
                                if (getCurrentPath()==="Session") {
                                        if (action.payload.recordsTotal > 0) {
                                                let userListOpt = [];
                                                action.payload.data.map((item) => {
                                                        return userListOpt.push({
                                                                label    : item.name_user+ " " +item.surname_user,
                                                                value    : item.id_user,
                                                                mail     : item.email_user,
                                                                typeList : "USER",
                                                        });
                                                });
                                                state.UtilisateurList = userListOpt;
                                        }
                                }else{
                                        if (action.payload.recordsTotal > 0) {
                                                state.UtilisateurList = action.payload.data; // !<- PUBLICATION DE LA LISTE DES: UTILISATEURS DANS LE STORE
                                                state.pagination =
                                                {
                                                        listParPage     : PAGINATION.listParPage,
                                                        currentPage     : state.pagination.changePageClick ? state.pagination.currentPage : 0,  // !si rechargement ou chargement simple des data currentPage=0 sinon currentPage reste par defaut la page designée l'hors du click sur la pâgination
                                                        changePageClick : false,
                                                        listLenght      : action.payload.recordsFiltered,
                                                        nbrPage         : Math.ceil(action.payload.recordsFiltered / PAGINATION.listParPage)
                                                };
                                        } else {
                                                state.UtilisateurList = [];
                                                if (action.meta.arg.search !== undefined) { //pendant la recherche : vu que c'est le memem endpoint 
                                                        Info.fire({ title: "Aucun utilisateur trouvé !!!" });
                                                } else {
                                                        Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                                                }
                                        }
                                        state.status = { ...state.status, utilisateur: STATUS.SUCCESS };
                                }
                        })
                        .addCase(loadDataUtilisateurList.rejected, (state, action) => {
                                state.status.utilisateur = STATUS.ERROR;
                                Danger.fire({ title: "Désolé ! La liste des utilisateurs n'a pas pu être chargée" });
                        })
                        .addCase(getDataUtilisateur.fulfilled, (state, action) => {
                                if (action.payload.reponse === "success") {
                                        state.currentUtilisateur        = action.payload.data; // !<- PUBLICATION DU CHARGEMENT DE L'UTILISATEUR EN COURS DANS LE STORE
                                } else {
                                        Danger.fire({ title: "Ce utilisateur est introuvable !" });
                                }
                        })
                        .addCase(searchUtilisateur.pending, (state, action) => {
                                state.status.utilisateur = STATUS.LOADING;
                        })
                        .addCase(searchUtilisateur.fulfilled, (state, action) => {
                                if (action.payload.code_statut === "" || action.payload.code_statut === "1") {
                                        if (action.payload.data.length > 0) {
                                                var searchParam = action.meta.arg;
                                                var result = action.payload.data.filter(item => { // !!! je fais un filtre sur les resultats selon les options selectionnées
                                                        return (searchParam.choix !== "" && searchParam.type === "" && searchParam.utilisateur === "") ?  // !recherche classic seulement dans la zone de recherche 
                                                                action.payload.data // !<- je balance tt le resultats
                                                        :
                                                                null
                                                });
                                                if (result.length > 0) {
                                                        state.status = { ...state.status, utilisateur: STATUS.SUCCESS };
                                                        state.UtilisateurList = result; // !<- PUBLICATION DE LA LISTE DES: UTILISATEURS DANS LE STORE
                                                } else {
                                                        state.status = { ...state.status, utilisateur: STATUS.ERROR };
                                                        state.UtilisateurList = []; // !<- pas de resultat 
                                                        Info.fire({ title: "Désolé ! Aucun résultat trouvé !" });
                                                }
                                                state.pagination = {
                                                        listParPage: PAGINATION.listParPage,
                                                        currentPage: state.pagination.changePageClick ? state.pagination.currentPage : 0,  // !si rechargement ou chargement simple des data currentPage=0 sinon currentPage reste par defaut la page designée l'hors du click sur la pâgination 
                                                        changePageClick: false,
                                                        listLenght: action.payload.recordsTotal,
                                                        nbrPage: Math.ceil(result ?? 0 / PAGINATION.listParPage)
                                                };
                                        } else { // !<- pas de resultat  
                                                Info.fire({ title: "Désolé ! Aucun résultat trouvé !" });
                                                state.status.utilisateur = STATUS.ERROR;
                                                state.UtilisateurList = [];
                                        }
                                } else {
                                        state.UtilisateurList = [];
                                        state.status = { ...state.status, utilisateur: STATUS.ERROR };
                                        Info.fire({ title: "Désolé ! Aucun résultat trouvé !" });
                                }
                        })
                        //doConnexion
                        .addCase(doConnexion.fulfilled, (state, action) => {
                                if (action.payload.reponse === "success") {
                                        if (!action.payload?.profil) {
                                                purgeStrorage();
                                                state.loginUtilisateur = action.payload.user;
                                                writeThisInLocalstore(action.payload.user, "loginUtilisateur");
                                                writeThisInLocalstore(action.payload.user.currentProfile, "currentProfile");
                                                // prise en compte du seul profil dans le state et redirection 
                                                Info.fire({ title: "Bienvenue " + action.payload.user.name_user +" "+ action.payload.user.surname_user });
                                                setTimeout(() => {
                                                        window.location.href = BASEROOT+"/";
                                                }, 1000);
                                                return;
                                        }else{
                                                if (action.payload.profil?.length >= 2) {
                                                        // ouverture de la modale de selection de profile
                                                        state.loginUtilisateur = action.payload;
                                                        state.modalProfile = { open: true, size: "profil" };
                                                        return;
                                                }
                                        }
                                } else {
                                        state.loginUtilisateur = [];
                                        purgeStrorage();
                                        if (action.payload.reponse === "error") {
                                                Danger.fire({ title: action.payload.message });
                                        }else{ 
                                                Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" }); 
                                        }
                                }
                        })
                        .addCase(doConnexion.rejected, (state, action) => {
                                state.status.utilisateur = STATUS.ERROR;
                                Danger.fire({ title: "Désolé ! La liste des utilisateurs n'a pas pu être chargée" });
                        })
                        //doConnexion
                        .addCase(searchUtilisateur.rejected, (state, action) => {
                                state.status.utilisateur = STATUS.ERROR;
                                Danger.fire({ title: "Désolé ! La liste des utilisateurs n'a pas pu être chargée" });
                        });
        },
});

/**CHARGEMENT DES OU UTILISATEURS */
export const loadDataUtilisateurList = createAsyncThunk('loadDataUtilisateurList/fetchAll', async (data) => {
        try {
                var bodyFormData = new FormData();
                bodyFormData.append("STATE_USER", "%");
                bodyFormData.append("search[value]", data.search ?? "");
                bodyFormData.append("START", data.start ?? 0);
                bodyFormData.append("LENGTH", data.listParPage ?? 100);
                var res = await fetch(`${BASEURL}${APINAMESPACE.UTILISATEUR}/list`, {
                        method : 'POST',
                        body   : bodyFormData
                });
                const response = await res.json();
                return response;
        } catch (error) {
                Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                return error.message;
        }
});

/**RECHERCHE MANUELLE DANS LA LISTE DES UTILISATEURS SELON LES PARAMETRES DE RECHERCHE
 * @évènement keyDown sur la zone de recherche
 */
export const searchUtilisateur = createAsyncThunk('utilisateurSearch/fetchAll', async (searchParam) => {
        try {
                var bodyFormData = new FormData();
                bodyFormData.append("STATE_USER", "%");
                bodyFormData.append("search[value]", (searchParam.search ?? ""));
                bodyFormData.append("START", searchParam.start ?? 0);
                bodyFormData.append("LENGTH", searchParam.listParPage ?? 100);
                var res = await fetch(`${BASEURL}${APINAMESPACE.UTILISATEUR}/list`, {
                        method : 'POST',
                        body   : bodyFormData
                });
                const response = await res.json();
                if (response.reponse==="error") {
                        return Danger.fire({ title: response.message });
                }                
                return response;
        } catch (error) {
                Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                return error.message;
        }
});

/** CHARGEMENT D'UN UTILISATEUR SPECIFIÉ */
export const getDataUtilisateur = createAsyncThunk('utilisateur/fetchOne', async (idUtilisateur) => {
        try {
                var bodyFormData = new FormData();
                bodyFormData.append("ID_USER", idUtilisateur ?? getThisInLocalstore("loginUtilisateur").id_user);
                var res = await fetch(`${BASEURL}${APINAMESPACE.UTILISATEUR}/GetOne`, {
                        method : 'POST',
                        body   : bodyFormData
                });
                const response = await res.json();
                if (response.reponse==="error") {
                        return Danger.fire({ title: response.message });
                }
                return response;
        } catch (error) {
                Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                return error.message;
        }
});

/** SOUMISSION DU FORMULAIRE DE CREATION D1 UTILISATEUR
 * @param {array} data données du formulaire
 * @function 
 */
export const createUtilisateur = createAsyncThunk('createUtilisateur', async (data,{ dispatch, getState }) => {
        try {
                var bodyFormData = new FormData();
                bodyFormData.append("NAME_USER", data.name_user);
                bodyFormData.append("SURNAME_USER", data.surname_user);
                bodyFormData.append("EMAIL_USER", data.email_user);
                bodyFormData.append("PWD_USER", data.pwd_user);
                bodyFormData.append("STR_UTITOKEN", getThisInLocalstore("loginUtilisateur").token);
                // if (getState().currentUtilisateur.fileImg) {
                //         bodyFormData.append("file", getState().utilisateurs.fileImg); //<- les fichiers
                // }
                var res = await fetch(`${BASEURL}${APINAMESPACE.UTILISATEUR}/new`, {
                        method : 'POST',
                        body   : bodyFormData
                });
                const response = await res.json();
                if (response.reponse === "success") {
                        dispatch(loadDataUtilisateurList({ start: 0, listParPage: PAGINATION.listParPage }));   //<- rechargement des data                                      
                        dispatch(setModalUtilisateur(MODALUTILISATEURDEFAULTSTATE)); //<- fermeture de la modale de modification
                        Success.fire({ title: response.message  });
                } else {
                        if (response.reponse==="error") {
                                return Danger.fire({ title: response.message });
                        }                        
                        Danger.fire({ title: "Echec de la création veuillez réessayer." });
                }
                return response;
        }
        catch (error) {
                Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                return error.message;
        }
});

/** MODIFICATION D'UTILISATEUR 
 * @param {array} data données du formulaire
 * */
export const updateUtilisateur = createAsyncThunk('updateUtilisateur', async (data, { dispatch, getState }) => {
        try {
                var bodyFormData = new FormData();
                bodyFormData.append("ID_USER", data.id_user);
                bodyFormData.append("NAME_USER", data.name_user);
                bodyFormData.append("SURNAME_USER", data.surname_user);
                // if (getState().currentUtilisateur.fileImg) {
                //         bodyFormData.append("file", getState().utilisateurs.fileImg); //<- les fichiers
                // }}
                var res = await fetch(`${BASEURL}${APINAMESPACE.UTILISATEUR}/updateUserInfos`, {
                        method : 'POST',
                        body   : bodyFormData
                });
                const response = await res.json();
                // if (response.code_statut === "1") {
                //         dispatch(loadDataUtilisateurList({ start: 0, listParPage: PAGINATION.listParPage }));   //<- rechargement des data                                      
                //         dispatch(setModalUtilisateur(MODALUTILISATEURDEFAULTSTATE)); //<- fermeture de la modale de modification
                //         Success.fire({ title: "Correctement mis à jour !" });
                // } else {
                //         Danger.fire({ title: "Echec de la modification veuillez réessayer." });
                // }
                return response;
        } catch (error) {
                Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                return error.message;
        }
});

/** MODIFICATION D'UTILISATEUR 
 * @param {array} data données du formulaire
 * */
export const updateUserEmail = createAsyncThunk('updateUserEmail', async (data, { dispatch, getState }) => {
        try {
                var bodyFormData = new FormData();
                bodyFormData.append("ID_USER", data.id_user);
                bodyFormData.append("EMAIL_USER", data.email_user);
                // if (getState().currentUtilisateur.fileImg) {
                //         bodyFormData.append("file", getState().utilisateurs.fileImg); //<- les fichiers
                // }}
                var res = await fetch(`${BASEURL}${APINAMESPACE.UTILISATEUR}/updateUserEmail`, {
                        method : 'POST',
                        body   : bodyFormData
                });
                const response = await res.json();
                if (response.reponse === "success") {
                        dispatch(loadDataUtilisateurList({ start: 0, listParPage: PAGINATION.listParPage }));   //<- rechargement des data                                      
                        dispatch(setModalUtilisateur(MODALUTILISATEURDEFAULTSTATE)); //<- fermeture de la modale de modification
                        Success.fire({ title: "Correctement mis à jour !" });
                } else {
                        if (response.reponse==="error") {
                                return Danger.fire({ title: response.message });
                        }
                        Danger.fire({ title: "Echec de la modification veuillez réessayer." });
                }
                return response;
        } catch (error) {
                Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                return error.message;
        }
});

/** SUPPRESSION D'UN UTILISATEUR */ 
export const deleteUtilisateur = createAsyncThunk('deleteUtilisateur', async (LG_UTIID, { dispatch, getState }) => {
        try {
                var bodyFormData = new FormData();
                bodyFormData.append("ID_USER", LG_UTIID);
                bodyFormData.append("STATE_USER", "DELETED");
                bodyFormData.append("TOKEN", getThisInLocalstore("loginUtilisateur").token);
                var res = await fetch(`${BASEURL}${APINAMESPACE.UTILISATEUR}/updateUserState`, {
                                method : 'POST',
                                body   : bodyFormData
                });
                const response = await res.json();
                if (response.reponse === "success") {
                        dispatch(loadDataUtilisateurList({ start: 0, listParPage: PAGINATION.listParPage }));   //<- rechargement des data
                        Success.fire({ title: "Suppression effectuée !" });
                } else {
                        if (response.reponse==="error") {
                                return Danger.fire({ title: response.message });
                        }
                        Danger.fire({ title: "Echec de la suppression veuillez réessayer." });
                }
                return response;
        } catch (error) {
                Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                return error.message;
        }
});

/** CONNEXION D'UN UTILISATEUR 
 * @param data {object} = {STR_UTILOGIN:loggin user,STR_UTIPASSWORD: le mot de passe utilisateur}
*/
export const doConnexion = createAsyncThunk('utilisateur/connect', async (data) => {
        try {
                var bodyFormData = new FormData();
                bodyFormData.append("EMAIL_USER", data.STR_UTILOGIN);
                bodyFormData.append("PWD_USER", data.STR_UTIPASSWORD);
                bodyFormData.append("PROFIL", data?.PROFIL?? "");
                var res = await fetch(`${BASEURL}${APINAMESPACE.CONGIG}/login`, {
                        method : 'POST',
                        body   : bodyFormData
                });
                const response = await res.json();
                return response;
        } catch (error) {
                Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                return error.message;
        }
});

/** DEVEROUILLAGE D'UNE SESSION UTILISATEUR */
export const doUnlock = createAsyncThunk('utilisateur/unlock', async (data, { dispatch, getState }) => {
        try {
                var bodyFormData = new FormData();
                bodyFormData.append("STR_UTITOKEN", getLoginUtilisateur()?.token);
                bodyFormData.append("STR_UTIPASSWORD", data.STR_UTIPASSWORD);
                bodyFormData.append("ID_USER", "");
                bodyFormData.append("STATE_USER", "LOCK");
                bodyFormData.append("TOKEN", getThisInLocalstore("loginUtilisateur")?.token);
                var res = await fetch(`${BASEURL}${APINAMESPACE.UTILISATEUR}/doUnlock`, {
                        method : 'POST',
                        body   : bodyFormData
                });
                const response = await res.json();
                if (response.code_statut === "1" && response.desc_statut === "Utilisateur deverrouillé") {
                        dispatch(setmodalLockFrame(MODALUTILISATEURLOCKUP));
                } else {
                        Danger.fire({ title: "mot de passe non reconnu !" });
                }
                return response;
        } catch (error) {
                Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                return error.message;
        }
});

/** VEROUILLAGE D'UNE SESSION UTILISATEUR */
export const lockSession = createAsyncThunk('utilisateur/lockSession', async (user, { dispatch, getState }) => {
        try {
                var bodyFormData = new FormData();
                bodyFormData.append("ID_USER", "");
                bodyFormData.append("STATE_USER", "LOCK");
                bodyFormData.append("TOKEN", getThisInLocalstore("loginUtilisateur")?.token);
                var res = await fetch(`${BASEURL}${APINAMESPACE.UTILISATEUR}/lockSession`, {
                        method : 'POST',
                        body   : bodyFormData
                });
                const response = await res.json();
                if (response.reponse === "success" && response.message ==="Utilisateur verrouillé") {
                        dispatch(setmodalLockFrame({ ...(getState().utilisateurs.modalLockFrame), open: true, size: "session" }));
                }
        } catch (error) {
                Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                return error.message;
        }
});

/** DECONNEXION D'UN UTILISATEUR */
export const doDisConnexion = createAsyncThunk('utilisateur/doDisConnexion', async (landingPage) => {
        // e.preventDefault();
        try {
                var bodyFormData = new FormData();
                bodyFormData.append("TOKEN", getThisInLocalstore("loginUtilisateur").token);
                var res = await fetch(`${BASEURL}${APINAMESPACE.CONGIG}/logout`, {
                        method : 'POST',
                        body   : bodyFormData
                });
                await res.json();
                purgeStrorage();
                window.location.href = landingPage ? landingPage : BASEROOT+"/Connexion";
                return;
        } catch (error) {
                Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                purgeStrorage();
                window.location = landingPage ? landingPage : BASEROOT+"/Connexion";
                return;
        }
});

/** RECOVER PASSWORD D'UN UTILISATEUR PAR UN ADMINISTRATEUR */
export const revoverPassWord = createAsyncThunk('utilisateur/revoverPassWord', async (ID_USER) => {
        try {
                var bodyFormData = new FormData();
                bodyFormData.append("ID_USER", ID_USER);
                bodyFormData.append("TOKEN", getThisInLocalstore("loginUtilisateur").token);
                var res = await fetch(`${BASEURL}${APINAMESPACE.UTILISATEUR}/initUserPswd`, {
                        method : 'POST',
                        body   : bodyFormData
                });
                let reponse = await res.json();
                if (reponse.reponse === "success") {
                        Info.fire({ title: reponse.message  });
                }
                if (reponse.reponse === "error") {
                        Danger.fire({ title: reponse.message  });
                }
                return;
        } catch (error) {
                Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                return;
        }
});

/** REMPLACER LE PASSWORD D'UN UTILISATEUR */
export const changePassWord = createAsyncThunk('utilisateur/changePassWord', async (data, { dispatch, getState }) => {
        try {
                var bodyFormData = new FormData();
                bodyFormData.append("ID_USER", getThisInLocalstore("loginUtilisateur").id_user);
                bodyFormData.append("OLD_PWD_USER", data.OLD_PASSWORD);
                bodyFormData.append("NEW_PWD_USER", data.NEW_PASSWORD);
                var res = await fetch(`${BASEURL}${APINAMESPACE.UTILISATEUR}/updateUserPswd`, {
                        method : 'POST',
                        body   : bodyFormData
                });
                let reponse = await res.json();
                if (reponse.reponse === "success") {
                        Success.fire({ title: reponse.message });
                        dispatch(setModalCompte(MODALCOMPTEUTILISATEURDEFAULTSTATE));
                        return;
                }else{
                        if (reponse.reponse === "error") {
                                return Danger.fire({ title: reponse.message });
                        }
                }
                Danger.fire({ title: "Une erreur est survenue. Veuillez réessayer" });
        } catch (error) {
                Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                return;
        }
});

/** CHARGEMENT DES INFOS DE L'UTILISATEUR CONNECTE POUR VOIR L'ETAT DE VERROUILLAGE DE SA SESSION */
export const getVerrouillageState = () => {
        return async function asyncGetVerthunk(dispatch, getState) {
                try {
                        var bodyFormData = new FormData();
                        bodyFormData.append("ID_USER", getThisInLocalstore("loginUtilisateur")?.id_user);
                        var res = await fetch(`${BASEURL}${APINAMESPACE.UTILISATEUR}/GetOne`, {
                                method: 'POST',
                                body: bodyFormData
                        });
                        const response = await res.json();
                        if (response.reponse === "success" && response.state_user === "LOCK") {
                                dispatch(setmodalLockFrame({ ...(getState().utilisateurs.modalLockFrame), open: true, size: "session" }));
                        }
                        return response;
                } catch (error) {
                        Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                        return error.message;
                }
        };
};

/** CHARGEMENT DES INFOS DE L'UTILISATEUR CONNECTE POUR VOIR L'ETAT DE VERROUILLAGE DE SA SESSION */
export const updateUserState = (user) => {
        return async function asyncGetVerthunk(dispatch, getState) {
                try {
                        var bodyFormData = new FormData();
                        bodyFormData.append("ID_USER", user.id);
                        bodyFormData.append("STATE_USER", user.state==="ACTIF" ? "BLOCK" : "ACTIF" );
                        bodyFormData.append("TOKEN", getThisInLocalstore("loginUtilisateur")?.token);
                        var res = await fetch(`${BASEURL}${APINAMESPACE.UTILISATEUR}/updateUserState`, {
                                method: 'POST',
                                body: bodyFormData
                        });
                        const response = await res.json();
                        if (response.reponse === "success" ) {
                                dispatch(loadDataUtilisateurList({ start: 0, listParPage: PAGINATION.listParPage }));   //<- rechargement des data
                                return Success.fire({title:response.message})
                        }else{
                                if (response.reponse === "error" ) {
                                        return Danger.fire({title:response.message})
                                }
                        }
                        return response;
                } catch (error) {
                        Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                        return error.message;
                }
        };
};

export const getCurrentProfile = () => {
        let profile = getCurrentlProfilId();
        let profilList = getAllProfilUtilisateur();
        for (let index = 0; index < profilList?.length; index++) {
                if (profilList[index].lg_PROFIL_ID === profile) {
                        return profilList[index];
                }
        }
        return [];
};

/**
 * RETOURN L'UTILISATEUR EN LIGNE DEPUIS LA LOCAKSTORAGE
 * @returns [USER]
 */
export const getLoginUtilisateur = () => {
        return (getThisInLocalstore("loginUtilisateur") ? getThisInLocalstore("loginUtilisateur") : false);
};
/**
 * RETOURNE L'ID DU PROFIL DE L'UTILISATEUR EN LIGNE DEPUIS LA LOCAKSTORAGE
 * @returns USER_PRFIL_IG
 */
export const getAllProfilUtilisateur = () => {
        if (getThisInLocalstore("loginUtilisateur")?.dataPro) {
                return getThisInLocalstore("loginUtilisateur").dataPro 
        }
        return getCurrentlProfilId();
};

export const getCurrentlProfilId = () => {
        return (getThisInLocalstore("currentProfile") ? getThisInLocalstore("currentProfile") : false);
};

/** AFFICHE LA POP UP DES PROFILES */
export const popUpProfil = (e) => {
        return async function popUpProfilUtilisateurThunk(dispatch, getState) {
                e.preventDefault();
                dispatch(setModalProfile({ open: true, size: "profil" }));
        };
};

/** CHANGER DE PROFIL A CHAUD 
 *  @param e {event} l'évenement javascript
 *  @param name_profil {string} le nom du profil selectionné
*/
export const switchProfil = (e, name_profil) => {
        return async function switchProfil(dispatch, getState) {
                dispatch(setCurrentProfile(name_profil));
                dispatch(setModalProfile({ open: false, size: "profil" }));
                if (name_profil !== undefined) {
                        writeThisInLocalstore(name_profil, "currentProfile");
                        if (getCurrentPath() === "Connexion") {
                                let username= document.getElementById("username");
                                let password= document.getElementById("password");
                                dispatch(doConnexion({STR_UTILOGIN:username.value,STR_UTIPASSWORD:password.value,PROFIL:name_profil}));
                                return;
                        } else {
                                menuDestroy();
                                dispatch(menuDispose(name_profil));
                                Info.fire({ title: "Permutation vers le profil '" + name_profil + "' éffectué ! " });
                                setTimeout(() => {
                                        redirectToPremierLienAutorise();
                                }, 1000);
                        }
                }
        };
};

/** EMPECHE UN UTILISATEUR NON CONNECTÉ D'AVOIR ACCES A L'APP*/
export const sessionSurveillance = () => {
        if (!getLoginUtilisateur()) {
                window.location.href = BASEROOT+"/Connexion";
        }
};


/** PLACE LE MENU DANS Le STATE 
 * @param  LG_PROID [string] le id du profile dont le menu doit etre mis en place
*/
export const menuDispose = (name_profil) => {
        return async function asyncMenuDispose(dispatch, getState) {
                let loginUtilisateur= getState().utilisateurs?.loginUtilisateur
                // loginUtilisateur
                //A lA 1ère connexion 
                if (name_profil !== undefined && name_profil !== null && name_profil !== "" && name_profil !== 0 && getThisInLocalstore("loginUtilisateur") === undefined) {
                        if (loginUtilisateur?.profil?.length>0) {
                                let menuList = loginUtilisateur.profil.filter(menu =>menu.name_profil===name_profil);
                                dispatch(setLoginUtilisateur({ ...loginUtilisateur, groupmenu: JSON.parse(menuList[0].privilege_profil_source) }));
                                writeThisInLocalstore(loginUtilisateur, "loginUtilisateur");
                                if (menuList) {
                                        dispatch(setMenu(JSON.parse(menuList[0].privilege_profil_source)));
                                        return;
                                }
                        }else{
                                Danger.fire({ title: "Désolé ! , Vous ne disposez pas encore de droit sur les fonctionnalités. Consulter un administrateur pour vos droits sur les menus de l'application. Déconnexion en cours..." });
                                setTimeout(() => {
                                        dispatch(doDisConnexion());
                                }, 5000);
                        }
                } else {
                        //changement de profile à chaud (en étant déja connecté)
                        if (name_profil !== undefined && name_profil !== null && name_profil !== "") {
                                let menuList = loginUtilisateur?.profil.filter(menu =>menu.name_profil===name_profil);
                                dispatch(setLoginUtilisateur({ ...loginUtilisateur, groupmenu: JSON.parse(menuList[0].privilege_profil_source) }));
                                writeThisInLocalstore({ ...loginUtilisateur, groupmenu: JSON.parse(menuList[0].privilege_profil_source) }, "loginUtilisateur");
                                if (menuList) {
                                        dispatch(setMenu(JSON.parse(menuList[0].privilege_profil_source)));
                                        return;
                                }
                        }
                        //else
                        Danger.fire({ title: "Désolé ! , Vous ne disposez pas encore de droit sur les fonctionnalités. Consulter un administrateur pour vos droits sur les menus de l'application. Déconnexion en cours..." });
                        setTimeout(() => {
                                dispatch(doDisConnexion());
                        }, 5000);
                }
        };
};
        
/** DESTRUCTION DU MENU EN LOCALSTORAGE*/
export const menuDestroy = () => {
        let oldUser = getThisInLocalstore("loginUtilisateur");
        delete oldUser.groupmenu;
        writeThisInLocalstore(oldUser, "loginUtilisateur");
        return;
};

/** 
 * SURVEILLANCE D'ACCES AU MENU DEMANDER
 * 
*/
export const pageSurveillance = (page) => {
        let menuAutorise = getThisInLocalstore("loginUtilisateur") !==undefined ? getThisInLocalstore("loginUtilisateur")?.groupmenu : false;
        if (menuAutorise) {
                let menuApplati  = [];
                for (let index = 0; index < menuAutorise.length; index++) {
                        if (menuAutorise[index].link!=="") {
                                menuApplati.push(menuAutorise[index].link);
                        }
                        if (menuAutorise[index]?.submenuList.length > 0) {
                                for (let j = 0; j < menuAutorise[index]?.submenuList.length; j++) {
                                        if (menuAutorise[index].submenuList[j].link!=="") {
                                                menuApplati.push(menuAutorise[index].submenuList[j].link);
                                        }
                                }
                        }
                }
                var result = menuApplati.filter(item => {
                        return item.split("/")[1] === page;
                });
                if (result.length === 0) {
                        window.location.href = BASEROOT+"/PageNotFound"; //redirection vers l page not fond
                        Danger.fire({ title: "Vous n'avez pas accès à cette partie de l'application." });
                        return;
                }
        }
        return;
};

/** 
 * REDIRECTION VERS UNE PAGE AUTORISEE
 * 
*/
export const redirectToPremierLienAutorise = () => {
        let menuApplati = [];
        let menuAutorise = getThisInLocalstore("loginUtilisateur") !==undefined ? getThisInLocalstore("loginUtilisateur").groupmenu : false;
        if (menuAutorise) {
                for (let index = 0; index < menuAutorise.length; index++) {
                        if (menuAutorise[index].link !== "") {
                                menuApplati.push(menuAutorise[index].link);
                        }
                        if (menuAutorise[index].submenuList.length > 0) {
                                for (let j = 0; j < menuAutorise[index].submenuList.length; j++) {
                                        if (menuAutorise[index].submenuList[j].link !== "") {
                                                menuApplati.push(menuAutorise[index].submenuList[j].link);
                                        }
                                }
                        }
                }
                if (menuApplati.length > 0) {
                        if (BASEROOT==="/") {
                                if ("/"+getCurrentPath()!==menuApplati[0]) {
                                        window.location.href = menuApplati[0];
                                }
                                return;
                        }
                        //En mode serveur apache
                        if (getCurrentPath()!== menuApplati[0]) {
                                window.location.href = BASEROOT+menuApplati[0];
                        }
                        return;
                }
        }
        if (BASEROOT==="/") {
                return "/Connexion";
        }
        //En mode serveur apache
        return BASEROOT+"/Connexion";
};


export const { setModalCompte, setmodalLockFrame, setUtilisateurList, setCurrentUtilisateur, setCurrentProfile, setLoginUtilisateur, setMenu, setMenuSate, setSubMenuState, setPagination, setformErreur, setFileImg, setModalUtilisateur, setModalProfile, status, menu } = UtilisateurSlice.actions;
export default UtilisateurSlice.reducer;


