import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Danger, Info, Success } from "../../globalComponents/CustomToast";
import { getThisInLocalstore } from "../../globalComponents/Main";
import { PAGINATION } from "../../globalComponents/Pagination";
import { BASEURL } from "../../services/serveur.js";

/** DOC
* !!CENTRALISATION DES STATES DOC
*@bessely
*@Author  : YAO BESSELY SUNDAY JUNIOR : +2250709116844 besselymail@gmail.com
*         :Codez en pensant que celui qui maintiendra votre code est un psychopathe qui connaît votre adresse.
*/

// toast.configure();
export const STATUS = Object.freeze({
        SUCCESS : 'success',
        ERROR   : 'error',
        LOADING : 'loading',
});

const APINAMESPACE = Object.freeze({
        DOC      : "/document",
});
const MODALDOCDEFAULTSTATE = { open: false, mode: "ListArmateur", size: "xl", title: "Selectionner un armateur", button: false, buttonName: "Enregistrer", inputstate: "", btnclass: "btn btn-primary" };
const initialState = {
        docList         : [],
        signataire      : {},
        aborder         : { Armateur: [], Navire: [], Matelos: [], Flights: [], Anglais:"",Francais:"",signataire:{signataire_ok:"",post_signataire_ok:""},saveState:false,doc:0},
        currentArmateur : [],
        currentArmNavire: [],
        currentNavire   : [],
        currentMatelots : [],
        currentFlights  : [],
        pagination      : PAGINATION,
        formErreur      : [],
        modalList       : MODALDOCDEFAULTSTATE,
        modalListDoc    : MODALDOCDEFAULTSTATE,
        status          : { doc: STATUS.SUCCESS },
        loader          : { open: false, text: "En attente du serveur...", spinner:"img"},
};

export const DocumentsSlice = createSlice({
        name: "docs",
        initialState,
        reducers: {
                setdocList          (state, action) { state.docList          = action.payload; },
                setAborder          (state, action) { state.aborder          = action.payload; },
                setSignataire       (state, action) { state.signataire       = action.payload; },
                setCurrentArmateur  (state, action) { state.currentArmateur  = action.payload; },
                setCurrentArmNavire (state, action) { state.currentArmNavire = action.payload; },
                setCurrentNavirel   (state, action) { state.currentNavire    = action.payload; },
                setCurrentNavire    (state, action) { state.currentNavire    = action.payload; },
                setCurrentMatelots  (state, action) { state.currentMatelots  = action.payload; },
                setCurrentFlights   (state, action) { state.currentFlights   = action.payload; },
                setPagination       (state, action) { state.pagination       = action.payload; },
                setModalList        (state, action) { state.modalList        = action.payload; },
                setModalListDoc     (state, action) { state.modalListDoc     = action.payload; },
                setformErreur       (state, action) { state.formErreur       = action.payload; },
                setStatus           (state, action) { state.status           = action.payload; },
                setLoader           (state, action) { state.loader           = action.payload; },
        },
        extraReducers: (builder) => {
                builder
                        .addCase(loadDataDocList.pending, (state, action) => {
                                state.status.doc = STATUS.LOADING;
                        })
                        .addCase(loadDataDocList.fulfilled, (state, action) => {
                                if (action.payload?.reponse ==="success") {
                                        state.docList = action.payload.data;
                                        state.pagination =
                                        {
                                                listParPage     : PAGINATION.listParPage,
                                                currentPage     : state.pagination.changePageClick ? state.pagination.currentPage : 0,
                                                changePageClick : false,
                                                listLenght      : action.payload.recordsFiltered,
                                                nbrPage         : Math.ceil(action.payload.recordsFiltered / PAGINATION.listParPage)
                                        };
                                } else {
                                        Info.fire({ title: action.payload.message });
                                }
                                state.status.doc = STATUS.SUCCESS;
                        })
                        .addCase(loadDataDocList.rejected, (state, action) => {
                                state.status.doc = STATUS.ERROR;
                                Danger.fire({ title: "Désolé ! La liste des documents n'a pas pu être chargée" });
                        })
                        .addCase(getDataDoc.fulfilled, (state, action) => {
                                if (action.payload?.LG_PROID !== undefined) {
                                        // !<- PUBLICATION DU CHARGEMENT DE L'DOC EN COURS DANS LE STORE  
                                        let currenProfil = {
                                                label    : action.payload.STR_SOCDESCRIPTION,
                                                value    : action.payload.LG_SOCID,
                                                typeList : "SOCIETE",
                                        };
                                        state.currentProfil         = action.payload;
                                        state.currentProfil.SOCIETE = currenProfil;
                                } else {
                                        Danger.fire({ title: "Ce Profil semble être introuvable !" });
                                }
                                if (action.payload.code_statut !== undefined && parseInt(action.payload.code_statut) >= 2) {
                                        Danger.fire({ title: action.payload.desc_statut });
                                        return;
                                }
                        })
                        .addCase(saveDoc.pending, (state, action) => {
                                state.loader={ open: true, text: "Enregistrement en cours", spinner:"img"};
                        })
                        .addCase(saveDoc.fulfilled, (state, action) => {
                                if (action.payload.reponse==="success") {
                                        state.aborder.doc       = action.payload.id.id_doc_ok;
                                        state.aborder.saveState = true;
                                        Success.fire({ title: action.payload.message });
                                }else{
                                        Danger.fire({ title: action.payload.message });
                                }
                                state.loader={ open: false, text: "En attente du serveur...", spinner:"img"};
                        })
                        .addCase(saveDoc.rejected, (state, action) => {
                                state.loader={ open: false, text: "Enregistrement en cours", spinner:"img"};
                        })
                        .addCase(loadSignataire.fulfilled, (state, action) => {
                                if (action.payload.reponse === "success") {
                                        state.aborder.signataire = action.payload.data;
                                } else {
                                        state.aborder.signataire={signataire_ok:"",post_signataire_ok:""}
                                }
                        });
        },
});

/**CHARGEMENT DES DOCS */
export const loadDataDocList = createAsyncThunk('docList/fetchAll', async (data, { dispatch, getState }) => {
        const formatDate =(suffixe="")=>{
                if (suffixe!=="" && data[suffixe]!==undefined && data[suffixe]!=="") {
                        let xxxx = data[suffixe].split("-");
                        return xxxx[2]+"/"+xxxx[1]+"/"+xxxx[0]
                }else{
                        if (suffixe==="DT_BEGIN") {
                                return "01/01/2023";
                        }else{
                                const date = new Date();
                                const jour = date.getDate().toString().padStart(2, "0");
                                const mois = (date.getMonth() + 1).toString().padStart(2, "0");
                                const annee= date.getFullYear().toString();
                                return `${jour}/${mois}/${annee}`;
                        }
                }
        };
        try {
                var bodyFormData = new FormData();
                bodyFormData.append("search[value]", data.Navire ?? "%");
                bodyFormData.append("DATE1", formatDate("DT_BEGIN"));
                bodyFormData.append("DATE2", formatDate("DT_END"));
                bodyFormData.append("START", data.start ?? 0);
                bodyFormData.append("LENGTH", data.listParPage ?? 100);
                var res = await fetch(`${BASEURL}${APINAMESPACE.DOC}/list`, {
                        method : 'POST',
                        body   : bodyFormData
                });
                const response = await res.json();
                return response;
        } catch (error) {
                Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                return error.message;
        }
});

/** 
 * CHARGEMENT D'UN DOC DONNÉE 
 * */
export const getDataDoc = createAsyncThunk('doc/fetchOne', async (idProfil) => {
        try {
                var bodyFormData = new FormData();
                bodyFormData.append("ID_DOC", idProfil);
                var res = await fetch(`${BASEURL}${APINAMESPACE.DOC}/GetOne`, {
                        method : 'POST',
                        body   : bodyFormData
                });
                const response = await res.json();
                return response;
        } catch (error) {
                Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                return error.message;
        }
});

/**RECHERCHE MANUELLE DANS LA LISTE DES DOCS SELON LES PARAMETRES DE RECHERCHE
 * @évènement onClick
 */
export const searchDoc = createAsyncThunk('docSearch/fetchAll', async (searchParam) => {
        try {
                var bodyFormData = new FormData();
                bodyFormData.append("LG_SOCID", "");
                bodyFormData.append("search[value]", searchParam.search ?? "");
                bodyFormData.append("start", searchParam.start ?? 0);
                bodyFormData.append("length", searchParam.length ?? 250);
                var res = await fetch(`${BASEURL}${APINAMESPACE.DOC}/listProfile`, {
                        method : 'POST',
                        body   : bodyFormData
                });
                const response = await res.json();
                return response;
        } catch (error) {
                Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion " });
                return error.message;
        }
});

/** SOUMISSION DU FORMULAIRE DE CREATION D1 DOC
 * 
 * @param {array} data données du formulaire
 * @function 
 */
export const saveDoc = createAsyncThunk("createOkToBoard", async (data,{ dispatch, getState }) => {
        let aborder = getState().documents.aborder;
        try {
                var bodyFormData = new FormData();
                bodyFormData.append("DETAIL_OK" , JSON.stringify(aborder           ));
                bodyFormData.append("ID_FLIGHT" , JSON.stringify(aborder.Flights   ));
                bodyFormData.append("IMMAT_NAV" , JSON.stringify(aborder.Navire    ));
                bodyFormData.append("ID_MATELOT", JSON.stringify(aborder.Matelos   ));
                bodyFormData.append("SIGNATAIRE", JSON.stringify(aborder.signataire));
                bodyFormData.append("TOKEN", getThisInLocalstore("loginUtilisateur").token);
                var res = await fetch(`${BASEURL}${APINAMESPACE.DOC}/new`, {
                        method : 'POST',
                        body   : bodyFormData
                });
                const response = await res.json();
                if (response.reponse === "success") {
                        Success.fire({ title: response.message });
                }else{
                        Danger.fire({ title: response.message });
                }
                return response;
        }catch (error) {
                Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                return error.message;
        }
});

/** SOUMISSION DU FORMULAIRE DE CREATION D1 DOC
 * 
 * @param {array} data données du formulaire
 * @function 
 */
export const countDownloadDoc = createAsyncThunk("countDownload", async (data,{ dispatch, getState }) => {
        let aborder = getState().documents.aborder;
        if (aborder.saveState) {
                try {
                        var bodyFormData = new FormData();
                        bodyFormData.append("ID_DOC_OK" , aborder.doc===0 ? aborder.id_doc_ok : aborder.doc );
                        bodyFormData.append("TOKEN", getThisInLocalstore("loginUtilisateur").token);
                        var res = await fetch(`${BASEURL}${APINAMESPACE.DOC}/downloadcont`, {
                                method : 'POST',
                                body   : bodyFormData
                        });
                        const response = await res.json();
                        return response;
                }catch (error) {
                        Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                        return error.message;
                }
        }
});

/** MODIFICATION D'DOC 
 * @param {array} data données du formulaire
 * */
export const updateDoc = createAsyncThunk("updateDocOKtoBord", async (data,{ dispatch, getState }) => {
        let aborder = getState().documents.aborder;
        try {
                var bodyFormData = new FormData();
                bodyFormData.append("DETAIL_OK" , JSON.stringify(aborder           ));
                bodyFormData.append("ID_FLIGHT" , JSON.stringify(aborder.Flights   ));
                bodyFormData.append("IMMAT_NAV" , JSON.stringify(aborder.Navire    ));
                bodyFormData.append("ID_MATELOT", JSON.stringify(aborder.Matelos   ));
                bodyFormData.append("SIGNATAIRE", JSON.stringify(aborder.signataire));
                bodyFormData.append("TOKEN", getThisInLocalstore("loginUtilisateur").token);
                var res = await fetch(`${BASEURL}${APINAMESPACE.DOC}/update`, {
                        method : 'POST',
                        body   : bodyFormData
                });
                const response = await res.json();
                if (response.reponse === "success") {
                        Success.fire({ title: response.message });
                }else{
                        Danger.fire({ title: response.message });
                }
                return response;
        }catch (error) {
                Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                return error.message;
        }
});

/** SUPPRESSION D'UN DOC */
export function deleteDoc(ID_DOC_OK) {
        return async function deleteDocThunk(dispatch, getState) {
                try {
                        var bodyFormData = new FormData();
                        bodyFormData.append("ID_DOC_OK", ID_DOC_OK);
                        bodyFormData.append("STATE_OK", "DELETED");
                        bodyFormData.append("TOKEN", getThisInLocalstore("loginUtilisateur").token);
                        var res = await fetch(`${BASEURL}${APINAMESPACE.DOC}/updatestate`, {
                                method: 'POST',
                                body: bodyFormData
                        });
                        const response = await res.json();
                        if (response.reponse === "success") {
                                dispatch(loadDataDocList({ start: 0, listParPage: PAGINATION.listParPage }));   //<- rechargement des data
                                Success.fire({ title: "Suppression effectuée !" });
                        } else {
                                Danger.fire({ title: "Echec de la suppression veuillez réessayer." });
                        }
                        return response;
                } catch (error) {
                        Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                        return error.message;
                }
        };
}


/**CHARGEMENT DES DOCS */
export const loadSignataire = createAsyncThunk('loadSignataire/fetchAll', async (data, { dispatch, getState }) => {
        try {
                var res = await fetch(`${BASEURL}${APINAMESPACE.DOC}/signataire`, {
                        method : 'POST',
                });
                const response = await res.json();
                return response;
        } catch (error) {
                Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                return error.message;
        }
});

export const { setdocList,setAborder,setSignataire, setLoader, setCurrentArmNavire, setModalListDoc, setCurrentArmateur,setModalList, setCurrentNavirel, setCurrentNavire, setCurrentMatelots, setCurrentFlights, setPagination, setformErreur, setFileImg, status, setTabChecked } = DocumentsSlice.actions;
export default DocumentsSlice.reducer;

