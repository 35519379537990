import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Danger, Info } from "../../globalComponents/CustomToast";
import { PAGINATION } from "../../globalComponents/Pagination";
import { BASEURL } from "../../services/serveur";

/** PISTEAUDITE [CONVERGENCE]
* !!CENTRALISATION DES STATES PISTEAUDITE
*@bessely
*@Author  : YAO BESSELY SUNDAY JUNIOR : +2250709116844 besselymail@gmail.com
*         :Codez en pensant que celui qui maintiendra votre code est un psychopathe qui connaît votre adresse.
*/

export const STATUS = Object.freeze({
        SUCCESS : 'success',
        ERROR   : 'error',
        LOADING : 'loading',
});

const APINAMESPACE = Object.freeze({
        SESSION: "/session",
});
const CURRENTPISTEAUDITEDEFAULTSATE = [{ LG_AGEID: "", STR_AGECODE: "", STR_AGECODEBCEAO: "", STR_AGEDESCRIPTION: "", STR_AGELOCALISATION: "", STR_AGEBP: "", STR_AGEMAIL: "", STR_AGEPHONE: "", STR_VILLE: "", STR_AGELONGITUDE: "", STR_AGELATITUDE: "" }];
const initialState = {
        pisteAuditList        : [],
        listPisteAuditOptions : [],
        currentPisteAudit     : CURRENTPISTEAUDITEDEFAULTSATE,
        pagination            : PAGINATION,
        formErreur            : [],
        fileImg               : [],
        searchParam           : { choix: "", pisteaudite: "", type: "" },
        status                : { pisteaudite: STATUS.LOADING },
        selectedUser          : "",
};

export const PisteAuditSlice = createSlice({
        name: "session",
        initialState,
        reducers: {
                setPisteAuditList    (state, action) { state.pisteAuditList    = action.payload; },
                setCurrentPisteAudit (state, action) { state.currentPisteAudit = action.payload; },
                setPagination        (state, action) { state.pagination        = action.payload; },
                setformErreur        (state, action) { state.formErreur        = action.payload; },
                setFileImg           (state, action) { state.fileImg           = action.payload; },
                setStatus            (state, action) { state.status            = action.payload; },
                setSelectedUser      (state, action) { state.selectedUser      = action.payload; },
        },
        extraReducers: (builder) => {
                builder
                        .addCase(loadDataSessionConsole.pending, (state, action) => {
                                state.status.pisteaudite = STATUS.LOADING;
                        })
                        .addCase(loadDataSessionConsole.fulfilled, (state, action) => {
                                if (action.payload.data?.length > 0) {
                                        state.pisteAuditList = action.payload.data; // !<- PUBLICATION DE LA LISTE DES: PISTEAUDITES DANS LE STORE
                                        state.pagination =
                                        {
                                                listParPage     : PAGINATION.listParPage,
                                                currentPage     : state.pagination.changePageClick ? state.pagination.currentPage : 0,  // !si rechargement ou chargement simple des data currentPage=0 sinon currentPage reste par defaut la page designée l'hors du click sur la pâgination
                                                changePageClick : false,
                                                listLenght      : action.payload.recordsFiltered,
                                                nbrPage         : Math.ceil(action.payload.recordsFiltered / PAGINATION.listParPage)
                                        };
                                } else {
                                        state.pisteAuditList = [];
                                        if (action.meta.arg.search !== undefined) {
                                                Info.fire({ title: "Aucune activité trouvée" });
                                        } else {
                                                Danger.fire({ title: "Aucune activité trouvée" });
                                        }
                                        state.pagination =
                                        {
                                                listParPage     : PAGINATION.listParPage,
                                                currentPage     : 0,
                                                changePageClick : false,
                                                listLenght      : 0,
                                                nbrPage         : 0
                                        };
                                }
                                state.status = { ...state.status, pisteaudite: STATUS.SUCCESS };
                        })
                        .addCase(loadDataSessionConsole.rejected, (state, action) => {
                                state.status.pisteaudite = STATUS.ERROR;
                                Danger.fire({ title: "Désolé ! La liste des session n'a pas pu être chargée" });
                        })
        },
});

/**CHARGEMENT DES ENTREPRISES OU PISTEAUDITES */
export const loadDataSessionConsole = createAsyncThunk('pisteauditeList/fetchAll', async (data) => {
        const formatDate =(suffixe="")=>{
                if (suffixe!=="" && data[suffixe]!==undefined && data[suffixe]!=="") {
                        let xxxx = data[suffixe].split("-");
                        return xxxx[2]+"/"+xxxx[1]+"/"+xxxx[0]
                }else{
                        const date = new Date();
                        const jour = date.getDate().toString().padStart(2, "0");
                        const mois = (date.getMonth() + 1).toString().padStart(2, "0");
                        const annee = date.getFullYear().toString();
                        return `${jour}/${mois}/${annee}`;
                }
        };
        try {
                var bodyFormData = new FormData();
                bodyFormData.append("ID_USER", data.ID_USER ?? "%");
                bodyFormData.append("USE_PROFIL","%");
                bodyFormData.append("SESSION_STATE", data.SESSION_STATE ?? "%");
                bodyFormData.append("DATE1", formatDate("DT_BEGIN"));
                bodyFormData.append("DATE2", formatDate("DT_END"));
                bodyFormData.append("START", data.start ?? 0);
                bodyFormData.append("LENGTH", data.listParPage ?? 10);
                var res = await fetch(`${BASEURL}${APINAMESPACE.SESSION}/console`, {
                                method : 'POST',
                                body   : bodyFormData
                });
                const response = await res.json();
                return response;
        } catch (error) {
                Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                return error.message;
        }
});


export const { setSelectedUser, setPisteAuditList, setCurrentPisteAudit, setPagination, setformErreur, setFileImg, status } = PisteAuditSlice.actions;
export default PisteAuditSlice.reducer;