
/** 
 * !!RAPPROCHEMENT BANCAIRE [CONVERGENCE]
* 
*@bessely
*@Author  : YAO BESSELY SUNDAY JUNIOR : +2250709116844 besselymail@gmail.com
*         :Codez en pensant que celui qui maintiendra votre code est un psychopathe qui connaît votre adresse.
**/
import React, { lazy, useEffect } from "react";
import { Provider } from "react-redux";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Store } from "./Store";
import Compte from "./globalComponents/Compte";
import CustomLoader from './globalComponents/CustomLoader';
import { getCurrentPath, packageJSON } from "./globalComponents/Main";
import { BASEROOT } from "./services/serveur";
import { pageSurveillance, sessionSurveillance } from './store/Utilisateurs/Utilisateur';
//Convergence principal component | lazy loading permet de charger les composants uniquement au besoin  { ça rend l'appli plus lentes en pricipe mais plus performante et moin groumande en memoire mais une fois builder la lenteur est imperceptible pour le client}
const Session         = lazy(() => import("./pages/session/Session"));
const Forget          = lazy(() => import("./pages/forget/Forget"));
const Document        = lazy(() => import("./pages/document/Document"));
const Transport       = lazy(() => import("./pages/transport/Transport"));
const Armateur        = lazy(() => import("./pages/armateur/Armateur"));
const Dashboard       = lazy(() => import("./pages/dashboard/Dashboard"));
const Profil          = lazy(() => import("./pages/profil/Profil"));
const Connexion       = lazy(() => import("./pages/connexion/Connexion"));
const Utilisateur     = lazy(() => import("./pages/utilisateur/Utilisateur"));
const Matelot         = lazy(() => import("./pages/matelot/Matelot"));
const PageNotFound    = lazy(() => import("./pages/PageNotFound"));

function App() {
  useEffect(() => {
    toast.configure();
    console.log(`[Bienvenue sur ${packageJSON.name.toLocaleUpperCase()}@${packageJSON.version} application web de rapprochement bancaire développée par SYNAPSE GROUPE.]`); // la page courante
    console.log("La page en cours : " + getCurrentPath()); // la page courante
    if (process.env.NODE_ENV==="production") {
      console.log   = function() {}; // desactive tous les console.log en production
      console.error = function() {}; // desactive tous les console.log en production
    }
    if (getCurrentPath() !== "Connexion" && getCurrentPath() !== "Forget" && getCurrentPath() !== "PageNotFound") {
      sessionSurveillance();
      pageSurveillance(getCurrentPath());
    }
  }, []);
  return (
    <Provider store={Store}>
      {
        getCurrentPath() !== "Connexion" && getCurrentPath() !== "Forget" && getCurrentPath() !== "PageNotFound" ? 
          <>
            <Compte />
            {/* <SessionTimeOut /> */}
            <CustomLoader/>
          </>
        :
          null
      }
      <Router basename={BASEROOT}>
        <Routes>
          <Route path = "/"               element={<Dashboard      />} />
          <Route path = "/Document"       element={<Document       />} />
          <Route path = "/Transport"      element={<Transport      />} />
          <Route path = "/Armateur"       element={<Armateur       />} />
          <Route path = "/Session"        element={<Session        />} />
          <Route path = "/Equipage"       element={<Matelot        />} />
          <Route path = "/Profil"         element={<Profil         />} />
          <Route path = "/Utilisateur"    element={<Utilisateur    />} />
          <Route path = "/Connexion"      element={<Connexion      />} />
          <Route path = "/Forget"         element={<Forget         />} />
          <Route path = "/PageNotFound"   element={<PageNotFound   />} />
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
