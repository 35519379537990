
/** RAPPROCHEMENT [CONVERGENCE]
* !!CENTRALISATION DES STATES RAPPROCHEMENT
*@bessely
*@Author  : YAO BESSELY SUNDAY JUNIOR : +2250709116844 besselymail@gmail.com
*         :Codez en pensant que celui qui maintiendra votre code est un psychopathe qui connaît votre adresse.
*/

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Swal from 'sweetalert2';
import { Danger, Info, Success } from "../../globalComponents/CustomToast";
import { getCurrentPath, getThisInLocalstore, writeThisInLocalstore } from "../../globalComponents/Main.js";
import { PAGINATION } from "../../globalComponents/Pagination";
import { BASEURL } from "../../services/serveur.js";

export const STATUS = Object.freeze({
        SUCCESS : 'success',
        WAITING : 'waiting',
        ERROR   : 'error',
        LOADING : 'loading',
});

const APINAMESPACE = Object.freeze({
        RAPPROCHEMENT : "/RapproManager",
        FILE          : "/FileManager",
        CONF          : "/ConfigurationManager",
});

const MODALCRITEREDEFAULTSTATE    = { open: false, mode:"", size: "critère", title: "Liste des critères de rapprochements automatiques", button:true,  buttonName:"Demarrer Le rapprochement", inputstate: "", btnclass: "btn btn-primary" };
const MODALMOVERAPPRODEFAULTSTATE = { open: false, mode:"", size: "moveRappro", title: "Liste des critères de rapprochements automatiques", button:true,  buttonName:"Demarrer Le rapprochement", inputstate: "", btnclass: "btn btn-primary" };
const MODALVALIDATION             = { open: false, mode: "creation", size: "xl", title: "", button: true, buttonName: "Enregistrer", inputstate: "", btnclass: "btn btn-primary" };
const MODALCOMMENT                = { open: false, mode: "creation", size: "xl", title: "", button: true, buttonName: "Enregistrer", inputstate: "", btnclass: "btn btn-primary" };
const BROUILLONDEFAULTSTATE       = {BROUILLONS:[]};
const DATECRITEREDEFAULTSTATE     = {DT_MVTDATEDOPEDEB: "", DT_MVTDATEDOPEFIN: "", DT_MVTDATEVALDEB: "", DT_MVTDATEVALFIN: ""};
const initialState = {
        currentDraggableItem  : [],
        mtxDraggbleList       : [],
        mvtDraggbleList       : [],
        listCompte            : [],
        rowRapprochement      : [],
        rowResRapprochement   : [],
        rowDraftRapprochement : BROUILLONDEFAULTSTATE,
        currentCompte         : "",
        operations            : {dataMvt:[],dataMtx:[],sumMtxMnt:0,sumMvtMnt:0},
        operationsRappro      : [],
        operationsCount       : [{miroirActive:0,nostroActive:0,totalMiroir:0,totalNostro:0}],
        entete                : [],
        currentEntete         : {"COLUMN":"", "SENS":""},
        criteres              : [],
        rsmcriteres           : [],
        Listcriteres          : [],
        datescritere          : DATECRITEREDEFAULTSTATE,
        modalcriteres         : MODALCRITEREDEFAULTSTATE,
        modalMoveRappro       : MODALMOVERAPPRODEFAULTSTATE,
        listParPage           : getThisInLocalstore("loginUtilisateur")?.DBL_DATASIZE,
        paginationMvt         : {listParPage:getThisInLocalstore("loginUtilisateur")?.DBL_DATASIZE, currentPage:0, changePageClick:false, listLenght:0, nbrPage:0},
        paginationMtx         : {listParPage:getThisInLocalstore("loginUtilisateur")?.DBL_DATASIZE, currentPage:0, changePageClick:false, listLenght:0, nbrPage:0},
        paginationEntete      : {listParPage:PAGINATION.listParPage, currentPage:0, changePageClick:false, listLenght:0, nbrPage:0},
        formErreur            : [],
        fileImg               : [],
        searchParam           : { choix: "", listCompte: "", type: "" },
        status                : { operation: STATUS.ERROR, resppprochement: STATUS.ERROR, brouillonRappro: STATUS.ERROR, waiting: STATUS.ERROR,  waitingX: STATUS.ERROR },
        loader                : { open: false, text: "En attente du serveur...", spinner:"img"},
        modalconfirmation     : { open: false, mode: "", size: "confirmation", title: "", button: true, buttonName: "Copié", inputstate: "", btnclass: "btn btn-primary" },
        indexRaprochement     : "",
        listEntete            : {rejet:[],totalrejet:0, attente:[], totalattente:0},
        currentEntetePage     : {rejet:0, attente:0},
        enteteEncours         : "",
        fileInfos             : {extension: "", name: "", size: "", type: "", url: ""},
        modalvalidation       : MODALVALIDATION,
        modalcomment          : MODALCOMMENT,
        statustRapp           : "",
        listFileSwift         : [],
        html                  : "",
        clipboard             : "",
};

export const RapprochementSlice = createSlice({
        name: "rapprochements",
        initialState,
        reducers: {
                setListCompte            (state, action) { state.listCompte            = action.payload; },
                setCurrentDraggableItem  (state, action) { state.currentDraggableItem  = action.payload; },
                setMtxDraggbleList       (state, action) { state.mtxDraggbleList       = action.payload; },
                setMvtDraggbleList       (state, action) { state.mvtDraggbleList       = action.payload; },
                setCurrentCompte         (state, action) { state.currentCompte         = action.payload; },
                setOperations            (state, action) { state.operations            = action.payload; },
                setRowRapprochement      (state, action) { state.rowRapprochement      = action.payload; },
                setResRowRapprochement   (state, action) { state.rowResRapprochement   = action.payload; },
                setRowDraftRapprochement (state, action) { state.rowDraftRapprochement = action.payload; },
                setOperationsCount       (state, action) { state.operationsCount       = action.payload; },
                setEntete                (state, action) { state.entete                = action.payload; },
                setCurrentEntete         (state, action) { state.currentEntete         = action.payload; },
                setCriteres              (state, action) { state.criteres              = action.payload; },
                setRsmcriteres           (state, action) { state.rsmcriteres           = action.payload; },
                setListCriteres          (state, action) { state.Listcriteres          = action.payload; },
                setModalcriteres         (state, action) { state.modalcriteres         = action.payload; },
                setModalMoveRappro       (state, action) { state.modalMoveRappro       = action.payload; },
                setDatescritere          (state, action) { state.datescritere          = action.payload; },
                setformErreur            (state, action) { state.formErreur            = action.payload; },
                setFileImg               (state, action) { state.fileImg               = action.payload; },
                setStatus                (state, action) { state.status                = action.payload; },
                setLoader                (state, action) { state.loader                = action.payload; },
                setIndexRaprochement     (state, action) { state.indexRaprochement     = action.payload; },
                setFileInfos             (state, action) { state.fileInfos             = action.payload; },
                setModalconfirmation     (state, action) { state.fileInfos             = action.payload; },
                setPaginationEntete      (state, action) { state.paginationEntete      = action.payload; },
                setPaginationMvt         (state, action) { state.paginationMvt         = action.payload; },
                setPaginationMtx         (state, action) { state.paginationMtx         = action.payload; },
                setListEntete            (state, action) { state.listEntete            = action.payload; },
                setCurrentEntetePage     (state, action) { state.currentEntetePage     = action.payload; },
                setEnteteEncours         (state, action) { state.enteteEncours         = action.payload; },
                setModalValidation       (state, action) { state.modalvalidation       = action.payload; },
                setModalComment          (state, action) { state.modalcomment          = action.payload; },
                setStatustRapp           (state, action) { state.statustRapp           = action.payload; },
                setListFileSwift         (state, action) { state.listFileSwift         = action.payload; },
        },
        extraReducers: (builder) => {
                builder
                        .addCase(loadDataListOperation.pending, (state, action) => {
                                state.status.operation = STATUS.LOADING;
                        })
                        .addCase(getRapproFromDraft.pending, (state, action) => {
                                state.status.brouillonRappro = STATUS.LOADING;
                                state.statustRapp          = "";
                                state.loader.open            = true;
                                state.loader.text            = "Chargement du brouillon...";
                        })
                        .addCase(listOperationByEntete.pending, (state, action) => {
                                state.statustRapp     = "";
                                state.status.waitingX = STATUS.LOADING;
                                state.loader.open     = true;
                                state.loader.text     = "Chargement des opérations en cours...";
                        })
                        .addCase(searchValideOperation.pending, (state, action) => {
                                state.statustRapp     = "";
                                state.status.waitingX = STATUS.LOADING;
                                state.loader.open     = true;
                                state.loader.text     = "Chargement des opérations en cours...";
                        })
                        .addCase(listEnteteRapprochement.pending, (state, action) => {
                                state.status.waiting = STATUS.LOADING;
                        })
                        .addCase(showAllOrOneFileLo.pending, (state, action) => {
                                state.status.waiting = STATUS.LOADING;
                        })
                        .addCase(listEnteteRappValid.pending, (state, action) => {
                                state.status.waiting = STATUS.LOADING;
                        })
                        .addCase(listEnteteRapprochementAll.pending, (state, action) => {
                                state.status.waiting = STATUS.LOADING;
                        })
                        .addCase(searchDataListOperation.pending, (state, action) => {
                                state.status.operation = STATUS.LOADING;
                        })
                        .addCase(printReport.pending, (state, action) => {
                                state.loader.open = true;
                                state.loader.text = "Production du document ...";
                        })
                        .addCase(fireAutoRapro.pending, (state, action) => {
                                state.loader.open = true;
                                state.loader.text = "Rapprochement automatique en cours...";
                        })
                        .addCase(validationRaprochement.pending, (state, action) => {
                                state.loader.open = true;
                                state.loader.text = "En cours de traitement...";
                        })
                        .addCase(undoRaprochement.pending, (state, action) => {
                                state.loader.open = true;
                                state.loader.text = "En cours de dérapprochement...";
                        })
                        .addCase(saveRapproForValidation.pending, (state, action) => {
                                state.loader.open = true;
                                state.loader.text = "Enregistrement en cours...";
                        })
                        .addCase(saveRapproAsDraft.pending, (state, action) => {
                                state.loader.open = true;
                                state.loader.text = "Sauvegarde du brouillon...";
                        })
                        .addCase(saveCriteresHistorique.fulfilled, (state, action) => {
                                if (action.payload.code_statut==="0") {
                                        Danger.fire({ title: "Echec de la sauvegarde des historiques de critères." });
                                }
                        })
                        .addCase(loadDataListSoccompte.fulfilled, (state, action) => {
                                if (action.payload.recordsTotal > 0) {
                                        let list = [];
                                        action.payload.data.map((item) => {
                                                let NameMir   = item.STR_SCCNAMEMIR !== "" ? item.STR_SCCNAMEMIR : "XXXXXXXXXXX"
                                                let NameNos   = item.STR_SCCNAMENOS !== "" ? item.STR_SCCNAMENOS : "XXXXXXXXXXX"
                                                let CompteMir = item.STR_SCCNCPTEMIR;
                                                let CompteNos = item.STR_SCCNCPTENOSTRO;
                                                let labelx    = "";
                                                if (CompteMir === CompteNos) {
                                                        labelx = NameMir + "  : " + CompteMir + " " + item.STR_SCCDEVIS;
                                                }else{
                                                        labelx = NameMir + " : " + CompteMir + " ||<======== " + item.STR_SCCDEVIS +" =======>|| " + NameNos + " : " + CompteNos;
                                                }
                                                return list.push({
                                                        label            : labelx,
                                                        value            : item.LG_SCCID,
                                                        MIROIR           : CompteMir,
                                                        NOSTRO           : CompteNos,
                                                        STR_SCCSOLDEMIR  : item.STR_SCCSOLDEMIR,
                                                        STR_SCCSOLDENOS  : item.STR_SCCSOLDNOSTRO,
                                                        LG_CORID         : item.LG_CORID,
                                                        LG_SCCID         : item.LG_SCCID,
                                                        STR_SCCSENSMIR   : item.STR_SCCSENSMIR,
                                                        STR_SCCSENSNOS   : item.STR_SCCSENSNOS,
                                                        DT_SCCSOLDMIROIR : item.DT_SCCSOLDMIROIR,
                                                        DT_SCCSOLDNOSTRO : item.DT_SCCSOLDNOSTRO,
                                                        STR_SCCNAMEMIR   : item.STR_SCCNAMEMIR,
                                                        STR_SCCNAMENOS   : item.STR_SCCNAMENOS,
                                                        STR_SCCDEVIS     : item.STR_SCCDEVIS,
                                                        typeList         : "COMPTE",
                                                });
                                        });
                                        state.listCompte = [];
                                        state.operations = [];
                                        // !!cette partie la seulement c'est entre moi et DIEU le père | si tu as compris c'est que tu es un enfant bénie
                                        if (document.getElementsByClassName("css-1jwi8f4-singleValue")[1]) {
                                                document.getElementsByClassName("css-1jwi8f4-singleValue")[1].innerHTML="";
                                        }
                                        state.listCompte = list;
                                        Info.fire({ title: list.length + " Compte(s) trouvé(s)..." });
                                } else {
                                        state.listCompte = [];
                                        state.operations = [];
                                        if (document.getElementsByClassName("css-1jwi8f4-singleValue")[1]) {
                                                document.getElementsByClassName("css-1jwi8f4-singleValue")[1].innerHTML="";
                                        }
                                        Info.fire({ title: "Aucun compte trouvé" });
                                }
                        })
                        .addCase(loadDataListOperation.fulfilled, (state, action) => {
                                state.operations       = {dataMvt:[],dataMtx:[],sumMtxMnt:0,sumMvtMnt:0}; //vidage de la liste des opérations
                                if (action?.meta?.arg?.afterDel===undefined) {
                                        state.rowRapprochement = []; //vidage de la liste des opérations rapprochées
                                }
                                if (action?.payload?.code_statut === "0") {
                                        state.status.operation = STATUS.ERROR;
                                        state.operationsCount  = [{miroirActive:0,nostroActive:0,totalMiroir:0,totalNostro:0}];
                                        Info.fire({ title: action.payload.desc_statut });
                                }else{
                                        state.status.operation     = STATUS.SUCCESS;
                                        if (action?.payload?.dataMvt?.length >= 1) {
                                                state.operations.dataMvt              = action.payload.dataMvt;
                                                state.operations.sumMvtMnt            = action.payload.sumMvtMnt;
                                                state.operationsCount[0].miroirActive = action.payload.dataMvt?.length;
                                                state.operationsCount[0].totalMiroir  = action.payload.recordsFilteredMvt;
                                                document.getElementsByClassName('cumulSuspend')[0].innerHTML = action.payload.sumMvtMnt;
                                                state.paginationMvt =
                                                {
                                                        listParPage     : state.listParPage,
                                                        currentPage     : state.paginationMvt.changePageClick ? state.paginationMvt.currentPage : 0,  // !si rechargement ou chargement simple des data currentPage=0 sinon currentPage reste par defaut la page designée l'hors du click sur la pâgination
                                                        changePageClick : false,
                                                        listLenght      : action.payload.recordsFilteredMvt,
                                                        nbrPage         : Math.ceil(action.payload.recordsFilteredMvt / state.listParPage)
                                                };
                                        }
                                        if (action?.payload?.dataMtx?.length >= 1) {
                                                state.operations.dataMtx              = action.payload.dataMtx;
                                                state.operations.sumMtxMnt            = action.payload.sumMtxMnt;
                                                state.operationsCount[0].nostroActive = action.payload.dataMtx?.length;
                                                state.operationsCount[0].totalNostro  = action.payload.recordsFilteredMtx;
                                                document.getElementsByClassName('cumulSuspend')[1].innerHTML = action.payload.sumMtxMnt;
                                                state.paginationMtx =
                                                {
                                                        listParPage     : state.listParPage,
                                                        currentPage     : state.paginationMtx.changePageClick ? state.paginationMtx.currentPage : 0,  // !si rechargement ou chargement simple des data currentPage=0 sinon currentPage reste par defaut la page designée l'hors du click sur la pâgination
                                                        changePageClick : false,
                                                        listLenght      : action.payload.recordsFilteredRappMtx,
                                                        nbrPage         : Math.ceil(action.payload.recordsFilteredMtx / state.listParPage)
                                                };
                                        }
                                        // if (action.payload?.dataMtx.length ===0){
                                        //         state.operations       = [];
                                        //         state.status.operation = STATUS.ERROR;
                                        //         state.operationsCount  = [{nostroActive:0,totalMiroir:0}];
                                        //         Info.fire({ title: "Aucune opération trouvée" });
                                        // }
                                }
                        })
                        .addCase(listEnteteRapprochement.fulfilled, (state, action) => {
                                state.listEntete = [];
                                if (action.payload.code_statutR === "1") {
                                        state.listEntete.rejet        = action.payload.RapprochementRejet;
                                        state.listEntete.totalrejet   = action.payload.recordsTotalRapprochementRejet;
                                } else {
                                        state.listEntete.rejet        = [];
                                }
                                if (action.payload.code_statut === "1") {
                                        state.listEntete.attente      = action.payload.Rapprochement;
                                        state.listEntete.totalattente = action.payload.recordsTotalRapprochement;
                                }else{
                                        state.listEntete.attente      = [];
                                }
                                state.status.waiting = STATUS.SUCCESS;
                        })
                        .addCase(listEnteteRappValid.fulfilled, (state, action) => {
                                state.listEntete = [];
                                if (action.payload?.RapprochementValid?.length >0) {
                                        state.listEntete.attente      = action.payload.RapprochementValid;
                                        state.listEntete.totalattente = action.payload.RapprochementValid.length;
                                        state.paginationEntete =
                                        {
                                                listParPage     : PAGINATION.listParPage,
                                                currentPage     : state.paginationEntete.changePageClick ? state.paginationEntete.currentPage : 0,  // !si rechargement ou chargement simple des data currentPage=0 sinon currentPage reste par defaut la page designée l'hors du click sur la pâgination
                                                changePageClick : false,
                                                listLenght      : action.payload.recordsTotalRapprochement,
                                                nbrPage         : Math.ceil(action.payload.recordsTotalRapprochement / PAGINATION.listParPage)
                                        };
                                } else {
                                        state.listEntete.attente = [];
                                }
                                state.status.waiting = STATUS.SUCCESS;
                        })
                        .addCase(showAllOrOneFileLo.fulfilled   , (state, action) => {
                                state.status.waiting = STATUS.SUCCESS;
                                if (action.payload.data.length>=1) {
                                        state.listFileSwift  = action.payload.data;
                                                state.paginationEntete =
                                                {
                                                        listParPage     : PAGINATION.listParPage,
                                                        currentPage     : state.paginationEntete.changePageClick ? state.paginationEntete.currentPage : 0,  // !si rechargement ou chargement simple des data currentPage=0 sinon currentPage reste par defaut la page designée l'hors du click sur la pâgination
                                                        changePageClick : false,
                                                        listLenght      : action.payload.recordsFiltered,
                                                        nbrPage         : Math.ceil(action.payload.recordsTotal / PAGINATION.listParPage)
                                                };
                                }else{
                                        state.listFileSwift   = [];
                                        state.paginationEntete= {listParPage:PAGINATION.listParPage, currentPage:0, changePageClick:false, listLenght:0, nbrPage:0};
                                }
                        })
                        .addCase(listEnteteRapprochementAll.fulfilled, (state, action) => {
                                state.listEntete = [];
                                if (action.payload.code_statut === "1") {
                                        state.listEntete.attente      = action.payload.Rapprochement;
                                        state.paginationEntete =
                                        {
                                                listParPage     : PAGINATION.listParPage,
                                                currentPage     : state.paginationEntete.changePageClick ? state.paginationEntete.currentPage : 0,  // !si rechargement ou chargement simple des data currentPage=0 sinon currentPage reste par defaut la page designée l'hors du click sur la pâgination
                                                changePageClick : false,
                                                listLenght      : action.payload.recordsTotalRapprochement,
                                                nbrPage: Math.ceil(action.payload.recordsTotalRapprochement / PAGINATION.listParPage)
                                        };
                                } else {
                                        state.listEntete.attente = [];
                                }
                                state.status.waiting = STATUS.SUCCESS;
                        })
                        .addCase(listWaitingRapprochement.fulfilled, (state, action) => { //!OBSOLETE
                                state.rowResRapprochement = [];
                                state.rowRapprochement    = [];
                                let list                  = [];
                                if (action.payload.code_statut==="1") {
                                        state.rowResRapprochement    = action.payload.Rapprochement;
                                        state.status.waiting = STATUS.SUCCESS;
                                }
                                if (action.payload?.RapprochementRejet?.length > 0) { // traitement des rejets
                                        action.payload.RapprochementRejet.map((item,x) => {
                                                for (let index = 0; index < item.RapproRejet.length; index++) {
                                                        list.push({
                                                                miroirActive : item.RapproRejet[index].miroirActive,
                                                                nostroActive : item.RapproRejet[index].nostroActive,
                                                        });
                                                }
                                                return list;
                                        });
                                        state.rowRapprochement    = list;
                                }else{
                                        state.rowRapprochement       = [];
                                        state.status.waiting = STATUS.ERROR;
                                }
                        })
                        .addCase(listOperationByEntete.fulfilled, (state, action) => {
                                let list                  = [];
                                state.rowResRapprochement = [];
                                state.rowRapprochement    = [];
                                state.status.waitingX     = STATUS.SUCCESS;
                                state.statustRapp         = "";
                                state.loader.open         = false;
                                // traitement des en attentes
                                if (action.payload?.Rappro?.length > 0) {
                                        state.rowResRapprochement = action.payload.Rappro;
                                }
                                // traitement des Validés
                                if (action.payload?.RapproValid?.length > 0) {
                                        state.rowResRapprochement = action.payload.RapproValid;
                                }
                                // traitement des rejets
                                if (action.payload?.RapproRejet?.length > 0) {
                                        state.statustRapp = action.payload.RapproRejet.length + " ligne(s) opération(s) rejetée(s). Motif : " + action.payload.RapproRejet[0].miroirActive[0].STR_RAPPROCOMMENT;
                                        action.payload.RapproRejet.map((item, x) => {
                                                list.push({
                                                        miroirActive: item.miroirActive,
                                                        nostroActive: item.nostroActive,
                                                });
                                                return list;
                                        });
                                        state.rowRapprochement = list;
                                }
                        })
                        .addCase(searchValideOperation.fulfilled, (state, action) => {
                                state.rowResRapprochement = [];
                                state.rowRapprochement    = [];
                                state.status.waitingX     = STATUS.SUCCESS;
                                state.statustRapp         = "";
                                state.loader.open         = false;
                                // traitement des en attentes
                                if (action.payload?.Rappro?.length > 0) {
                                        state.rowResRapprochement = action.payload.Rappro;
                                }
                                // traitement des Validés
                                if (action.payload?.RapproValid?.length > 0) {
                                        state.rowResRapprochement = action.payload.RapproValid;
                                }
                        })
                        .addCase(getcritereRapprochement.fulfilled, (state, action) => {
                                if (action.payload.data.length > 0 || action.payload.data.length > 0) {
                                        state.Listcriteres = action.payload.data;
                                        let list           = [];
                                        let listx          = [];
                                        state.Listcriteres.map((item) => { 
                                                list.push({[item.str_Lstothervalue] : "0"}); // pour la selection des criteres | JUSTE DE SQUELETTE VIDE A CE STATDE QUE JE VAIS ENSUITE ENRICHIR AU FUR A MESUR DES SELECTIONS
                                                listx.push({[item.str_Lstdescription] : ""}); // pour affichage des critere selectionnés en resuumé | JUSTE DE SQUELETTE VIDE A CE STATDE QUE JE VAIS ENSUITE ENRICHIR AU FUR A MESUR DES SELECTIONS
                                                return true;
                                        });
                                        state.criteres     = list;
                                        state.rsmcriteres  = listx;
                                } else {
                                        state.criteres     = [];
                                        state.rsmcriteres  = [];
                                        Info.fire({ title: "Impossible de charger la liste des critères pour les rapprochements automatiques" });
                                }
                        })
                        .addCase(getcritereOrdre.fulfilled, (state, action) => {
                                if (action.payload.code_statut==="1") {
                                        state.entete       = action.payload;
                                } else {
                                        state.entete       = [];
                                        Info.fire({ title: "Impossible de charger la liste des entêtes d'ordonnancement" });
                                }
                        })
                        .addCase(getCritereHistorique.fulfilled, (state, action) => {
                                if (action.payload.code_statut==="1") {
                                        // restauration des critères précedement selectionn
                                        state.criteres      = action.payload.data[0].STR_CRITERES;
                                        // reposition du resumé des critères selctionné
                                        let selection       = JSON.parse(JSON.stringify(state.rsmcriteres)); // <- permet une copy fidele de l'ancien array || [...rsmcriteres] <- peu fiable pour ce cas ;
                                        let Listcriteres    = state.Listcriteres;
                                        Listcriteres.map((item,x) => { // restauration du resumé
                                                for (let index = 0; index < state.criteres.length; index++) {
                                                        if (item.str_Lstothervalue===Object.keys(state.criteres[index])[0] && state.criteres[index][Object.keys(state.criteres[index])[0]]!=="0" ) {
                                                                selection[x][item.str_Lstdescription] = item.str_Lstdescription;
                                                                break;
                                                        }
                                                }
                                                return true;
                                        });
                                        state.rsmcriteres=selection;
                                }
                        })
                        .addCase(saveRapproForValidation.fulfilled, (state, action) => {
                                if (action.payload.code_statut==="1") {
                                        Success.fire({ title: action.payload.desc_statut });
                                        console.log(action.payload);
                                        state.rowRapprochement=[];
                                        state.loader.open = false;
                                }else{
                                        Danger.fire({ title: "Echec de l'enregistrement." });
                                        state.loader.open = false;
                                }
                        })
                        .addCase(saveRapproAsDraft.fulfilled, (state, action) => {
                                if (action.payload?.code_statut==="1") {
                                        Success.fire({ title: action.payload.desc_statut });
                                        state.rowRapprochement=[];
                                }else{
                                        Danger.fire({ title: "Echec de la sauvegarde." });
                                }
                                state.loader.open = false;
                        })
                        .addCase(getRapproFromDraft.fulfilled, (state, action) => {
                                state.rowDraftRapprochement  = action.payload?.code_statut==="1" ?  action.payload : BROUILLONDEFAULTSTATE;
                                state.status.brouillonRappro = STATUS.SUCCESS;
                                state.loader.open            = false;
                        })
                        .addCase(deleteRapproDraft.fulfilled, (state, action) => {
                                state.rowDraftRapprochement  = BROUILLONDEFAULTSTATE;
                                Info.fire({ title: "Brouillon supprimé." });
                        })
                        .addCase(searchDataListOperation.fulfilled, (state, action) => {
                                if (action.payload?.data) {
                                        //initialisation de la couleur de tt les flêches
                                        let arrow_up  = document.getElementsByClassName(action.meta.arg.compteType+" feather-arrow-up");
                                        let arrow_down= document.getElementsByClassName(action.meta.arg.compteType+" feather-arrow-down");
                                        for (let index = 0; index < arrow_up.length; index++) {
                                                arrow_up[index].setAttribute("stroke","#CECECE");
                                        }
                                        for (let index = 0; index < arrow_down.length; index++) {
                                                arrow_down[index].setAttribute("stroke","#CECECE");
                                        }
                                        //traitement des données de la recherche
                                        if (action.meta.arg.compteType==="mtx") {
                                                state.operationsCount[0].nostroActive = action.payload.data?.length;
                                                state.operations.dataMtx              = action.payload.data;
                                        }
                                        if (action.meta.arg.compteType==="mvt") {
                                                state.operationsCount[0].miroirActive = action.payload.data?.length;
                                                state.operations.dataMvt              = action.payload.data;
                                        }
                                        state.status.operation    = STATUS.SUCCESS;
                                } else {
                                        state.status.operation = STATUS.ERROR;
                                        if (action.meta.arg.compte===undefined) {
                                                Danger.fire({ title: "Sélectionnez d'abord un compte." });
                                        } else {
                                                Info.fire({ title: "Aucune opération trouvée" });
                                        }
                                }
                        })
                        .addCase(fireAutoRapro.fulfilled, (state, action) => {
                                console.log(action);
                                console.log(action.meta.arg.DT_MVTDATEDOPEDEB);
                                console.log(action.meta.arg.DT_MVTDATEDOPEFIN);
                                if (action.payload?.RappAuto?.length > 0) {
                                        state.modalcriteres    = MODALCRITEREDEFAULTSTATE;
                                        state.rowRapprochement = action.payload.RappAuto;
                                        //TODO : VIDER LES CHAMPS
                                        if (action.meta.arg.DT_MVTDATEDOPEDEB) {
                                        }
                                        state.statustRapp      = (action.payload.RappAuto.length) + " ligne(s) rapprochée(s) automatiquement avec le(s) critère(s) suivant(s)";
                                        state.loader.open      = false;
                                        Success.fire({ title: "Nous Avons réussi à rapprocher automatiquement " + (action.payload.RappAuto.length) + "  ligne(s) opération(s) !" });
                                } else {
                                        state.rowRapprochement = [{ miroirActive: [], nostroActive: [] }];
                                        state.statustRapp    = 0;
                                        // state.status.operation = STATUS.ERROR;
                                        state.loader.open = false;
                                        Danger.fire({ title: "Le rapprochement automatique n'a pas été concluant, Veuillez réessayer avec d'autres critères" });
                                }
                        })
                        .addCase(undoRaprochement.fulfilled, (state, action) => {
                                state.loader.open = false;
                        })
                        .addCase(validationRaprochement.fulfilled, (state, action) => {
                                state.loader.open = false;
                        })
                        .addCase(printReport.fulfilled, (state, action) => {
                                if (action.payload?.code_statut==="1") {
                                        state.fileInfos.name      = action.payload.filename;
                                        state.fileInfos.extension = action.payload.extensionfilename;
                                        if (downloadfile(action.payload)) {
                                                state.loader.open         = false;
                                        }else{
                                                state.fileInfos.name      = "";
                                                state.fileInfos.extension = "";
                                                state.loader.open         = false;
                                                Danger.fire({ title: "Le fichier n'a pas pu être téléchargé. Veuillez réessayer." });
                                        }
                                } else {
                                        state.fileInfos.name      = "";
                                        state.fileInfos.extension = "";
                                        state.loader.open         = false;
                                        Danger.fire({ title: "Impossible de procéder à l'impression." });
                                }
                        })
                        .addCase(searchDataListOperation.rejected, (state, action) => {
                                state.status.operation = STATUS.ERROR;
                                Danger.fire({ title: "Désolé ! La liste des opérations n'a pas pu être chargée" });
                        })
                        .addCase(loadDataListOperation.rejected, (state, action) => {
                                Danger.fire({ title: "Désolé ! La liste des opérations n'a pas pu être chargée" });
                        })
                        .addCase(loadDataListSoccompte.rejected, (state, action) => {
                                Danger.fire({ title: "Désolé ! La liste des comptes n'a pas pu être chargée" });
                        })
                        .addCase(getRapproFromDraft.rejected, (state, action) => {
                                state.rowDraftRapprochement  = BROUILLONDEFAULTSTATE;
                                state.status.brouillonRappro = STATUS.ERROR;
                                state.loader.open            = false;
                                Danger.fire({ title: "Désolé ! nous n'avons pas pu vérifier si vous avez un brouillon de rapprochement." });
                        })
                        .addCase(saveRapproForValidation.rejected, (state, action) => {
                                state.loader.open = false;
                                Danger.fire({ title: "Impossible de procéder à la validation." });
                        })
                        .addCase(printReport.rejected, (state, action) => {
                                state.loader.open = false;
                                Danger.fire({ title: "Impossible de procéder à l'impression." });
                        })
                        .addCase(fireAutoRapro.rejected, (state, action) => {
                                state.loader.open = false;
                                Danger.fire({ title: "Impossible de procéder au rapprochement." });
                        })
                        .addCase(saveRapproAsDraft.rejected, (state, action) => {
                                state.loader.open = false;
                                Danger.fire({ title: "Impossible de procéder à la sauvegarde du brouillon." });
                        })
                        .addCase(listOperationByEntete.rejected, (state, action) => {
                                        state.status.waitingX = STATUS.ERROR;
                                        state.statustRapp     = "";
                                        state.loader.open     = false;
                        })
                        .addCase(searchValideOperation.rejected, (state, action) => {
                                state.status.waitingX = STATUS.ERROR;
                                state.statustRapp = "";
                                state.loader.open = false;
                                Danger.fire({ title: "Echec de la recherche." });
                        })
                        .addCase(showAllOrOneFileLo.rejected, (state, action) => {
                                state.status.waiting = STATUS.ERROR;
                                state.listFileSwift  = {listParPage:PAGINATION.listParPage, currentPage:0, changePageClick:false, listLenght:0, nbrPage:0};
                        })
                        .addCase(undoRaprochement.rejected, (state, action) => {
                                state.loader.open = false;
                                Danger.fire({ title: "Impossible de procéder au traitement." });
                        })
                        .addCase(validationRaprochement.rejected, (state, action) => {
                                state.loader.open = false;
                                Danger.fire({ title: "Impossible de procéder au traitement." });
                        })
                        .addCase(listEnteteRappValid.rejected, (state, action) => {
                                state.status.waiting = STATUS.ERROR;
                        })
                        .addCase(saveCriteresHistorique.rejected, (state, action) => {
                                Danger.fire({ title: "Echec de la sauvegarde des historiques de critères." });
                        })
                        .addCase(makeHtmlRepresentation.fulfilled, (state, action) => {
                                state.html=action.payload;
                        })
                        .addCase(makeClipboardObject.fulfilled, (state, action) => {
                                state.clipboard=action.payload;
                        });
        },
});

/**CHARGEMENT DES ENTREPRISES */
export const loadDataListSoccompte   = createAsyncThunk('ListSoccompte/fetchAll', async (data) => {
        try {
                var bodyFormData = new FormData();
                bodyFormData.append("LG_CORID"     , data.LG_CORID           );
                bodyFormData.append("search[value]", data.search      ?? ""  );
                bodyFormData.append("start"        , data.start       ?? 0   );
                bodyFormData.append("length"       , data.listParPage ?? 1000);
                bodyFormData.append("LG_PROID", getThisInLocalstore("currentProfile") ?? getThisInLocalstore("loginUtilisateur").lg_PROFIL_ID);
                var res = await fetch(`${BASEURL}${APINAMESPACE.RAPPROCHEMENT}/ListSoccompte`, {
                        method : 'POST',
                        body   : bodyFormData
                });
                const response = await res.json();
                return response;
        } catch (error) {
                Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                return error.message;
        }
});

/**CHARGEMENT DES OPERATIONS */
export const loadDataListOperation   = createAsyncThunk('Listedoperation/fetchAll', async (data,{dispatch,getState,rejectWithValue}) => {
        var compte ="";
        if (getCurrentPath() === "Derapprochement" || getCurrentPath() === "Validation") {
                if (getState().rapprochements.listEntete?.attente?.length>0) { 
                        compte = getThisInLocalstore('currentEntete')?.LG_SCCID; // invocation à partir de la liste
                        if (compte===undefined) {
                                return;
                        }
                }else{
                        //Je block les choses ici paq dans ces deux pages 
                        //la liste des opérations doit être appéllée seulment ci il ya des entetes
                        return;
                }
        }else{
                compte = getState().rapprochements.currentCompte.COMPTE.value;
        }
        let { state, startMvt, startMtx, afterDel  } = data;
        if (compte!=="" && compte!==undefined) {
                try {
                        var bodyFormData = new FormData();
                        bodyFormData.append("LG_SCCID", compte       );
                        bodyFormData.append("state"   , state    ?? 0);
                        bodyFormData.append("startMvt", startMvt ?? 0);
                        bodyFormData.append("startMtx", startMtx ?? 0);
                        if (afterDel!==undefined) {
                                bodyFormData.append("afterDel", afterDel);
                        }
                        bodyFormData.append("LG_UTIID", getThisInLocalstore("loginUtilisateur").LG_UTIID);
                        var res = await fetch(`${BASEURL}${APINAMESPACE.RAPPROCHEMENT}/Listedoperation`, {
                                method : 'POST',
                                body   : bodyFormData
                        });
                        const response = await res.json();
                        return response;
                } catch (error) {
                        Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                        return error.message;
                }
        }else{
                Danger.fire({ title: "Sélectionner un compte." });
                return;
        }
});

/**CHARGEMENT DES RAPPROCHEMENT EN ATTENTE POUR UN COMPTE DONNEE */
export const listWaitingRapprochement   = createAsyncThunk('listWaitingRapprochement/fetchAll', async (data,{dispatch,getState}) => {
        var compte       = getState().rapprochements.currentCompte.COMPTE.value;
        if (compte) {
                try {
                        var bodyFormData = new FormData();
                        bodyFormData.append("LG_SCCID", compte);
                        var res = await fetch(`${BASEURL}${APINAMESPACE.RAPPROCHEMENT}/ListRapprochement`, {
                                method : 'POST',
                                body   : bodyFormData
                        });
                        const response = await res.json();
                        setTimeout(() => {
                                dispatch(removeOperation());
                        }, 2000);
                        return response;
                } catch (error) {
                        Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                        return error.message;
                }
        }else{
                Danger.fire({ title: "Sélectionnez d'abord un compte." });
        }
});

/**CHARGEMENT DES OPERATIONS EN ATTENTE A PARTIR D'UNE ENTETE */
export const listOperationByEntete = createAsyncThunk('listOperationByEntete/fetchAll', async (RAPPROID, { dispatch, getState }) => {
        if (RAPPROID) {
                try {
                        var bodyFormData = new FormData();
                        bodyFormData.append("LG_RAPPROID", RAPPROID);
                        var res = await fetch(`${BASEURL}${APINAMESPACE.RAPPROCHEMENT}/ListOperEntete`, {
                                method : 'POST',
                                body   : bodyFormData
                        });
                        const response = await res.json();
                        return response;
                } catch (error) {
                        Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                        return error.message;
                }
        } else {
                Danger.fire({ title: "Sélectionnez d'abord une entête." });
        }
});

/**
 * CHARGEMENT DES ENTETES DE RAPPROCHEMENT EN ATTENTES 
 * ET REJETES POUR UN COMPTE DONNEE
*/
export const listEnteteRapprochement = createAsyncThunk('ListEnteteRapprochement/fetchAll', async (data, { dispatch, getState }) => {
        var compte   = getState().rapprochements.currentCompte.COMPTE.value;
        var start    = getState().rapprochements.currentEntetePage.attente;
        var startRej = getState().rapprochements.currentEntetePage.rejet;
        if (compte) {
                try {
                        var bodyFormData = new FormData();
                        bodyFormData.append("LG_SCCID"  , compte       );
                        bodyFormData.append("startRapp" , start ?? 0   );
                        bodyFormData.append("lengthRapp", 4            );
                        bodyFormData.append("startRej"  , startRej ?? 0);
                        bodyFormData.append("lengthRej" , 4            );
                        bodyFormData.append("LG_PROID", getThisInLocalstore("currentProfile") ?? getThisInLocalstore("loginUtilisateur").lg_PROFIL_ID);
                        var res = await fetch(`${BASEURL}${APINAMESPACE.RAPPROCHEMENT}/ListEnteteRapprochement`, {
                                method : 'POST',
                                body   : bodyFormData
                        });
                        const response = await res.json();
                        dispatch(initEntePagination());
                        return response;
                } catch (error) {
                        Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                        return error.message;
                }
        } else {
                Danger.fire({ title: "Sélectionnez d'abord un compte." });
        }
});

/**CHARGEMENT DE TOUTES LES ENTETES DE RAPPROCHEMENT EN ATTENTES */
export const listEnteteRapprochementAll = createAsyncThunk('listEnteteRapprochementAll/fetchAll', async (data, { dispatch, getState }) => {
        var start = data.start;
        try {
                var bodyFormData = new FormData();
                bodyFormData.append("LG_SCCID","");
                bodyFormData.append("startRapp", start ?? 0);
                bodyFormData.append("lengthRapp", PAGINATION.listParPage);
                bodyFormData.append("startRej" , 0);
                bodyFormData.append("lengthRej", 0);
                bodyFormData.append("LG_PROID", getThisInLocalstore("currentProfile") ?? getThisInLocalstore("loginUtilisateur").lg_PROFIL_ID);
                var res = await fetch(`${BASEURL}${APINAMESPACE.RAPPROCHEMENT}/ListEnteteRapprochement`, {
                        method : 'POST',
                        body   : bodyFormData
                });
                const response = await res.json();
                return response;
        } catch (error) {
                Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                return error.message;
        }
});

/**CHARGEMENT DE TOUTES LES ENTETES DE RAPPROCHEMENT VALIDÉS */
export const listEnteteRappValid = createAsyncThunk('listEnteteRappValid/fetchAll', async (data, { dispatch, getState }) => {
        var start  = data?.start ? data.start : 0;
        var compte = getState().rapprochements.currentCompte?.COMPTE?.value ? getState().rapprochements.currentCompte.COMPTE.value : "";
        try {
                var bodyFormData = new FormData();
                bodyFormData.append("LG_SCCID", compte);
                bodyFormData.append("start", start ?? 0);
                bodyFormData.append("length", PAGINATION.listParPage);
                bodyFormData.append("LG_PROID", getThisInLocalstore("currentProfile") ?? getThisInLocalstore("loginUtilisateur").lg_PROFIL_ID);
                var res = await fetch(`${BASEURL}${APINAMESPACE.RAPPROCHEMENT}/ListValid`, {
                        method : 'POST',
                        body   : bodyFormData
                });
                const response = await res.json();
                return response;
        } catch (error) {
                Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                return error.message;
        }
});

/**RECHERCHE DANS LES OPERATIONS
 * !!Attention j'utilise cette meme fonction pour invoquer 2 endpoints différents [searchMvt , searchMtx]
 * @param {string} LG_SCCID dans data
 * @param {string} compteType mvt ou mtx dans data
 * @param {string} search le critere de recherche dans data
 * @param {string} COLUMN la de tri
 * @param {string} SENS le sens du tri
 * @return array
 */
export const searchDataListOperation = createAsyncThunk('searchOperation/fetchAll', async (data,{dispatch,getState,rejectWithValue}) => {
        let {search,compteType} = data;
        let compte              = getState().rapprochements.currentCompte.COMPTE?.value;
        let currentEntete       = getState().rapprochements.currentEntete;
        let endpointPrefixe     = "/search"+compteType.charAt(0).toUpperCase() + compteType.slice(1); // searchMvt , searchMtx
        // let variableState       = "pagination"+compteType.charAt(0).toUpperCase() + compteType.slice(1); // "paginationMtx" ou "paginationMvt
        // let start               = getState().rapprochements[variableState]?.currentPage ?? 0;

        let bodyFormData        = new FormData();
        if (compte!==undefined) {
                try {
                        bodyFormData.append("LG_SCCID"    , compte              );
                        bodyFormData.append("search_value", search        ?? "" );
                        bodyFormData.append("compteType"  , compteType          );
                        bodyFormData.append("COLUMN"      , currentEntete.COLUMN);
                        bodyFormData.append("SENS"        , currentEntete.SENS  );
                        bodyFormData.append("start", 0);
                        let res = await fetch(`${BASEURL}${APINAMESPACE.RAPPROCHEMENT}${endpointPrefixe}`, {
                                method : 'POST',
                                body   : bodyFormData
                        });
                        const response = await res.json();
                        return response;
                } catch (error) {
                        Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                        return error.message;
                }
        } else {
                return false;
        }
});

/**CHARGEMENT DES LIBELLES DE CRITERES */
export const getcritereRapprochement = createAsyncThunk('getcritereRapprochement/fetchAll', async (data) => {
        try {
                var bodyFormData = new FormData();
                bodyFormData.append("LG_TYLID", "12729202952608735659");
                var res = await fetch(`${BASEURL}${APINAMESPACE.RAPPROCHEMENT}/critereRapprochement`, {
                        method : 'POST',
                        body   : bodyFormData
                });
                const response = await res.json();
                return response;
        } catch (error) {
                Danger.fire({ title: "Impossible de charger la liste des critères pour les rapprochements automatiques" });
                return error.message;
        }
});

/**CHARGEMENT DES LIBELLES DE CRITERES D'ORDRE */
export const getcritereOrdre = createAsyncThunk('getcritereOrdre/fetchAll', async (data) => {
        try {
                var bodyFormData = new FormData();
                bodyFormData.append("LG_TYLID", "1272919443078269472E");
                var res = await fetch(`${BASEURL}${APINAMESPACE.RAPPROCHEMENT}/getColumns`, {
                        method : 'POST',
                        body   : bodyFormData
                });
                const response = await res.json();
                return response;
        } catch (error) {
                Danger.fire({ title: "Impossible de charger la liste des entêtes d'ordonnancement" });
                return error.message;
        }
});

/**ENREGISTREMENT DES CRITERES EN HISTORIQUE */
export const saveCriteresHistorique = createAsyncThunk('saveCriteresHistorique/fetchAll', async (data,{dispatch,getState}) => {
        var correspondant= getState().corsocietes.currentOptCorrList.CORRESPONDANT.value;
        var compte       = getState().rapprochements.currentCompte.COMPTE.value;
        var criteres     = getState().rapprochements.criteres;
        var jsondetCr    = JSON.stringify({ detCr: criteres });
        try {
                var bodyFormData = new FormData();
                bodyFormData.append("CORRESPONDANT", correspondant);
                bodyFormData.append("STR_COMPTE", compte);
                bodyFormData.append("STR_CRITERES", jsondetCr);
                bodyFormData.append("STR_UTITOKEN", getThisInLocalstore("loginUtilisateur").token);
                var res = await fetch(`${BASEURL}${APINAMESPACE.RAPPROCHEMENT}/SaveCriteresHistorique`, {
                        method : 'POST',
                        body   : bodyFormData
                });
                const response = await res.json();
                return response;
        } catch (error) {
                Danger.fire({ title: "Impossible de sauvegarder la liste des critères pour le moment." });
                return error.message;
        }
});

/**CHARGEMENT DE L'HISTORIQUE DES CRITERES */
export const getCritereHistorique = createAsyncThunk('getHistorique/fetchAll', async (data,{dispatch,getState}) => {
        var compte       = getState().rapprochements.currentCompte.COMPTE.value;
        if (compte) {
                try {
                                var bodyFormData = new FormData();
                                bodyFormData.append("STR_COMPTE", compte);
                                bodyFormData.append("STR_UTIID", getThisInLocalstore("loginUtilisateur").token);
                                var res = await fetch(`${BASEURL}${APINAMESPACE.RAPPROCHEMENT}/getCritereHistorique`, {
                                        method : 'POST',
                                        body   : bodyFormData
                                });
                                const response = await res.json();
                                return response;
                } catch (error) {
                        Danger.fire({ title: "Impossible d'obtenir l'historique de vos critères pour ce compte" });
                        return error.message;
                }
        }
});

/**LANCER UN RAPPROCHEMENT AUTOMATIQUE */
export const fireAutoRapro = createAsyncThunk('fireAutoRapro/fetchAll', async (data,{dispatch,getState}) => {
        try {
                var correspondant= getState().corsocietes.currentOptCorrList.CORRESPONDANT.value;
                var compte       = getState().rapprochements.currentCompte.COMPTE.value;
                var criteres     = getState().rapprochements.criteres;
                var datesX       = getState().rapprochements.datescritere;
                var start        = getState().rapprochements.paginationMvt.currentPage;
                var LISTCRITERE  = JSON.stringify(criteres);
                var bodyFormData = new FormData();
                // criteres.map((item, x)=>{bodyFormData.append([Object.keys(item)], item[Object.keys(item)]);});
                bodyFormData.append("LISTCRITERE", LISTCRITERE);
                bodyFormData.append("DT_MVTDATEDOPEDEB",datesX.DT_MVTDATEDOPEDEB);
                bodyFormData.append("DT_MVTDATEDOPEFIN",datesX.DT_MVTDATEDOPEFIN);
                bodyFormData.append("DT_MVTDATEVALDEB", datesX.DT_MVTDATEVALDEB);
                bodyFormData.append("DT_MVTDATEVALFIN", datesX.DT_MVTDATEVALFIN);
                bodyFormData.append("CORRESPONDANT", correspondant);
                bodyFormData.append("LG_SCCID", compte);
                bodyFormData.append("start", start);
                bodyFormData.append("STR_UTITOKEN", getThisInLocalstore("loginUtilisateur").token);
                var res = await fetch(`${BASEURL}${APINAMESPACE.RAPPROCHEMENT}/RappAuto`, {
                        method : 'POST',
                        body   : bodyFormData
                });
                const response = await res.json();
                setTimeout(() => {
                        dispatch(removeOperation());
                }, 2000);
                return response;
        } catch (error) {
                Danger.fire({ title: "Impossible de charger la liste des critères pour les rapprochements automatiques" });
                return error.message;
        }
});

/** SAUVEGARDE D'UN RAPPROCHEMENT POUR VALIDATION
 * @function 
 */
export const saveRapproForValidation = createAsyncThunk ('saveRapproForValidation/fetchAll', async (data,{dispatch, getState}) => {
        let rowRapprochement = {rowRapprochement:getState().rapprochements.rowRapprochement};
        let compte           = getState().rapprochements.currentCompte.COMPTE.value;
        try {
                var bodyFormData = new FormData();
                bodyFormData.append("STR_DETAILRAP", JSON.stringify(rowRapprochement));
                bodyFormData.append("LG_SCCID", compte);
                bodyFormData.append("LG_UTICREATED", getThisInLocalstore("loginUtilisateur").token);
                var res = await fetch(`${BASEURL}${APINAMESPACE.RAPPROCHEMENT}/Rapprochement`, {
                        method: 'POST',
                        body: bodyFormData
                });
                const response = await res.json();
                if (response.code_statut === "1") {
                        dispatch(loadDataListOperation({ state: "0", startMvt: 0, startMtx: 0 }));
                        dispatch(listEnteteRapprochement([]));
                        dispatch(setListEntete({ rejet: [], totalRejet: 0, attente: [], totalAttente: 0 }));
                        dispatch(getRapproFromDraft([]));
                }
                return response;
        } catch (error) {
                Danger.fire({ title: "Impossible d'enregistrer ce rapprochement." });
                return error.message;
        }
});

/** SAUVEGARDE D'UN RAPPROCHEMENT EN BROUILLON
 * @function 
 */
export const saveRapproAsDraft = createAsyncThunk ('saveRapproAsDraft/fetchAll', async (data,{dispatch, getState}) => {
        let rowRapprochement = {rowRapprochement:getState().rapprochements.rowRapprochement};
        let compte           = getState().rapprochements.currentCompte.COMPTE.value;
        if (checkForEmptyRowRppr(rowRapprochement.rowRapprochement)){
                try {
                        var bodyFormData = new FormData();
                        bodyFormData.append("STR_BROUILLONS",JSON.stringify(rowRapprochement));
                        bodyFormData.append("LG_SCCID", compte);
                        bodyFormData.append("LG_UTICREATED", getThisInLocalstore("loginUtilisateur").token);
                        var res = await fetch(`${BASEURL}${APINAMESPACE.RAPPROCHEMENT}/brouillons`, {
                                method : 'POST',
                                body   : bodyFormData
                        });
                        const response = await res.json();
                        if (response.code_statut==="1") {
                                dispatch(loadDataListOperation({ state: "0", startMvt: 0, startMtx: 0 }));
                                dispatch(listEnteteRapprochement([]));
                                dispatch(setListEntete({ rejet: [], totalRejet: 0, attente: [], totalAttente: 0 }));
                                dispatch(getRapproFromDraft([])); 
                        }
                        return response;
                }catch (error) {
                        Danger.fire({ title: "Impossible d'enregistrer ce rapprochement en brouillon." });
                        return error.message;
                }
        }else{
                dispatch(setLoader({ open: false, text: "Enregistrment en cours..." }));
        }
});

/** RECUPERATION D'UN RAPPROCHEMENT EN BROUILLON
 * @function 
 */
export const getRapproFromDraft = createAsyncThunk('getRapproFromDraft/fetchAll', async (data, { dispatch, getState }) => {
        let compte  = getState().rapprochements.currentCompte.COMPTE.value;
        if (compte) {
                try {
                        var bodyFormData = new FormData();
                        bodyFormData.append("LG_SCCID", compte);
                        bodyFormData.append("LG_UTIID", getThisInLocalstore("loginUtilisateur").LG_UTIID);
                        var res = await fetch(`${BASEURL}${APINAMESPACE.RAPPROCHEMENT}/getBrouillon`, {
                                method : 'POST',
                                body   : bodyFormData
                        });
                        const response = await res.json();
                        return response;
                } catch (error) {
                        Danger.fire({ title: "Impossible de charger ce rapprochement en brouillon." });
                        return error.message;
                }
        }
});

/** IMPRESSION DES ETATS DE RAPPROCHEMENT
 * @param {string} fileType xls ou pdf
 * @function 
 */
export const printReport = createAsyncThunk('printReport/fetchAll', async (fileType, { dispatch, getState }) => {
        let compte   = getState().rapprochements.currentCompte.COMPTE.value;
        let MIROIR   = getState().rapprochements.currentCompte.COMPTE.MIROIR;
        let NOSTRO   = getState().rapprochements.currentCompte.COMPTE.NOSTRO;
        let STR_FILE = MIROIR === NOSTRO ? "rp_Report_Final_ligth" : "rp_Report_Final"
        if (compte) {
                var bodyFormData = new FormData();
                var res          = {};
                try {
                        bodyFormData.append("STR_TYPEREPORT", "");
                        bodyFormData.append("STR_VALUE", compte + "");
                        bodyFormData.append("STR_FILE", JSON.stringify([{ "STR_FILE": STR_FILE }]));
                        bodyFormData.append("STR_EXTENSION", fileType);
                        bodyFormData.append("STR_UTITOKEN", getThisInLocalstore("loginUtilisateur").token);
                        res = await fetch(`${BASEURL}${APINAMESPACE.FILE}/printreport`, {
                                method : 'POST',
                                body   : bodyFormData
                        });
                        const response = await res.json();
                        return response;
                } catch (error) {
                        Danger.fire({ title: "Impossible d'initier le téléchargement. Assurez vous d'avoir selectionner un compte" });
                        return error.message;
                }
        }else{
                Danger.fire({ title: "Sélectionne d'abord un compte." });
                return;
        }
});

/** TELECHARGEMENT DU FICHIER
 * @param {string} fileInfos nom du fichier et l'extension 
 * @function 
 */
export const downloadfile = async (fileInfos) => {
        try {
                window.location = `${BASEURL}${APINAMESPACE.FILE}/downloadfile?STR_EXTENSION=${fileInfos.extensionfilename}&STR_FILE=${fileInfos.filename}&STR_TYPE_IMPORT=export`;
                return true;
        } catch (error) {
                Danger.fire({ title: "Impossible de télécharger le fichier. Veuillez réessayer !" });
                return error.message;
        }
};

/** DÉVEROUILLE LES COMPTES */
export const disconnectAllSoccompte = async (data) => {
        var bodyFormData = new FormData();
        bodyFormData.append("STR_UTITOKEN", getThisInLocalstore("loginUtilisateur")?.token);
        await fetch(`${BASEURL}${APINAMESPACE.CONF}/disconnectAllSoccompte`, {
                method : 'POST',
                body   : bodyFormData
        });
        return;
};

/** SUPPRESSION D'UN RAPPROCHEMENT 
 * @function 
 */
export const deleteRapproDraft = createAsyncThunk('deleteRapproDraft/fetchAll', async (data, { dispatch, getState }) => {
        let compte = getState().rapprochements.currentCompte.COMPTE.value;
        if (compte) {
                try {
                        var bodyFormData = new FormData();
                        bodyFormData.append("LG_SCCID", compte);
                        bodyFormData.append("LG_UTIID", getThisInLocalstore("loginUtilisateur").LG_UTIID);
                        var res = await fetch(`${BASEURL}${APINAMESPACE.RAPPROCHEMENT}/deleteBrouillon`, {
                                method : 'POST',
                                body   : bodyFormData
                        });
                        const response = await res.json();
                        if (response.code_statut==="1") {
                                dispatch(loadDataListOperation({ state: "0", startMvt: 0, startMtx: 0 }));
                                dispatch(listEnteteRapprochement([]));
                                dispatch(setListEntete({ rejet: [], totalRejet: 0, attente: [], totalAttente: 0 }));
                                dispatch(getRapproFromDraft([])); 
                                dispatch(setRowRapprochement([]));
                        }
                        return response;
                } catch (error) {
                        Danger.fire({ title: "Impossible de charger ce rapprochement en brouillon." });
                        return error.message;
                }
        }
});

/** AFFICHAGE D'UN RAPPROCHEMENT EN BROUILLON
 * @function 
 */
export const renderRapproDraft = createAsyncThunk('renderRapproDraft/fetchAll', async (data, { dispatch, getState }) => {
        let rowDraftRapprochement = getState().rapprochements.rowDraftRapprochement;
        if (rowDraftRapprochement.BROUILLONS.length>0) {
                dispatch(setStatustRapp(""));
                dispatch(setRowRapprochement([]));
                setTimeout(() => {
                        dispatch(setRowRapprochement(rowDraftRapprochement.BROUILLONS));
                        if (rowDraftRapprochement.totalOperationIndisponible>0) {
                                Info.fire({ title: "Brouillon restauré avec "+rowDraftRapprochement.totalOperationIndisponible+" opération(s) indisponible(s)." });
                        }
                }, 300);
                setTimeout(() => {
                        dispatch(removeOperation());
                }, 1000);
        }else{
                Danger.fire({ title: "Ce Brouillon est vide." });
        }
});

/**Ajout d'une ligne de rapprochement */
export const NewRapprochementRow = createAsyncThunk('NewRapprochementRow/fetchAll', async (data, { dispatch, getState }) => {
        let newList = JSON.parse(JSON.stringify(getState().rapprochements.rowRapprochement));
        newList.unshift({ miroirActive: [], nostroActive: [] }); // <-Rajoute le nvell elmt au debut du tableau
        dispatch(setRowRapprochement(newList));
        setTimeout(() => {
                doAnimate(0, "miroirActive"," scale-up-center");
                doAnimate(0, "nostroActive"," scale-up-center");
        }, 0);

        setTimeout(() => {
                unDoAnimate(0, "miroirActive"," scale-up-center");
                unDoAnimate(0, "nostroActive"," scale-up-center");
        }, 500);
});

/** Quand on supprime la ligne de rapprochement la zone en BAS */
export const closeRowButtonTrigger = createAsyncThunk('closeRowButtonTrigger/fetchAll', async (datax, { dispatch, getState }) => {
        let {e, row} = datax;
        let rowRapprochement = getState().rapprochements?.rowRapprochement;
        if (e) {
                dispatch(restoreOperation({row}));
                doAnimate(row, "miroirActive", " scale-in-hor-center");
                doAnimate(row, "nostroActive", " scale-in-hor-center");
                setTimeout(() => { // le temps que l'animation s'execute
                        let list = [...rowRapprochement];
                        dispatch(setRowRapprochement([...list.slice(0, row), ...list.slice(row + 1)])); // suppression et maj du state des lignes de rapprochement
                        unDoAnimate(row, "miroirActive", " scale-in-hor-center");
                        unDoAnimate(row, "nostroActive", " scale-in-hor-center");
                }, 300);
        }
});

/** Quand on supprime une ligne de rapprochement : la zone en BAS Si il y avait des rapprochements dans cette ligne on doit les restaurés dans les opérations en haut */
export const restoreOperation = createAsyncThunk('restoreOperation/fetchAll', async (data, { dispatch, getState }) => {
        let {row}            = data; // row represente la ligne supprimé
        let rowRapprochement = getState().rapprochements.rowRapprochement; // <- la ligne a supprimé
        let operations       = JSON.parse(JSON.stringify(getState().rapprochements.operations)); // <- les opérations en attentes
        if (rowRapprochement[row].nostroActive.length>0) {
                for (let index = 0; index < rowRapprochement[row].nostroActive.length; index++) {
                        let temoin  = false;
                        for (let zz = 0; zz <  operations.dataMtx.length; zz++) {
                                if (operations.dataMtx[zz].LG_MTXID===rowRapprochement[row].nostroActive[index].LG_MTXID) { // je recherche l'opération concernée
                                        operations.dataMtx[zz].state=""; // réaffichage des données
                                        temoin=true;
                                        break;
                                }
                        }
                        // si l'opération à faire réapparaitre n'a pas été retrouvée dans la liste [sans doute que le l'user a déclenché une recherche dans la zone des operations]
                        if (!temoin) { 
                                // dans ce cas je dépose la nouvelle opération a la fin
                                operations.dataMtx.push(rowRapprochement[row].nostroActive[index]);
                        } 
                }
        }
        if (rowRapprochement[row].miroirActive.length>0) {
                for (let index = 0; index < rowRapprochement[row].miroirActive.length; index++) {
                        let temoin  = false;
                        for (let zz = 0; zz <  operations.dataMvt.length; zz++) {
                                if (operations.dataMvt[zz].LG_MVTID===rowRapprochement[row].miroirActive[index].LG_MVTID) { // je recherche l'opération concernée
                                        operations.dataMvt[zz].state=""; // réaffichage des données
                                        temoin=true;
                                        break;
                                }
                        }
                        // si l'opération à faire réapparaitre n'a pas été retrouvée dans la liste [sans doute que le l'user a déclenché une recherche dans la zone des operations]
                        if (!temoin) { 
                                // dans ce cas je dépose la nouvelle opération a la fin
                                operations.dataMvt.push(rowRapprochement[row].miroirActive[index]);
                        } 
                }
        }
        if (rowRapprochement[row].miroirActive.length>0 || rowRapprochement[row].nostroActive.length>0) {
                dispatch(setOperations(operations));
                Info.fire({ title: "Les opérations on été restaurées." });
                setTimeout(() => {
                        dispatch(countOperationRow({typeOpe:"miroirActive"}));
                        dispatch(countOperationRow({typeOpe:"nostroActive"}));
                }, 2000);
        }
});

/** Quand on Ajoute une/des lignes de rapprochement à partir d'une fonction soit en rapprochement automatique soit par restitition d'operation rejeter soit par appel de brouillon : Je doit retirer les operations qui viennent d'être rapprochées de la liste des operations en attentes */
export const removeOperation = createAsyncThunk('removeOperation/fetchAll', async (data, { dispatch, getState }) => {
        let rowRapprochement = getState().rapprochements.rowRapprochement; // <- les nouvelles operations de rapprochement arrivées
        let operations       = JSON.parse(JSON.stringify(getState().rapprochements.operations)); // <- les opérations en attentes
        for (let index = 0; index < rowRapprochement.length; index++) {
                if (rowRapprochement[index].nostroActive.length > 0) {
                        for (let i = 0; i < rowRapprochement[index].nostroActive.length; i++) {
                                for (let zz = 0; zz < operations.dataMtx.length; zz++) {
                                        if (operations.dataMtx[zz].LG_MTXID === rowRapprochement[index].nostroActive[i].LG_MTXID) { // je recherche l'opération concernée
                                                operations.dataMtx[zz].state = "hidden"; // masquage des opérations
                                                break;
                                        }
                                }
                        }
                }
                if (rowRapprochement[index].miroirActive.length > 0) {
                        for (let i = 0; i < rowRapprochement[index].miroirActive.length; i++) {
                                for (let zz = 0; zz < operations.dataMvt.length; zz++) {
                                        if (operations.dataMvt[zz].LG_MVTID === rowRapprochement[index].miroirActive[i].LG_MVTID) { // je recherche l'opération concernée
                                                operations.dataMvt[zz].state = "hidden"; // masquage des opérations
                                                break;
                                        }
                                }
                        }
                }
        }
        dispatch(setOperations(operations));
        setTimeout(() => {
                dispatch(countOperationRow({ typeOpe: "miroirActive" }));
                dispatch(countOperationRow({ typeOpe: "nostroActive" }));
        }, 300);
});

/** Compteur dynamique de ligne d'operation  */
export const countOperationRow = createAsyncThunk('countOperationRow/fetchAll', async (data, { dispatch, getState }) => {
        let { typeOpe } = data;
        let elemt       = document.getElementsByClassName("opeRow_" + typeOpe); // je compte le nombre d'operation visible sur le DOM dc les operations hidden ne sont pas concernées vu J'ai demandé a react de ne pas les rendre visible
        let anc         = JSON.parse(JSON.stringify(getState().rapprochements.operationsCount));
        anc[0][typeOpe] = elemt.length;
        dispatch(setOperationsCount(anc));
});

/**VALIDATION FINALE DU RAPPROCHEMENT */
export const validationRaprochement = createAsyncThunk('validationRaprochement', async (data, { dispatch, getState }) => {
        let LG_RAPPROID = getState().rapprochements.enteteEncours;
        if (LG_RAPPROID!==undefined) {
                try {
                        var bodyFormData = new FormData();
                        bodyFormData.append("LG_RAPPROID", LG_RAPPROID);
                        bodyFormData.append('LG_RAPID', data.LG_RAPID);
                        bodyFormData.append("STR_RAPPROCOMMENT", data.STR_RAPPROCOMMENT ?? "Non renseigné");
                        bodyFormData.append("state", data.state);
                        bodyFormData.append("STR_UTITOKEN", getThisInLocalstore("loginUtilisateur").token);
                        var res = await fetch(`${BASEURL}${APINAMESPACE.RAPPROCHEMENT}/confirmeOrRefuseRapro`, {
                                method : 'POST',
                                body   : bodyFormData
                        });
                        const response = await res.json();
                        if (response.code_statut === "1") {
                                dispatch(setModalComment(MODALCOMMENT)); //<- fermeture de la modale de commentaire
                                dispatch(setModalValidation(MODALVALIDATION)); //<- fermeture de la modale de validation
                                dispatch(listEnteteRapprochementAll([]));//<-Rechargement de la liste des entetes de rapprochement en attente
                                Success.fire({ title: response.desc_statut });
                        } else {
                                dispatch(setModalComment(MODALCOMMENT)); //<- fermeture de la modale de commentaire  
                                dispatch(setModalValidation(MODALVALIDATION)); //<- fermeture de la modale de validation
                                Danger.fire({ title: "La validation a échoué" });
                        }
                        return response;
                } catch (error) {
                        Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                        return error.message;
                }
        }else{
                Danger.fire({ title: "Veuillez selectionner une entete de rapprochement" });
        }
});

/**DERAPPROCHEMENT DU RAPPROCHEMENT */
export const undoRaprochement = createAsyncThunk('undoRaprochement', async (data, { dispatch, getState }) => {
        let LG_RAPPROID         = getState().rapprochements.enteteEncours;
        let rowResRapprochement = getState().rapprochements.rowResRapprochement;
        let tab                 = [];
        rowResRapprochement.map((data, row) => {
                return (tab.push(data.LG_RAPID)); //recuperation de toutes les opérations validées
        });
        if (LG_RAPPROID !== undefined ) {
                try {
                        var bodyFormData = new FormData();
                        bodyFormData.append("LG_RAPPROID", LG_RAPPROID);
                        bodyFormData.append('LG_RAPID', JSON.stringify(tab));
                        bodyFormData.append("STR_UTITOKEN", getThisInLocalstore("loginUtilisateur").token);
                        var res = await fetch(`${BASEURL}${APINAMESPACE.RAPPROCHEMENT}/deRappro`, {
                                method : 'POST',
                                body   : bodyFormData
                        });
                        const response = await res.json();
                        if (response.code_statut === "1") {
                                dispatch(setModalValidation(MODALVALIDATION)); //<- fermeture de la modale de Dérapprochement
                                dispatch(listEnteteRappValid([]));//<-Rechargement de la liste des entetes de rapprochement validé
                                Success.fire({ title: response.desc_statut });
                        } else {
                                dispatch(setModalValidation(MODALVALIDATION)); //<- fermeture de la modale de validation
                                Danger.fire({ title: "Le dérapprochement a échoué" });
                        }
                        return response;
                } catch (error) {
                        Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                        return error.message;
                }
        } else {
                Danger.fire({ title: "Veuillez selectionner une entete de rapprochement" });
        }
});

/**SUPPRESION D'OPERATION REJETEES */
export const deleteAllRejectRapprochement = createAsyncThunk('deleteAllRejectRapprochement', async (LG_RAPPROID, { dispatch, getState }) => {
        // let LG_RAPPROID         = getState().rapprochements.enteteEncours;
        if (LG_RAPPROID !== undefined && LG_RAPPROID !== ""  ) {
                try {
                        var bodyFormData = new FormData();
                        bodyFormData.append("LG_RAPPROID", LG_RAPPROID);
                        bodyFormData.append("LG_UTIID", getThisInLocalstore("loginUtilisateur").token);
                        var res = await fetch(`${BASEURL}${APINAMESPACE.RAPPROCHEMENT}/deleteRapproRejet`, {
                                method : 'POST',
                                body   : bodyFormData
                        });
                        const response = await res.json();
                        if (response.code_statut === "1") {
                                dispatch(listEnteteRapprochement([])); // tentative de Chargement des rapprochements en attente de validation //{LG_SCCID:item.value}
                                dispatch(loadDataListOperation({ state: "0", startMvt: 0, startMtx: 0 })); // tentative de Chargement des operations //{LG_SCCID:item.value}
                                Success.fire({ title: response.desc_statut });
                        } else {
                                Danger.fire({ title: "La suppression a échoué" });
                        }
                        return response;
                } catch (error) {
                        Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                        return error.message;
                }
        } else {
                Danger.fire({ title: "Veuillez selectionner une entete de rapprochement" });
        }
});

/**SUPPRESION D'UNE LIGNE d'OPERATION REJETEES */
export const deleteOneRowRejetRapprochement = createAsyncThunk('deleteOneRowRejetRapprochement', async (datax, { dispatch, getState }) => {        
        let {row,data}    = datax;
        let rowRapprochement = getState().rapprochements?.rowRapprochement;
        if (data?.miroirActive[0].LG_RAPID !== undefined && data?.miroirActive[0].LG_RAPID===data?.nostroActive[0].LG_RAPID  ) { // je verifie que se sont des opérations rejetées
                try {
                        var bodyFormData = new FormData();
                        bodyFormData.append('LG_RAPID', data?.miroirActive[0].LG_RAPID);
                        bodyFormData.append("LG_UTIID", getThisInLocalstore("loginUtilisateur").token);
                        var res = await fetch(`${BASEURL}${APINAMESPACE.RAPPROCHEMENT}/deleteRapproLigneRejet`, {
                                method : 'POST',
                                body   : bodyFormData
                        });
                        const response = await res.json();
                        if (response.code_statut === "1") {
                                doAnimate(row, "miroirActive", " scale-in-hor-center");
                                doAnimate(row, "nostroActive", " scale-in-hor-center");
                                setTimeout(() => { // le temps que l'animation s'execute
                                        let list = [...rowRapprochement];
                                        dispatch(setRowRapprochement([...list.slice(0, row), ...list.slice(row + 1)])); // suppression et maj du state des lignes de rapprochement
                                        unDoAnimate(row, "miroirActive", " scale-in-hor-center");
                                        unDoAnimate(row, "nostroActive", " scale-in-hor-center");
                                }, 300);
                                dispatch(loadDataListOperation({ state: "0", startMvt: 0, startMtx: 0, afterDel:true })); // tentative de Chargement des operations //{LG_SCCID:item.value}
                                Success.fire({ title: response.desc_statut });
                        } else {
                                Danger.fire({ title: "La suppression de la ligne a échoué" });
                        }
                        return response;
                } catch (error) {
                        Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                        return error.message;
                }
        }else{ // si se sont juste de fraiches opérations
                dispatch(closeRowButtonTrigger(datax)); //je les supprime et les restitue dans la listes des opération
        }
});

/**CHARGEMENT DE LA LISTE DES FICHIERS SWIFT */
export const showAllOrOneFileLo = createAsyncThunk('showAllOrOneFileLo', async (data, { dispatch, getState }) => {
        var LG_SCCID        = getState().rapprochements.currentCompte?.COMPTE?.value;
        const date          = new Date();
        const options       = { day: '2-digit', month: '2-digit', year: 'numeric' };
        const aujourdhui    = date.toLocaleDateString('fr-FR', options);
        console.log(data);
        const formatDate =(suffixe="")=>{
                if (suffixe!=="" && data[suffixe]!==undefined && data[suffixe]!=="") {
                        let xxxx = data[suffixe].split("-");
                        return xxxx[2]+"/"+xxxx[1]+"/"+xxxx[0]
                }
                if (suffixe==="DtFin") {
                        return aujourdhui;
                }
                return "01/01/2017"
        };
        if (LG_SCCID !== undefined) {
                try {
                        var bodyFormData = new FormData();
                        bodyFormData.append("LG_SCCID", LG_SCCID);
                        bodyFormData.append("DtDeb", formatDate("DtDeb"));
                        bodyFormData.append("DtFin", formatDate("DtFin"));
                        bodyFormData.append("start", data.start ?? 0);
                        bodyFormData.append("length", data.length ?? 10);
                        var res = await fetch(`${BASEURL}${APINAMESPACE.RAPPROCHEMENT}/showAllOrOneFileLo`, {
                                method : 'POST',
                                body   : bodyFormData
                        });
                        const response = await res.json();
                        if (response.data.length >0) {
                                Success.fire({ title: response.data.length +" fichiers traités" });
                        } else {
                                Danger.fire({ title: "Le chargement a échoué" });
                        }
                        return response;
                } catch (error) {
                        Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                        return error.message;
                }
        } else {
                Danger.fire({ title: "Veuillez selectionner un compte" });
        }
});

/**
* ajout de la classe de suppression dans le composant <Rapprochement /> 
*/
export const doAnimate = (row, typeOpe, animateClass) => {
        let elmt = document.getElementById("ROW_" + typeOpe + "_" + row);
        elmt.className = elmt.className + animateClass;
};

/**
* Retrait de la classe de suppression dans le composants <Rapprochement /> 
*/
export const unDoAnimate = (row, typeOpe,animateClass) => {
        let elmt = document.getElementById("ROW_" + typeOpe + "_" + row);
        elmt.className = String(elmt.className).split(animateClass)[0];
};

/**Vérifie q'uil n'y a pas de vide dans les lignes de rapprochement*/
export const checkForEmptyRowRppr =  (rowRapprochement) => {
        if (rowRapprochement?.length > 0) {
                for (let index = 0; index < rowRapprochement.length; index++) {
                        if (rowRapprochement[index].nostroActive.length === 0 || rowRapprochement[index].miroirActive.length === 0) {
                                Danger.fire({ title: "la ligne " + (index + 1) + "  ne doit pas rester sans opérations." });
                                return false;
                        }
                }
        } else {
                if (rowRapprochement.length === 0) {
                        Danger.fire({ title: "Rapprocher au moins une ligne." });
                        return false;
                } else {
                        Danger.fire({ title: "Aucune ligne de rapprochement ne doit rester sans opérations." });
                        return false;
                }
        }
        return true;
};

/** 
 * verifie que le  montant est bien formaté Pck svt les montants viennent avec rien apres la virgule
 * Si le nombre est mal formatté je retourn sa partie entiere ex: si le nombre est 1445, je retourn 1445
 * si par contre le nombre est bien formater je le retourne entierement en remplaçant la virgule par un point
 */
export const checkForFormat = (montant) => {
        if (montant) {
                let splitMontant = montant?.toString().split(',');
                if (String(splitMontant[splitMontant.length - 1]) === "") {
                        return String(splitMontant[0]);
                }
                return parseFloat(montant?.toString().replace(',', '.'));
        }
        return 0;
};

/** 
 *Format le montant dans une devise ou rend un format par defaut dans le cas ou la devise = ""
*/
export const formatBydevise = (montant, devise) => {
        if (devise === "Euro" || devise === "EUR") {
                return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(montant);
        }
        if (devise === "Dollars" || devise === "USD") {
                return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(montant);
        }
        if (devise === "XOF") {
                return new Intl.NumberFormat('ci', { style: 'currency', currency: 'XOF', currencySign: 'accounting' }).format(montant);
        }
        if (devise === "CHF") {
                return new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF', currencySign: 'accounting' }).format(montant);
        }
        return new Intl.NumberFormat('ci').format(montant);
};

/**CUMULATEUR DE SOMME */
export function sommeCumulee(typeOpe, TABLEAU, source) {
        if (typeOpe==="miroirActive") {
                return ExtrateurDeColonne(TABLEAU, "STR_MVTCREDIT", typeOpe, source).reduce(somme, 0);
        }
        if (typeOpe==="nostroActive") {
                return ExtrateurDeColonne(TABLEAU, "STR_MTXCREDIT", typeOpe, source).reduce(somme, 0);
        }
        function somme(total, num) {
                return total + parseFloat(num);
        }
}

/** retourn le solde du compte courant en nostro ou en miroir  
 * @param {any} columnOpe le nom de la colonne ou l'index à extraire
 * @param {object} currentCompte <- le state qui contient les informations du compte courrant voir [loadDataListSoccompte.fulfilled] pour le format de l'objet
 * @param {array} listCompte la liste des compte dans laquel chercher les informations
*/
export function getSoldeCurrentCompte(columnOpe, currentCompte,listCompte) {
        if (getCurrentPath() === "Rapprochement") {
                if (currentCompte?.COMPTE!==undefined) {
                        for (let index = 0; index < listCompte?.length; index++) {
                                if (listCompte[index].value===currentCompte?.COMPTE?.value) { // dans value ya l'id du compte LG_SCCID
                                        if (columnOpe === "STR_SCCSOLDEMIR") {
                                                return currentCompte.COMPTE?.STR_SCCSENSMIR === "C" ? listCompte[index][columnOpe] : (-1 * listCompte[index][columnOpe]);
                                        }
                                        if (columnOpe === "STR_SCCSOLDENOS") {
                                                return currentCompte.COMPTE?.STR_SCCSENSNOS === "C" ? listCompte[index][columnOpe] : (-1 * listCompte[index][columnOpe]);
                                        }
                                }
                        }
                }
                return 0;
        }
        if (getCurrentPath() === "Derapprochement" || getCurrentPath() === "Validation") { // ici les informations du compte son plutot stocker en localstorage
                if (columnOpe === "STR_SCCSOLDEMIR") {
                        return getThisInLocalstore('currentEntete')?.STR_SCCSENSMIR === "C" ? (getThisInLocalstore('currentEntete')?.STR_SCCSOLDEMIR) : (-1 * getThisInLocalstore('currentEntete')?.STR_SCCSOLDEMIR);
                }
                if (columnOpe === "STR_SCCSOLDENOS") {
                        return getThisInLocalstore('currentEntete')?.STR_SCCSENSNOS === "C" ? (getThisInLocalstore('currentEntete')?.STR_SCCSOLDNOSTRO) : (-1 * getThisInLocalstore('currentEntete')?.STR_SCCSOLDNOSTRO);
                }
                // dans les modals de resumé de rapprochement
                let SOLDEMIROIRE     = listCompte[columnOpe].STR_SCCSENSMIR === "C" ? listCompte[columnOpe].STR_SCCSOLDEMIR : (-1 * listCompte[columnOpe].STR_SCCSOLDEMIR);
                let SOLDENOSTRO      = listCompte[columnOpe].STR_SCCSENSNOS === "C" ? listCompte[columnOpe].STR_SCCSOLDNOSTRO : (-1 * listCompte[columnOpe].STR_SCCSOLDNOSTRO);
                let DATESOLDEMIROIRE = listCompte[columnOpe].DT_SCCSOLDMIROIR;
                let DATESOLDENOSTRO  = listCompte[columnOpe].DT_SCCSOLDNOSTRO;
                setTimeout(() => {
                        document.getElementById("SOLDEMIROIRE").innerHTML     = formatBydevise(SOLDEMIROIRE, "");
                        document.getElementById("SOLDENOSTRO").innerHTML      = formatBydevise(SOLDENOSTRO, "");
                        document.getElementById("DATESOLDEMIROIRE").innerHTML = "Solde au " +DATESOLDEMIROIRE
                        document.getElementById("DATESOLDENOSTRO").innerHTML  = "Solde au " +DATESOLDENOSTRO
                }, 500);
        }
        return 0;
}

/**
 * RETOURN LA DATE DU SOLDE DU COMPTE COURANT EN NOSTRO OU EN MIROIR
 * @param {any} columnOpe le nom de la colonne ou l'index à extraire
 * @param {object} currentCompte <- le state qui contient les informations du compte courrant voir [loadDataListSoccompte.fulfilled] pour le format de l'objet
 * @param {array} listCompte la liste des compte dans laquel chercher les informations
*/
export function getSoldeCurrentDateSoldeCompte(columnOpe, currentCompte, listCompte) {
        for (let index = 0; index < listCompte?.length; index++) {
                if (listCompte[index].value === currentCompte.COMPTE.value) { // dans value ya l'id du compte LG_SCCID
                        return listCompte[index][columnOpe];
                }
        }
        return "";
}

/** calcul les totaux pour chaque ligne par type d'opération  
 * @param {string} typeOpe nostroActive ou miroirActive
 * @param {object} rowRapprochement <- les données de la ligne de rapprochement
*/
export const totalMontantRow = (typeOpe,rowRapprochement) => {
        let total = 0.0;
        for (let i = 0; i < rowRapprochement[typeOpe].length; i++) {
                if (typeOpe === "miroirActive") {
                        total = total + (rowRapprochement[typeOpe][i].STR_MVTSENS === "C" ? checkForFormat(rowRapprochement[typeOpe][i].STR_MVTCREDIT) : checkForFormat((-1) * rowRapprochement[typeOpe][i].STR_MVTCREDIT)); // prise en compte du signe de l'opération à cumulée
                }
                if (typeOpe === "nostroActive") {
                        total = total + (rowRapprochement[typeOpe][i].STR_MTXSENS === "C" ? checkForFormat(rowRapprochement[typeOpe][i].STR_MTXCREDIT) : checkForFormat((-1) * rowRapprochement[typeOpe][i].STR_MTXCREDIT)); // prise en compte du signe de l'opération à cumulée
                }
        }
        if (total !== 'undefined' && total !== 0.0) {
                checkEgalite();
                return formatBydevise(total, ""); //rowRapprochement[row][typeOpe][0].STR_MTXDEVI
        } else {
                checkEgalite();
                return "0.0";
        }
};

/** Verifie le montant des operations dans les 2 sens et change la couleur en vert si les montants sont egaux
 *  ou retourn le nombre de ligne ou la somme des totaux donne 0 au cas ou  typeDeReponse est renseigné
 * @param {boolean} typeDeReponse
 */
export function checkEgalite(typeDeReponse) {
                if (typeDeReponse){
                        let temoin =0;
                        let totalHtmlComponentMir   = document.getElementsByClassName("opeTotalmiroirActive"); // total d'une ligne de rapprochement en miroir
                        let totalHtmlComponentNos   = document.getElementsByClassName("opeTotalnostroActive"); // total d'une ligne de rapprochement en nostro
                        for (let i = 0; i < totalHtmlComponentMir.length; i++) {
                                if ((totalHtmlComponentMir[i].innerText !== "0.0" && totalHtmlComponentNos[i].innerText !== "0.0") && (parseFloat(totalHtmlComponentMir[i].innerText.replace(/\s/g, '')) + parseFloat(totalHtmlComponentNos[i].innerText.replace(/\s/g, '')) === 0)) {
                                        temoin++;
                                } else {
                                        let  elmt       = document.getElementById("ROW_miroirActive_"+i);
                                        let  elmt1      = document.getElementById("ROW_nostroActive_"+i);
                                        elmt.className  = elmt.className + " color-blink"; //Ajoute la classe
                                        elmt1.className = elmt1.className + " color-blink"; //Ajoute la classe
                                        elmt.scrollIntoView({ behavior: "auto", block: "center", inline: "nearest" });
                                        Danger.fire({ title: "Defaut de rapprochement à la ligne " + (i + 1)});
                                        setTimeout(() => { // puis retir la classe
                                                elmt.className  = String(elmt.className).split(" color-blink")[0];
                                                elmt1.className = String(elmt1.className).split(" color-blink")[0];
                                        }, 5000);
                                        return false;
                                }
                        }
                        return temoin; // retourne le nombre de ligne ou la somme des totaux s'annule { normelemnt le nombre de ligne du tableau }
                }else{
                        setTimeout(() => { // pck le composant s'affiche apres une animation qui dure un peu plus d'une miliseconde
                                let totalHtmlComponentMir   = document.getElementsByClassName("opeTotalmiroirActive");
                                let totalHtmlComponentNos   = document.getElementsByClassName("opeTotalnostroActive");
                                for (let i = 0; i < totalHtmlComponentMir.length; i++) {
                                        if ((totalHtmlComponentMir[i].innerText !== "0.0" && totalHtmlComponentNos[i].innerText !== "0.0") && (parseFloat(totalHtmlComponentMir[i].innerText.replace(/\s/g, '')) + parseFloat(totalHtmlComponentNos[i].innerText.replace(/\s/g, '')) === 0)) {
                                                totalHtmlComponentMir[i].className = "border-right text-right align-self-center py-4 px-1 col-12 my-0 font-weight-bold text-success text-monospace opeTotalmiroirActive";
                                                totalHtmlComponentNos[i].className = "border-left  text-left align-self-center py-4 px-1 col-12 my-0 font-weight-bold text-success text-monospace opeTotalnostroActive";
                                        } else {
                                                totalHtmlComponentMir[i].className = "border-right text-right align-self-center py-4 px-1 col-12 my-0 font-weight-bold text-danger text-monospace opeTotalmiroirActive";
                                                totalHtmlComponentNos[i].className = "border-left  text-left align-self-center py-4 px-1 col-12 my-0 font-weight-bold text-danger text-monospace opeTotalnostroActive";
                                        }
                                }
                        }, 800);
                }
}

/** Extrait une information donnée du tableau des rapprochement en cours
 * 
 * @param {array} TABLEAU 
 * @param {string} col_mir  le nom de la colone à extraire 
 * @param {string} col_nos  le nom de la colone à extraire ||  typeOperation 
 * @returns array
 */
export const ExtrateurDeColonne = (TABLEAU, col_mir, col_nos, mode) => {
        let MERGE_COL   = [];
        let COL_I       = [];
        let COL_J       = [];
        if (col_nos !== "miroirActive" && col_nos !== "nostroActive") { // extraction basée sur le nom de la colonne
                for (let i = 0; i < TABLEAU.length; i++) {
                        TABLEAU[i].miroirActive.map((item, x) => {
                                return COL_I.push(item[col_mir]);
                        });
                        MERGE_COL[i]=COL_I;
                }
                for (let i = 0; i < TABLEAU.length; i++) {
                        TABLEAU[i].nostroActive.map((item, x) => {
                                return COL_J.push(item[col_nos]);
                        });
                        MERGE_COL[i]=[...COL_J];
                }
        }else{ // extraction basée sur le type d'opération
                if (col_nos === "miroirActive" || col_nos === "nostroActive"){
                        if (mode!==undefined) { //cacul des montant cumulé  ici depuis cumulSuspens.jsx
                                TABLEAU.map((item, x) =>{
                                        if (item.state!=="hidden") {
                                                if (col_mir === "STR_MVTCREDIT") { // pour le cumule des montant
                                                        return item.STR_MVTSENS ==="C" ? MERGE_COL.push(item[col_mir]) : MERGE_COL.push((-1) * item[col_mir]); // prise en compte du signe de l'opération à cumulée
                                                }
                                                if (col_mir === "STR_MTXCREDIT") { // pour le cumule des montant
                                                        return item?.STR_MTXSENS ==="C" ? MERGE_COL.push(item[col_mir]) : MERGE_COL.push((-1) * item[col_mir]); // prise en compte du signe de l'opération à cumulée
                                                }
                                        }
                                        return false;
                                });
                        }else{
                                for (let i = 0; i < TABLEAU.length; i++) {
                                        TABLEAU[i][col_nos].map((item, x) => {
                                                if (col_mir === "STR_MVTCREDIT") { // pour le cumule des montant
                                                        return item?.STR_MVTSENS ==="C" ? MERGE_COL.push((item[col_mir])) : MERGE_COL.push(((-1) * item[col_mir])); // prise en compte du signe de l'opération à cumulée
                                                }
                                                if (col_mir === "STR_MTXCREDIT") { // pour le cumule des montant
                                                        return item?.STR_MTXSENS ==="C" ? MERGE_COL.push((item[col_mir])) : MERGE_COL.push(((-1) * item[col_mir])); // prise en compte du signe de l'opération à cumulée
                                                }
                                                return false;
                                        });
                                }
                        }
                }
        }
        return MERGE_COL;
};

/** SAUVEGARDE D'UN RAPPROCHEMENT EN BROUILLON
 * @function 
 */
export const initEntePagination = createAsyncThunk('initEntePagination', async (data, { dispatch, getState }) => {
        setTimeout(() => {
                // let currentEntetePage = getState().rapprochements.currentEntetePage;
                // let listEntete        = getState().rapprochements.listEntete;
                // let totalrejet        = getState().rapprochements.listEntete.totalrejet;
                let totalattente      = getState().rapprochements.listEntete.totalattente;
                // let nbrPageAttente    = Math.ceil(totalattente / 4);
                // let nbrPageRejet      = Math.ceil(totalrejet / 4);
                // console.log("nbrPageAttente", nbrPageAttente);
                // console.log("nbrPageRejet", nbrPageRejet);
                // console.log("totalattente", totalattente);
                // console.log("totalrejet", totalrejet);
                if (totalattente <= 4) {
                        document.getElementById("navigateentetechoiseattenteleft" ).style.display = "none";
                        document.getElementById("navigateentetechoiseattenteright").style.display = "none";
                }
                // if (totalattente > 4) {
                //         document.getElementById("navigateentetechoiseattenteright").style.display = "block";
                // }
                // if (listEntete.attente.length <= 4 && nbrPageAttente>4 ) {
                //         document.getElementById("navigateentetechoiseattenteright").style.display = "block";
                // }
                // if (totalrejet <= 4) {
                //         document.getElementById("navigateentetechoiserejetright").style.display = "none";
                //         document.getElementById("navigateentetechoiserejetleft").style.display = "none";
                // }
                // if (totalrejet > 4) {
                //         document.getElementById("navigateentetechoiserejetright").style.display = "block";
                // }
                // if (totalrejet > 4 && (document.getElementById("navigateentetechoiserejetleft").style.display) === "none" && (document.getElementById("navigateentetechoiserejetright").style.display) === "none") {
                //         document.getElementById("navigateentetechoiserejetleft").style.display = "block";
                // }
        }, 300);
});

/**
 * RECHERCHE DANS LES OPERATIONS VALIDEES
 * @param {object} data contient les colonnes ci-dessous
 * @param {string} LG_SCCID dans data
 * @param {string} LG_PROID dans data profil
 * @param {string} search le critere de recherche dans data
 * @return array
 */
export const searchValideOperation = createAsyncThunk('searchValideOperation/fetchAll', async (data, { dispatch, getState, rejectWithValue }) => {
        let compte                 = getState().rapprochements.currentCompte.COMPTE?.value;
        let bodyFormData           = new FormData();
        if (compte !== undefined) {
                try {
                        bodyFormData.append("search[value]", data.search ?? "");
                        bodyFormData.append("LG_SCCID", compte);
                        bodyFormData.append("start", data.start ?? 0);
                        bodyFormData.append("LG_PROID", getThisInLocalstore("currentProfile"));
                        let res = await fetch(`${BASEURL}${APINAMESPACE.RAPPROCHEMENT}/SearchValid`, {
                                method : 'POST',
                                body   : bodyFormData
                        });
                        const response = await res.json();
                        if (data.search !== "" && data.search !== undefined) {
                                writeThisInLocalstore(data.search, "refMatch");
                        }
                        return response;
                } catch (error) {
                        Danger.fire({ title: "Recherche infructueuse ! vérifier votre connexion" });
                        return error.message;
                }
        } else {
                Danger.fire({ title: "Recherche infructueuse !" });
                return false;
        }
});
// const searchInput = document.querySelector('input[type="search"]::-webkit-search-cancel-button');
// console.log(searchInput);
// const cancelIcon = searchInput?.querySelector('::-webkit-search-cancel-button');

// cancelIcon?.addEventListener('click', function () {
//         // Le code à exécuter lors du clic sur l'icône de suppression
//         console.log('L\'icône de suppression a été cliquée.');
// });

/**
 * Initialise la modal critere de rapprochement une foi fermée
 * @returns  void
 */
export function cleanModalCritereAfterClose(){
        return async function cleanModalCritereAfterCloseThunk(dispatch, getState) {
                let modalcriteres = JSON.parse(JSON.stringify(getState().rapprochements.modalcriteres));
                if (!modalcriteres.open) { //a la fermeture de la modal critères
                        let criteres    = JSON.parse(JSON.stringify(getState().rapprochements.criteres));
                        let rsmcriteres = JSON.parse(JSON.stringify(getState().rapprochements.rsmcriteres));
                        let newCritere  = JSON.parse(JSON.stringify(getState().rapprochements.criteres));
                        let selection   = JSON.parse(JSON.stringify(getState().rapprochements.rsmcriteres));
                        criteres.map((item, x) => { return (newCritere[x][Object.keys(item)[0]] = "0"); });
                        rsmcriteres.map((item, x) => { return (selection[x][Object.keys(item)[0]] = "0"); });
                        dispatch(setCriteres(newCritere));
                        dispatch(setRsmcriteres(selection));
                        dispatch(setDatescritere(DATECRITEREDEFAULTSTATE));
                        //vidage des champs de date 
                        let datx = document.getElementsByClassName("flatpickr");
                        for (let index = 0; index < datx.length; index++) {
                                datx[index]._flatpickr.clear();
                        }
                        // déselection de tous les critères en une fois
                        let checkboxList = document.getElementsByClassName("criteres");
                        for (let index = 0; index < checkboxList.length; index++) {
                                checkboxList[index].checked = false;
                        }
                }
        };
}

/**
 * Supprime le champs date designé (Habituelement dans la modal des critères)
 * @returns  void
 */
export function clearDate(data){
        return async function clearDateThunk(dispatch, getState) {
                dispatch(setDatescritere({...getState().rapprochements.datescritere,[data[0]]:"",[data[1]]:""}));
                //vidage des champs de date
                document.getElementById(data[0])._flatpickr.clear();
                document.getElementById(data[1])._flatpickr.clear();
        };
}

/** Genere du texte pour le copier coller des informations d'une operation
 * @function 
 */
export const makeClipboardObject = createAsyncThunk('makeClipboardObject', async (datax, { dispatch, getState }) => {
        let {data,typeOpe } = datax
        let clipboard       = "", clipMotifRejet="", clipdateCreaRap="", cliphtmldateUpdateRap="";
        let currentCompte   = getState().rapprochements.currentCompte;
        if (typeOpe==="miroirActive") {
                if (data?.STR_RAPPROCOMMENT!==undefined) {
                        clipdateCreaRap       = "Date du dernier rapprochement :"+data.DT_RAPPROCREATED+"\n" ;
                        clipMotifRejet        = "Motif du Rejet :" + (data.STR_RAPPROCOMMENT)+"\n" ;
                        cliphtmldateUpdateRap = "Date de rejet du rapprochement :"+data.DT_RAPPROUPDATED+"\n" ;
                }
                //le text a copie
                clipboard = "Compte Nostro N° :"            +currentCompte.COMPTE.NOSTRO            +"\n" ;
                clipboard+= "Compte Miroir N° :"            +currentCompte.COMPTE.MIROIR            +"\n" ;
                clipboard+= "Référence relative :"          +data.STR_MVTREFRELAT         +"\n" ;
                clipboard+= "Référence de l'opération :"    +data.STR_MVTREFDOPE          +"\n" ;
                clipboard+= "Référence de la transaction :" +data.STR_MVTREFTRANSAC       +"\n" ;
                clipboard+= "Date de l'opération :"         +data.DT_MVTDATEDOPE          +"\n" ;
                clipboard+= "Date de validation :"          +data.DT_MVTDATEVAL           +"\n" ;
                clipboard+= "Montant de l'opération  :"     +data.STR_MVTCREDIT           +"\n" ;
                clipboard+= "Sens de l'opération :"         +data.STR_MVTSENS             +"\n" ;
                clipboard+= "Libellé de l'opération :"      +data.STR_MVTLIBELLE          +"\n" ;
                clipboard+= "Devise :"                      +data.STR_MTXDEVI             +"\n" ;
                clipboard+= cliphtmldateUpdateRap;
                clipboard+= clipdateCreaRap;
                clipboard+= clipMotifRejet;
        }

        if (typeOpe==="nostroActive") {
                if (data?.STR_RAPPROCOMMENT !== undefined) {
                                clipdateCreaRap       = "Date du dernier rapprochement :"  + data.DT_RAPPROCREATED  + "\n"             ;
                                clipMotifRejet        = "Motif du Rejet :"                 + data.STR_RAPPROCOMMENT + "\n"             ;
                                cliphtmldateUpdateRap = "Date de rejet du rapprochement :" + data.DT_RAPPROUPDATED  + "\n"             ;
                }
                //le text a copie
                clipboard = "Compte Nostro N° :"            +currentCompte.COMPTE.NOSTRO            +"\n" ;
                clipboard+= "Compte Miroir N° :"            +currentCompte.COMPTE.MIROIR            +"\n" ;
                clipboard+= "Référence relative :"          +data.STR_MTXREFRELAT         +"\n" ;
                clipboard+= "Référence de l'opération :"    +data.STR_MTXREFDOPE          +"\n" ;
                clipboard+= "Référence de la transaction :" +data.STR_MTXREFTRANSAC       +"\n" ;
                clipboard+= "Date de l'opération :"         +data.DT_MTXDATEDOPE          +"\n" ;
                clipboard+= "Date de validation :"          +data.DT_MTXDATEVAL           +"\n" ;
                clipboard+= "Montant de l'opération  :"     +data.STR_MTXCREDIT           +"\n" ;
                clipboard+= "Sens de l'opération :"         +data.STR_MTXSENS             +"\n" ;
                clipboard+= "Libellé de l'opération :"      +data.STR_MTXLIBELLE          +"\n" ;
                clipboard+= "Devise :"                      +data.STR_MTXDEVI             +"\n" ;
                clipboard+= cliphtmldateUpdateRap;
                clipboard+= clipdateCreaRap;
                clipboard+= clipMotifRejet;
        }
        return  clipboard;
});

/** genere du html ou du jsx pour le rendu en version eclatée d'une operation
 * @function 
 */
export const makeHtmlRepresentation = createAsyncThunk('makeHtmlRepresentation', async (datax, { dispatch, getState }) => {
        let {data,typeOpe, style } = datax;
        let html            = "", htmlMotifRejet="", htmldateUpdateRap="", htmldateCreaRap="";
        let currentCompte = getState().rapprochements.currentCompte;
        if (typeOpe==="miroirActive") {
                if (data?.STR_RAPPROCOMMENT!==undefined) {
                        htmldateCreaRap       = '<hr class="text-muted bg-bg-white text-white"><small class="text-muted">Date du dernier rapprochement :  <span class="text-danger text-right">'+data.DT_RAPPROCREATED+'</span></small>';
                        htmlMotifRejet        = '<small class="text-muted">Motif de rejet :  <span class="text-danger text-right">'+data.STR_RAPPROCOMMENT+'</span></small>';
                        htmldateUpdateRap     = '<small class="text-muted">Date de rejet du rapprochement :  <span class="text-danger text-right">'+data.DT_RAPPROUPDATED+'</span></small>';
                }
                //le html à affiché dans la modale
                html = '<div class="p-1 bg-light text-center oneOpe card">'+
                                '<small class="text-muted">Compte Nostro N° : <span class="text-danger text-right">'+currentCompte.COMPTE.NOSTRO+'</span></small>'+
                                '<small class="text-muted">Compte Miroir N° :  <span class="text-danger text-right">'+currentCompte.COMPTE.MIROIR+'</span></small>'+
                                '<small class="text-muted">Référence relative : <span class="text-danger text-right">'+data.STR_MVTREFRELAT+'</span></small>'+
                                '<small class="text-muted">Référence de la transaction :  <span class="text-danger text-right">'+data.STR_MVTREFTRANSAC+'</span></small>'+
                                '<small class="text-muted">Date de l\'opération :  <span class="text-danger text-right">'+data.DT_MVTDATEDOPE+'</span></small>'+
                                '<small class="text-muted">Date de validation  :  <span class="text-danger text-right">'+data.DT_MVTDATEVAL+'</span></small>'+
                                '<small class="text-muted">Référence de l\'opération :  <span class="text-danger text-right">'+data.STR_MVTREFDOPE+'</span></small>'+
                                '<small class="text-muted">Montant de l\'opération :  <span class="text-danger text-right">'+formatBydevise(data.STR_MVTCREDIT,"")+" "+data.STR_MTXDEVI+'</span></small>'+
                                '<small class="text-muted">Sens de l\'opération :  <span class="text-danger text-right">'+data.STR_MVTSENS+'</span></small>'+
                                '<small class="text-muted">Libellé de l\'opération :  <span class="text-danger text-right">'+data.STR_MVTLIBELLE+'</span></small>'+
                                '<small class="text-muted">Devise :  <span class="text-danger text-right">'+data.STR_MTXDEVI+'</span></small>'+
                                htmldateCreaRap+
                                htmldateUpdateRap+
                                htmlMotifRejet+
                        '</div>';
        }

        if (typeOpe==="nostroActive") {
                if (data?.STR_RAPPROCOMMENT !== undefined) {
                                htmldateCreaRap  = '<hr class="text-muted bg-bg-white text-white"><small class="text-muted">Date du dernier rapprochement :  <span class="text-danger text-right">'  + data.DT_RAPPROCREATED  + '</span></small>';
                                htmlMotifRejet   = '<small class="text-muted">Motif de rejet :  <span class="text-danger text-right">'                 + data.STR_RAPPROCOMMENT + '</span></small>';
                                htmldateUpdateRap= '<small class="text-muted">Date de rejet du rapprochement :  <span class="text-danger text-right">' + data.DT_RAPPROUPDATED  + '</span></small>';
                }
                //le html à affiché dans la modale
                html = '<div class="p-1 bg-light text-center oneOpe card">'+
                                '<small class="text-muted">Compte Nostro N° : <span class="text-danger text-right">'+currentCompte.COMPTE.NOSTRO +'</span></small>'+
                                '<small class="text-muted">Compte Miroir N° :  <span class="text-danger text-right">'+currentCompte.COMPTE.MIROIR+'</span></small>'+
                                '<small class="text-muted">Référence relative : <span class="text-danger text-right">'+data.STR_MTXREFRELAT+'</span></small>'+
                                '<small class="text-muted">Référence de l\'opération :  <span class="text-danger text-right">'+data.STR_MTXREFDOPE+'</span></small>'+
                                '<small class="text-muted">Référence de la transaction :  <span class="text-danger text-right">'+data.STR_MTXREFTRANSAC+'</span></small>'+
                                '<small class="text-muted">Date de l\'opération :  <span class="text-danger text-right">'+data.DT_MTXDATEDOPE+'</span></small>'+
                                '<small class="text-muted">Date de validation  :  <span class="text-danger text-right">'+data.DT_MTXDATEVAL+'</span></small>'+
                                '<small class="text-muted">Montant de l\'opération :  <span class="text-danger text-right">'+formatBydevise(data.STR_MTXCREDIT,"")+" "+data.STR_MTXLIBELLE+'</span></small>'+
                                '<small class="text-muted">Sens de l\'opération :  <span class="text-danger text-right">'+data.STR_MTXSENS+'</span></small>'+
                                '<small class="text-muted">Libellé de l\'opération :  <span class="text-danger text-right">'+data.STR_MTXLIBELLE+'</span></small>'+
                                '<small class="text-muted">Devise :  <span class="text-danger text-right">'+data.STR_MTXDEVI+'</span></small>'+
                                htmldateCreaRap+
                                htmldateUpdateRap+
                                htmlMotifRejet+
                        '</div>';
        }
        return  style==="jsx" ? html.replace(/class?/g,"className") : html;
});

/** affiche les detailles d'une operation sur l'aquel un double click a été fait
 * 
 */
export const fireModalSweetAlert = createAsyncThunk('fireModalSweetAlert', async (data, { dispatch, getState }) => {
        Swal.fire({
                html               : getState().rapprochements.html,
                focusConfirm       : true,
                buttonsStyling     : true,
                customClass        : {confirmButton    : "btn btn-block"},
                confirmButtonColor : '#ccc',
                confirmButtonText  : "copier",
        }).then((result) => {
                if (result.isConfirmed) {
                        if(window.isSecureContext) {
                                // copy to clip board
                                navigator.clipboard.writeText(getState().rapprochements.clipboard).then(function() {
                                        Swal.fire({
                                                icon              : 'success',
                                                title             : 'Copié !!!',
                                                showConfirmButton : false,
                                                timer             : 1000
                                        });
                                }, function(err) {
                                        Info.fire("Impossible de faire une copie dans ce navigateur ! Réessayer avec Google Chrome.");
                                });
                        }else {
                                Info.fire("Copie impossible en protocol non HTTPS. Veuillez faire une copie manuelle (CTRL+C) en sélectionnant le champs texte qui vous interresse.");
                                console.error("Copie impossible en protocol non HTTPS. Veuillez faire une copie manuelle (CTRL+C) en sélectionnant le champs texte qui vous interresse.");
                        }
                }
        });
        // pour styliser le composant dans la modal sweetalert2
        let sweetelmt       = document.getElementById("swal2-html-container");
        sweetelmt.className = sweetelmt.className+" p-1 m-0";
});

export const { setStatustRapp, setListEntete, setModalComment, setModalValidation, setStatus, setModalconfirmation, setCurrentEntetePage, setPaginationMvt, setPaginationEntete, setPaginationMtx, setEnteteEncours, setLoader, setListCompte, setCurrentCompte, setRowRapprochement, setResRowRapprochement, setRowDraftRapprochement, setOperationsCount, setOperations,setEntete, setCurrentEntete ,setCriteres,setListCriteres,setModalcriteres,setModalMoveRappro,setDatescritere, setAnimeDragAcept, setAnimeDragReject, setMtxDraggbleList, setMvtDraggbleList,setCurrentDraggableItem,setRsmcriteres, setPagination, setformErreur, setFileImg, status, setIndexRaprochement } = RapprochementSlice.actions;
export default RapprochementSlice.reducer;