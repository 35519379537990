import { configureStore } from "@reduxjs/toolkit";
import armateurReducer from './store/Armateur/Armateur';
import dashboard from "./store/Dashboard/Dashboard";
import documentReducer from './store/Document/Document';
import matelotReducer from './store/Matelot/Matelot';
import profilprivilegesReducer from './store/Profil/Privilege';
import profilReducer from './store/Profil/Profil';
import rapprochementsReducer from './store/Rapprochements/Rapprochement';
import SessionReducer from './store/Session/Session';
import transportReducer from './store/Transport/Transport';
import utilisateurReducer from './store/Utilisateurs/Utilisateur';

export const Store = configureStore ({
  reducer: {
    utilisateurs     : utilisateurReducer,
    session          : SessionReducer,
    matelots         : matelotReducer,
    armateurs        : armateurReducer,
    documents        : documentReducer,
    transports       : transportReducer,
    rapprochements   : rapprochementsReducer,
    profils          : profilReducer,
    profilprivileges : profilprivilegesReducer,
    dashboard        : dashboard
  }
});