import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Danger, Info, Success } from "../../globalComponents/CustomToast";
import { converteDateToLetterFranch, getCurrentPath, getThisInLocalstore } from "../../globalComponents/Main";
import { PAGINATION } from "../../globalComponents/Pagination";
import { BASEURL } from "../../services/serveur.js";
import { setAborder } from "../Document/Document";

/** Transport [CONVERGENCE] SYNAPSE GROUPE
* !!CENTRALISATION DES STATES Transport
*@bessely
*@Author  : YAO BESSELY SUNDAY JUNIOR : +2250709116844 besselymail@gmail.com
*         :Codez en pensant que celui qui maintiendra votre code est un psychopathe qui connaît votre adresse.
*/

export const STATUS = Object.freeze({
        SUCCESS : 'success',
        ERROR   : 'error',
        LOADING : 'loading',
});

const APINAMESPACE = Object.freeze({
        TRANSPORT : "/flight",
        CONGIG    : "/session",
});
const MODALTRANSPORTDEFAULTSTATE          = { open: false, mode: "", title: "", button: "", inputstate: "", btnclass: "btn btn-primary my-2 text-18 col-4" }; 
const MODALTRANSPORTLOCKUP                = { open: false, mode: "", size: "xx", title: "Long période d'inactivité détectée", button: true, buttonName: "Toujours là !!!", btnclass: "btn btn-block btn-danger shadow" };
export const CURRENTTRANSPORTDEFAULTSATE  = [{ id_flight: "", date_depat_flight: "", imma_flight: "", name_flight: "", depart: "", arrive: "", type_flight: "", state_flight:"" }];
const MODALPROFILTRANSPORTDEFAULTSTATE    = { open: false, mode: "creation", size: "profil", title: "Choisissez un profil", button: false, };
const MODALCOMPTETRANSPORTDEFAULTSTATE    = { open: false, mode: "modification", size: "compte", title: "Mon Compte", button: false, };
const initialState = {
        TransportList    : [],
        loginTransport   : [],
        currentTransport : CURRENTTRANSPORTDEFAULTSATE,
        pagination       : PAGINATION,
        formErreur       : [],
        modalTransport   : MODALTRANSPORTDEFAULTSTATE,
        modalProfile     : MODALPROFILTRANSPORTDEFAULTSTATE,
        modalCompte      : MODALCOMPTETRANSPORTDEFAULTSTATE,
        modalLockFrame   : MODALTRANSPORTLOCKUP,
        fileImg          : [],
        searchParam      : { choix: "", Transport: "", type: "" },
        status           : { Transport: STATUS.LOADING },
};

export const TransportSlice = createSlice({
        name: "Transports",
        initialState,
        reducers: {
                setTransportList    (state, action) { state.TransportList    = action.payload; },
                setLoginTransport   (state, action) { state.loginTransport   = action.payload; },
                setCurrentTransport (state, action) { state.currentTransport = action.payload; },
                setPagination       (state, action) { state.pagination       = action.payload; },
                setformErreur       (state, action) { state.formErreur       = action.payload; },
                setFileImg          (state, action) { state.fileImg          = action.payload; },
                setModalTransport   (state, action) { state.modalTransport   = action.payload; },
                setModalProfile     (state, action) { state.modalProfile     = action.payload; },
                setModalCompte      (state, action) { state.modalCompte      = action.payload; },
                setStatus           (state, action) { state.status           = action.payload; },
                setMenu             (state, action) { state.menu             = action.payload; },
                setSubMenuState     (state, action) { state.subMenuState     = action.payload; },
                setmodalLockFrame   (state, action) { state.modalLockFrame   = action.payload; },
        },
        extraReducers: (builder) => {
                builder
                        .addCase(loadDataTransportList.pending, (state, action) => {
                                state.status.Transport = STATUS.LOADING;
                        })
                        .addCase(loadDataTransportList.fulfilled, (state, action) => {
                                if (getCurrentPath()==="Document") {
                                        if (action.payload.recordsTotal > 0) {
                                                let userListOpt = [];
                                                action.payload.data.map((item) => {
                                                        return userListOpt.push({
                                                                label       : item.name_flight+ " " +item.depart+"/"+item.arrive,
                                                                value       : item.id_flight,
                                                                dateL       : item.date_depart_letter,
                                                                date        : item.date_depat_flight,
                                                                type_flight : item.type_flight,
                                                                allInfos    : item,
                                                                typeList    : "Flights",
                                                        });
                                                });
                                                state.TransportList = userListOpt;
                                        }
                                }else{
                                        if (action.payload.recordsTotal > 0) {
                                                state.TransportList = action.payload.data; // !<- PUBLICATION DE LA LISTE DES: TransportS DANS LE STORE
                                                state.pagination =
                                                {
                                                        listParPage     : PAGINATION.listParPage,
                                                        currentPage     : state.pagination.changePageClick ? state.pagination.currentPage : 0,  // !si rechargement ou chargement simple des data currentPage=0 sinon currentPage reste par defaut la page designée l'hors du click sur la pâgination
                                                        changePageClick : false,
                                                        listLenght      : action.payload.recordsFiltered,
                                                        nbrPage         : Math.ceil(action.payload.recordsFiltered / PAGINATION.listParPage)
                                                };
                                        } else {
                                                Info.fire({ title: "Aucun Transport trouvé !!!" });
                                        }
                                        state.status = { ...state.status, Transport: STATUS.SUCCESS };
                                }
                        })
                        .addCase(loadDataTransportList.rejected, (state, action) => {
                                state.status.Transport = STATUS.ERROR;
                                Danger.fire({ title: "Désolé ! La liste des Transports n'a pas pu être chargée" });
                        })
                        .addCase(getDataTransport.fulfilled, (state, action) => {
                                if (action.payload.reponse === "success") {
                                        state.currentTransport        = action.payload.data; // !<- PUBLICATION DU CHARGEMENT DE L'Transport EN COURS DANS LE STORE
                                } else {
                                        Danger.fire({ title: "Ce Transport est introuvable !" });
                                }
                        })
        },
});

/**CHARGEMENT DES OU TransportS */
export const loadDataTransportList = createAsyncThunk('loadDataTransportList/fetchAll', async (data) => {
        const formatDate =(suffixe="")=>{
                if (suffixe!=="" && data[suffixe]!==undefined && data[suffixe]!=="") {
                        let xxxx = data[suffixe].split("-");
                        return xxxx[2]+"/"+xxxx[1]+"/"+xxxx[0]
                }else{
                        const date = new Date();
                        const jour = date.getDate().toString().padStart(2, "0");
                        const mois = (date.getMonth() + 1).toString().padStart(2, "0");
                        const annee = date.getFullYear().toString();
                        return `${jour}/${mois}/${annee}`;
                }
        };
        try {
                var bodyFormData = new FormData();
                bodyFormData.append("STATE_FLIGHT", data.STATE_FLIGHT??"%");
                bodyFormData.append("search[value]", data.search ?? "%");
                if (getCurrentPath()==="Document") {
                        bodyFormData.append("DATE1", "01/01/1960");
                        bodyFormData.append("DATE2", "30/01/2060");
                }else{
                        bodyFormData.append("DATE1", formatDate("DT_BEGIN"));
                        bodyFormData.append("DATE2", formatDate("DT_END"));
                }
                bodyFormData.append("START", data.start ?? 0);
                bodyFormData.append("LENGTH", data.listParPage ?? 100);
                var res = await fetch(`${BASEURL}${APINAMESPACE.TRANSPORT}/list`, {
                        method : 'POST',
                        body   : bodyFormData
                });
                const response = await res.json();
                return response;
        } catch (error) {
                Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                return error.message;
        }
});

/** CHARGEMENT D'UN Transport SPECIFIÉ */
export const getDataTransport = createAsyncThunk('Transport/fetchOne', async (idTransport) => {
        try {
                var bodyFormData = new FormData();
                bodyFormData.append("ID_FLIGHT", idTransport);
                var res = await fetch(`${BASEURL}${APINAMESPACE.TRANSPORT}/GetOne`, {
                        method : 'POST',
                        body   : bodyFormData
                });
                const response = await res.json();
                if (response.reponse==="error") {
                        return Danger.fire({ title: response.message });
                }
                return response;
        } catch (error) {
                Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                return error.message;
        }
});

/** SOUMISSION DU FORMULAIRE DE CREATION D1 Transport
 * @param {array} data données du formulaire
 * @function 
 */
export const createTransport = createAsyncThunk('createTransport', async (data,{ dispatch, getState }) => {
        try {
                var bodyFormData = new FormData();
                bodyFormData.append("DATE_DEPAT_FLIGHT", data.date_depat_flight ??"");
                bodyFormData.append("IMMA_FLIGHT"      , data.imma_flight       ??"");
                bodyFormData.append("NAME_FLIGHT"      , data.name_flight       ??"");
                bodyFormData.append("DEPART"           , data.depart            ??"");
                bodyFormData.append("ARRIVE"           , data.arrive            ??"");
                bodyFormData.append("TYPE_FLIGHT"      , document.getElementById('type_flight').value);
                bodyFormData.append("TOKEN", getThisInLocalstore("loginUtilisateur").token);
                var res = await fetch(`${BASEURL}${APINAMESPACE.TRANSPORT}/new`, {
                        method : 'POST',
                        body   : bodyFormData
                });
                const response = await res.json();
                if (response.reponse === "success") {
                        dispatch(setModalTransport(MODALTRANSPORTDEFAULTSTATE)); //<- fermeture de la modale de modification
                        dispatch(setCurrentTransport({}));
                        setTimeout(() => {
                                dispatch(loadDataTransportList({ DT_BEGIN: "2020-01-01", DT_END: "", STATE_FLIGHT:"%", start: 0, listParPage: PAGINATION.listParPage, search: "" }));   //<- rechargement des data
                        }, 1000);
                        if (getCurrentPath()==="Document") {
                                let aborder = getState().documents.aborder;
                                let aborderC = JSON.parse(JSON.stringify(aborder));
                                data={
                                        ...data,
                                        "id_flight"          : response.data.id_flight,
                                        "date_depart_letter" : converteDateToLetterFranch(data.date_depat_flight),
                                        'date_depart_format' : data.date_depat_flight
                                }
                                aborderC['Flights'].push(data);
                                dispatch(setAborder(aborderC));
                        }
                        Success.fire({ title: response.message  });
                } else {
                        if (response.reponse==="error") {
                                return Danger.fire({ title: response.message });
                        }
                        Danger.fire({ title: "Echec de la création veuillez réessayer." });
                }
                return response;
        }
        catch (error) {
                Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                return error.message;
        }
});

/** MODIFICATION D'Transport 
 * @param {array} data données du formulaire
 * */
export const updateTransport = createAsyncThunk('updateTransport', async (data, { dispatch, getState }) => {
        try {
                var bodyFormData = new FormData();
                bodyFormData.append("ID_FLIGHT"        , data.id_flight             );
                bodyFormData.append("DATE_DEPAT_FLIGHT", data.date_depat_flight ??"");
                bodyFormData.append("IMMA_FLIGHT"      , data.imma_flight       ??"");
                bodyFormData.append("NAME_FLIGHT"      , data.name_flight       ??"");
                bodyFormData.append("DEPART"           , data.depart            ??"");
                bodyFormData.append("ARRIVE"           , data.arrive            ??"");
                bodyFormData.append("TYPE_FLIGHT"      , data.type_flight       ??"");
                bodyFormData.append("TOKEN", getThisInLocalstore("loginUtilisateur").token);
                // if (getState().currentTransport.fileImg) {
                //         bodyFormData.append("file", getState().Transports.fileImg); //<- les fichiers
                // }}
                var res = await fetch(`${BASEURL}${APINAMESPACE.TRANSPORT}/update`, {
                        method : 'POST',
                        body   : bodyFormData
                });
                const response = await res.json();
                if (response.reponse === "success") {
                        dispatch(loadDataTransportList({ DT_BEGIN: "2020-01-01", DT_END: "", STATE_FLIGHT:"%", start: 0, listParPage: PAGINATION.listParPage, search: "" }));   //<- rechargement des data
                        dispatch(setModalTransport(MODALTRANSPORTDEFAULTSTATE)); //<- fermeture de la modale de modification
                        Success.fire({ title:response.message });
                } else {
                        if (response.reponse === "error") {
                                return Danger.fire({ title: response.message});
                        }
                        Danger.fire({ title: "Echec de la modification veuillez réessayer." });
                }
                return response;
        } catch (error) {
                Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                return error.message;
        }
});


/** SUPPRESSION D'UN Transport */ 
export const deleteTransport = createAsyncThunk('deleteTransport', async (ID_Transport, { dispatch, getState }) => {
        try {
                var bodyFormData = new FormData();
                bodyFormData.append("ID_FLIGHT", ID_Transport);
                bodyFormData.append("STATE_FLIGHT", "DELETED");
                bodyFormData.append("TOKEN", getThisInLocalstore("loginUtilisateur").token);
                var res = await fetch(`${BASEURL}${APINAMESPACE.TRANSPORT}/updateState`, {
                                method : 'POST',
                                body   : bodyFormData
                });
                const response = await res.json();
                if (response.reponse === "success") {
                        dispatch(loadDataTransportList({ DT_BEGIN: "2020-01-01", DT_END: "", STATE_FLIGHT:"%", start: 0, listParPage: PAGINATION.listParPage, search: "" }));
                        Success.fire({ title: "Suppression effectuée !" });
                } else {
                        if (response.reponse==="error") {
                                return Danger.fire({ title: response.message });
                        }
                        Danger.fire({ title: "Echec de la suppression veuillez réessayer." });
                }
                return response;
        } catch (error) {
                Danger.fire({ title: "Le serveur est injoingnable ! vérifier votre connexion" });
                return error.message;
        }
});


export const { setModalCompte, setmodalLockFrame, setTransportList, setCurrentTransport, setLoginTransport, setMenu, setMenuSate, setSubMenuState, setPagination, setformErreur, setFileImg, setModalTransport, setModalProfile, status, menu } = TransportSlice.actions;
export default TransportSlice.reducer;


