const SERVEUR = {
      "HOSTNAME"           : window.location.hostname, // HOST NAME WEB
      "SYNAPSE"            : "160.155.220.179", //<- SERVEUR IP WOLD WILD WEB
      "SYNAPSE_LOCAL"      : "192.168.1.150",   //<- SERVEUR IP LOCAL WEB
      "SYNAPSE_VIRTUAL"    : "192.168.1.195",   //<- SERVEUR IP LOCAL WEB
      "MEDIO"              : "DESKTOP-UEDFLUD", //<- medio PC dans le réseau SYNAPSE
      "AXEL"               : "DESKTOP-7864OJN", //<- Axel PC PERSO  dans le réseau SYNAPSE
      "AXEL_PRO"           : "DESKTOP-1166FQ2", //<- Axel PC PRO  dans le réseau SYNAPSE
      "CAMARA_PC"          : "Ahmed-Camara",    //<- CAMARA PC  dans le réseau SYNAPSE
      "THIS_PC"            : "JUNIORPCDEV", //<- JUNIOR PC  dans le réseau SYNAPSE
      "SERVEUR_TEST_BNI"   : "10.207.254.12",
      "SERVEUR_TEST_BNI_L" : "127.0.0.1",
      "PORT"               : "80",
      "PROD_SERVER"        : "https://sumaci.pro",
      "PROTOCOL"           : window.location.protocol+"//" // http: || https:
};
var  BASEURL  =`${SERVEUR.PROD_SERVER}/api.travelboat.form`;
var  BASEROOT ="";
if (process.env.NODE_ENV==="development") {
      BASEURL  = `${SERVEUR.PROTOCOL}${SERVEUR.THIS_PC}:${SERVEUR.PORT}/api.travelboat.form`;
      BASEROOT ="";
}

export { BASEROOT, BASEURL, SERVEUR };

