import Swal from 'sweetalert2';
export const Success = Swal.mixin({
    toast             : true,
    position          : 'top-end',
    showConfirmButton : false,
    showCloseButton   : true,
    timer             : 6000,
    timerProgressBar  : true,
    background        : '#154d3b',
    color             : '#fff',
    icon              : 'success',
    iconColor         : '#fff',
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
});

export const Danger = Swal.mixin({
    toast             : true,
    position          : 'top-end',
    showConfirmButton : false,
    showCloseButton   : true,
    timer             : 6000,
    timerProgressBar  : true,
    background        : '#F7021E',
    color             : '#fff',
    icon              : 'error',
    iconColor         : '#fff',
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
});

export const DangerBottom = Swal.mixin({
    toast             : true,
    position          : 'bottom-start',
    showConfirmButton : false,
    showCloseButton   : true,
    timer             : 6000,
    timerProgressBar  : true,
    background        : '#F7021E',
    color             : '#fff',
    icon              : 'error',
    iconColor         : '#fff',
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
});

export const Info = Swal.mixin({
    toast             : true,
    position          : 'top-end',
    showConfirmButton : false,
    showCloseButton   : true,
    timer             : 6000,
    timerProgressBar  : true,
    background        : '#0F405D',
    color             : '#fff',
    icon              : 'info',
    iconColor         : '#fff',
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
}); 